import axios from 'axios';

import { ROOT_API_URL, ROOT_NODE_API_URL } from '../../constant';

export const FETCH_IMAGEKIT_SHA = 'fetch_imagekit_sha';
export const FETCH_IMAGEKIT_SHA_PUBLIC = 'fetch_imagekit_sha_public';
export const SAVE_REFERRAL_TO_FIREBASE = 'save_referral_to_firebase';
export const SEND_SMS = 'send_sms';

export const FETCH_SERVER_TIME = 'FETCH_SERVER_TIME';
export const SET_SINGLE_UPLOAD_LOADING = 'set_single_upload_loading';
export const SET_CURRENT_CURSOR = 'set_current_cursor';
export const SHOW_GLOBAL_SEARCH = 'SHOW_GLOBAL_SEARCH';

export function fetchImagekitSHA(accesstoken, filename, timestamp) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}utils/imagekitSHA?filename=${filename}&timestamp=${timestamp}`, config);
    return {
        type: FETCH_IMAGEKIT_SHA,
        promise: request,
    };
}

export function fetchImagekitSHAV2(accesstoken, filename, token, expire) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const data = {
        token,
        expire,
    };
    const request = axios.post(`${ROOT_API_URL}utils/imagekit-sha`, data, config);
    return {
        type: FETCH_IMAGEKIT_SHA,
        promise: request,
    };
}

export function fetchImagekitSHAV2Public(filename, token, timestamp) {
    const request = axios.get(`${ROOT_API_URL}utils/imagekit-sha/token?authToken=${token}&expire=${timestamp}`);
    return {
        type: FETCH_IMAGEKIT_SHA_PUBLIC,
        promise: request,
    };
}

export function saveReferralInfoToFirebase(data) {
    const request = axios.post(`${ROOT_NODE_API_URL}napp/savereferral`, data);
    return {
        type: SAVE_REFERRAL_TO_FIREBASE,
        promise: request,
    };
}

export function sendSms(data) {
    const body = {
        'token': 'ZmxlZXR4U2VuZHNtc1Rva2Vu',
        ...data,
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/sms/sendsms`, body);
    return {
        type: SEND_SMS,
        promise: request,
    };
}

/**
 * in case if user system clock is behind or in future
 * for example imagekit error: Your request contains invalid expire parameter. Expire parameter should be a Unix time in less than 1 hour into the future
 */
export function getServerTime(accesstoken) {
    const request = axios.get(`${ROOT_NODE_API_URL}napp/misc/servertime`, {});
    return {
        type: FETCH_SERVER_TIME,
        promise: request,
    };
}

export function setUploadLoading(value) {
    return {
        type: SET_SINGLE_UPLOAD_LOADING,
        data: value,
    };
}

export function setCurrentCursor(value) {
    return {
        type: SET_CURRENT_CURSOR,
        data: value,
    };
}

export function enableSearchBar(value) {
    return {
        type: SHOW_GLOBAL_SEARCH,
        data: value,
    };
}
