import { handle } from 'redux-pack';
import { get } from 'lodash';
import { FETCH_DRIVER_SCORE_GRAPH, FETCH_DRIVER_TREND_SCORE } from 'actions/dashboard/driver_score_actions';

const initialState = {
    trendError: null,
    trendStatus: 'idle',
    trendData: [],
    scoreGraphStatus: 'idle',
    driverScore: [],
    scoreGraphError: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_DRIVER_TREND_SCORE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    trendStatus: 'loading',
                }),
                failure: (prevState) => ({
                    ...prevState,
                    trendError: payload,
                    trendStatus: 'error',
                    trendData: [],
                }),
                success: (prevState) => ({
                    ...prevState,
                    trendStatus: 'success',
                    trendData: get(payload, 'data.trendScoreMap', []),
                }),
            });
        case FETCH_DRIVER_SCORE_GRAPH:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    scoreGraphStatus: 'loading',
                }),
                failure: (prevState) => ({
                    ...prevState,
                    scoreGraphError: payload,
                    scoreGraphStatus: 'error',
                    scoreGraphData: [],
                }),
                success: (prevState) => ({
                    ...prevState,
                    scoreGraphStatus: 'success',
                    driverScore: get(payload, 'data.driverScore', []),
                    scoreVsCountMap: get(payload, 'data.scoreVsCountMap', {}),
                }),
            });
        default:
            return state;
    }
}
