import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Field, getIn } from 'formik';
import { Form } from 'antd';
import { FxCol } from 'sharedV2/FxGrid';
export const FormItem = ({ name, __debug, showValidateSuccess = false, showInitialErrorAfterTouched = false, children, validate, ...restProps }) => {
    return (_jsx(FxCol, { className: restProps.colClassName, span: restProps.colSpan || 24, children: _jsx(Field, { name: name, validate: validate, children: ({ form: { errors = {}, touched = {}, initialErrors = {} } }) => {
                const error = getIn(errors, name, undefined);
                const initialError = getIn(initialErrors, name, undefined);
                let isTouched = getIn(touched, name, false);
                if (Array.isArray(isTouched)) {
                    isTouched =
                        isTouched.length === 0 ? true : isTouched.reduce((acc, value) => acc || value, false);
                }
                const hasError = error !== undefined && isTouched;
                const hasInitialError = initialError !== undefined;
                const showError = hasError || (hasInitialError && (!isTouched || showInitialErrorAfterTouched));
                return (_jsx(Form.Item, { htmlFor: name, id: name, name: name, validateStatus: hasError || (hasInitialError && !isTouched) ? 'error' : undefined, help: showError ? (_jsxs(_Fragment, { children: [hasError && _jsx("li", { children: error }), hasInitialError && (!isTouched || showInitialErrorAfterTouched) && (_jsx("li", { children: initialError }))] })) : null, ...restProps, children: _jsx(_Fragment, { children: children }) }));
            } }) }));
};
export default FormItem;
