import { jsx as _jsx } from "react/jsx-runtime";
import { InputNumber as $InputNumber } from 'antd';
import Field from './field';
export const InputNumber = ({ name, validate, fast, className, onChange: $onChange, onBlur: $onBlur, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value, onBlur }, form: { setFieldValue } }) => (_jsx($InputNumber, { ...restProps, name: name, id: name, value: value, onChange: (value) => {
            setFieldValue(name, value);
            $onChange && $onChange(value);
        }, onBlur: (event) => {
            onBlur(event);
            $onBlur && $onBlur(event);
        }, className: `w-100 ${className || ''}` })) }));
export default InputNumber;
