import { useState } from 'react';
import FxSingleFileDropzoneComponent from 'sharedV2/fileUpload/FxSingleFileDropzoneComponent';

export default (props) => {
    const [fileUploadError, setFileUploadError] = useState(null);
    const {
        label,
        isRequired,
        meta: { touched, error },
        outerDivStyle,
        labelDivStyle = '',
        style = {
            width: '100%',
            height: '80px',
        },
    } = props;

    return (
        <div className={outerDivStyle}>
            {label && (
                <div className={error ? 'form-control-label text-danger' : `${labelDivStyle}`}>
                    <label>{label}</label>
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            <FxSingleFileDropzoneComponent
                {...props}
                uploadedUrl={props.input.value || props.input.value === 0 ? props.input.value : null}
                fileErrorCallback={(error) => {
                    setFileUploadError(error);
                }}
                fileSuccessCallback={(url) => {
                    setFileUploadError(null);
                    props.input.onChange(url);
                }}
                fileDeleteCallback={() => {
                    setFileUploadError(null);
                    props.input.onChange(null);
                }}
            />
            {/*<SingleFileDropzoneComponent*/}
            {/*    {...props}*/}
            {/*    uploadedUrl={props.input.value || props.input.value === 0 ? props.input.value : null}*/}
            {/*    fileErrorCallback={(error) => {*/}
            {/*        setFileUploadError(error);*/}
            {/*    }}*/}
            {/*    fileSuccessCallback={(url) => {*/}
            {/*        setFileUploadError(null);*/}
            {/*        props.input.onChange(url);*/}
            {/*    }}*/}
            {/*    fileDeleteCallback={() => {*/}
            {/*        setFileUploadError(null);*/}
            {/*        props.input.onChange(null);*/}
            {/*    }}*/}
            {/*    style={style}*/}
            {/*/>*/}
            {/*{(fileUploadError || error) && (*/}
            {/*    <div className="text-help text-danger">*/}
            {/*        {fileUploadError}-{error}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};
