import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { fetchVehicleById } from 'actions/dashboard/vehicles_action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { mappedDrivers, mappedTransporters, mappedBrokers } from 'utils/form_utils';
import { FxSelectField, FxTextAreaFormikStacked, FxTextFieldStackedLabel } from '../../../../shared';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { getPhoneNumberWithoutPrefix } from 'utils/validation_utils';
import { get, groupBy } from 'lodash';
import { getAddress } from 'constant';
const HireVehicleInformationComponent = (props) => {
    const { formikProps, transporters, drivers, brokers, consignments = [], editId } = props;
    const groupedDrivers = useMemo(() => groupBy(drivers, 'id'), [drivers]);
    const groupedTransporters = useMemo(() => groupBy(transporters, 'id'), [transporters]);
    const groupedBrokers = useMemo(() => groupBy(brokers, 'id'), [brokers]);
    const { values, setFieldValue } = formikProps;
    useEffect(() => {
        if (values.vehicleId && +get(consignments, 'length', 0) <= 0 && !editId) {
            props.fetchVehicleById(values.vehicleId, props.accesstoken).then((res) => {
                const data = get(res, 'payload.data', {});
                if (data) {
                    setFieldValue('vehicleInfo', {
                        ownerName: '',
                        ownerMobile: '',
                        panNumber: '',
                        driverName: '',
                        driverMobile: '',
                        ownerAddress: '',
                        brokerName: '',
                        brokerMobile: '',
                    });
                    const brokerName = get(data, 'currentTransporterId', '');
                    const driverName = get(data, 'activeAssignment.user.id', '');
                    let driverMobile = getPhoneNumberWithoutPrefix(get(data, 'activeAssignment.user.phoneNumber', ''));
                    setFieldValue('vehicleInfo.driverName', driverName || '');
                    driverMobile = driverMobile && driverMobile.length > 10 ? driverMobile.substring(2) : driverMobile;
                    setFieldValue('vehicleInfo.driverMobile', driverMobile || '');
                    setFieldValue('vehicleInfo.ownerName', brokerName || '');
                    setFieldValue('vehicleInfo.brokerName', brokerName || '');
                    let mobileNumber = getPhoneNumberWithoutPrefix(get(groupedBrokers[brokerName], '0.contact.mobileNumber', ''));
                    setFieldValue('vehicleInfo.panNumber', get(groupedTransporters[brokerName], '0.panNumber', ''));
                    setFieldValue('vehicleInfo.ownerAddress', getAddress(get(groupedTransporters[brokerName], '0.address', '')));
                    mobileNumber = mobileNumber && mobileNumber.length > 10 ? mobileNumber.substring(2) : mobileNumber;
                    setFieldValue('vehicleInfo.ownerMobile', mobileNumber || '');
                    setFieldValue('vehicleInfo.brokerMobile', mobileNumber || '');
                }
            });
        }
    }, [values.vehicleId]);
    return (_jsxs("div", { children: [_jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `vehicleInfo.ownerName`, component: FxSelectField, options: mappedTransporters(transporters), label: "Owner Name", isRequired: true, onChange: (val) => {
                                let mobileNumber = getPhoneNumberWithoutPrefix(get(groupedTransporters[val], '0.contact.mobileNumber', ''));
                                mobileNumber =
                                    mobileNumber && mobileNumber.length > 10 ? mobileNumber.substring(2) : mobileNumber;
                                setFieldValue('vehicleInfo.ownerMobile', mobileNumber);
                                setFieldValue('vehicleInfo.panNumber', get(groupedTransporters[val], '0.panNumber', ''));
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `vehicleInfo.ownerMobile`, component: FxTextFieldStackedLabel, label: "Mobile No.", prependText: getPhonePrefixFromCountry() }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `vehicleInfo.panNumber`, component: FxTextFieldStackedLabel, label: "PAN No." }) }), _jsx("div", { className: "col-md-5", children: _jsx(Field, { type: 'text', name: `vehicleInfo.ownerAddress`, component: FxTextAreaFormikStacked, label: "Owner Address" }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `vehicleInfo.driverName`, component: FxSelectField, options: mappedDrivers(drivers), label: "Driver Name", onChange: (val) => {
                                setFieldValue('vehicleInfo.driverMobile', getPhoneNumberWithoutPrefix(get(groupedDrivers[val], '0.phoneNumber')));
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `vehicleInfo.driverMobile`, component: FxTextFieldStackedLabel, label: "Mobile No.", prependText: getPhonePrefixFromCountry() }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `vehicleInfo.brokerName`, component: FxSelectField, options: mappedBrokers(brokers), label: "Broker Name", onChange: (val) => {
                                let mobileNumber = getPhoneNumberWithoutPrefix(get(groupedBrokers[val], '0.contact.mobileNumber', ''));
                                mobileNumber =
                                    mobileNumber && mobileNumber.length > 10 ? mobileNumber.substring(2) : mobileNumber;
                                setFieldValue('vehicleInfo.brokerMobile', mobileNumber);
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `vehicleInfo.brokerMobile`, component: FxTextFieldStackedLabel, label: "Mobile No.", prependText: getPhonePrefixFromCountry() }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchVehicleById }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state.login, 'data.access_token', ''),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HireVehicleInformationComponent);
