import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get } from 'lodash';
import { Field } from 'formik';
import { mappedVehicleListMiniOptions, mappedObjectToOptions, mappedBranches } from '../../../utils/mapping_utils';
import { getMomentTime, DATE_FORMAT_TIMESTAMP } from '../../../../utils/date_utils';
import { FxSelectField, FxSelectSingleDate, FxTextAreaFormikStacked } from '../../../../shared';
import { ADDRESS_TYPES } from '../../../../utils/consignmentsUtils';
import BillNumberComponent from '../../bill_number/bill_number_component';
import { showConsignmentBasedLorryArrival } from 'components/dashboard/roles/erp_permission_utils';
import { mappedConsignmentsList } from 'utils/form_utils';
const BasicInformationComponent = (props) => {
    const { formikProps, editId, miniVehicleList, loggedInUser, setChallanFilters, challanFilters, isLorryArrival, branches, fetchConsignmentsFromNumber, } = props;
    let filteredVehicles;
    const { values, setFieldValue } = formikProps;
    const { hiredVehicles } = values;
    const showCNSearchField = isLorryArrival && showConsignmentBasedLorryArrival();
    if (hiredVehicles != 'true') {
        filteredVehicles = filter(miniVehicleList, (vehicle) => get(vehicle, 'vehicleOwnership') === 'OWNED');
    }
    else {
        filteredVehicles = filter(miniVehicleList, (vehicle) => get(vehicle, 'vehicleOwnership') !== 'OWNED');
    }
    const onChangeFetchChallans = (val) => {
        setChallanFilters({ ...challanFilters, ...val });
    };
    const branchOptions = useMemo(() => {
        return mappedBranches(branches);
    }, [branches]);
    const consignmentOptions = useMemo(() => {
        return mappedConsignmentsList(props.consignments);
    }, [props.consignments]);
    return (_jsxs("div", { children: [showCNSearchField && !editId && (_jsx("div", { className: "row", children: _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "CN Number", name: `consignmentNumber`, placeholder: "Select CN", component: FxSelectField, loadAsync: true, defaultOptions: true, callbackLoadOptions: true, promisifyOptions: fetchConsignmentsFromNumber, remoteOptions: consignmentOptions, value: get(values, `consignmentNumber`), onChange: (val) => {
                            props.handleCnNumberChange(val);
                        } }) }) })), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `arrivalType`, component: FxSelectField, options: mappedObjectToOptions(ADDRESS_TYPES), label: "Arrival Type", disabled: !!editId, onChange: (val) => onChangeFetchChallans({ arrivalType: val }) }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `arrivalDate`, timePicker: true, component: FxSelectSingleDate, label: "Arrival Date", isRequired: true, disabled: !!editId, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), _jsx("div", { className: "col-md-4 d-flex align-items-center", children: _jsxs("div", { className: "mt-1", children: [_jsx("div", { className: "radio d-inline-block mr-2", style: { marginLeft: 1.25 + 'rem' }, children: _jsxs("label", { className: "my-1", children: [_jsx(Field, { name: "hiredVehicles", component: "input", type: "radio", value: 'false', checked: values.hiredVehicles == 'false', disabled: !!editId || isLorryArrival }), ' ', "Own Vehicle"] }) }), _jsx("div", { className: "radio d-inline-block mr-2", style: { marginLeft: 1.25 + 'rem' }, children: _jsxs("label", { className: "my-1", children: [_jsx(Field, { name: "hiredVehicles", component: "input", type: "radio", value: 'true', checked: values.hiredVehicles == 'true', disabled: !!editId || isLorryArrival }), ' ', "Hire Vehicle"] }) })] }) })] }), _jsxs("div", { className: "row", children: [_jsx(BillNumberComponent, { formikProps: formikProps, isEdit: !!editId, isReadyToFetchBillNumber: true, onBranchSelect: (val) => {
                            setFieldValue('receivingBranchId', val);
                            onChangeFetchChallans({ branch: val });
                        }, fieldDisabled: { 'branch': !!editId, 'bookName': !!editId, 'stationaryNumber': !!editId }, fieldLabel: { 'stationaryNumber': 'Arrival No.' }, removeDivWrapper: true }), isLorryArrival && (_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: `receivingBranchId`, component: FxSelectField, disabled: !!editId, options: branchOptions, label: "Receiving Branch", isRequired: true, onChange: (val) => {
                                onChangeFetchChallans({ branch: val });
                            } }) }))] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `vehicleId`, component: FxSelectField, disabled: !!editId, options: mappedVehicleListMiniOptions(filteredVehicles, loggedInUser, true), label: "Vehicle", isRequired: true, onChange: (val) => onChangeFetchChallans({ vehicleId: val }) }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `actualAcknowledgementReceived`, timePicker: true, component: FxSelectSingleDate, label: "Actual Acknowledgement Received", isRequired: true, disabled: !!editId, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `loadingDate`, timePicker: true, component: FxSelectSingleDate, label: "Loading Date", valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), ' ', _jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `reachDate`, timePicker: true, component: FxSelectSingleDate, label: "Reach Date", valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: "deliveryConditions", type: "text", component: FxTextAreaFormikStacked, label: "Delivery Conditions" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: "unloadingAddress", type: "text", component: FxTextAreaFormikStacked, label: "Unloading Address" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: "remark", type: "text", component: FxTextAreaFormikStacked, label: "Remark" }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicInformationComponent);
