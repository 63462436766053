import { jsx as _jsx } from "react/jsx-runtime";
import { Radio as $Radio } from 'antd';
import Field from './field';
export const Radio = ({ name, validate, fast, onChange, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($Radio, { ...restProps, name: name, id: name, value: value, onChange: (event) => {
            setFieldValue(name, event.target.value);
            setFieldTouched(name, true, false);
            onChange && onChange(event);
        } })) }));
Radio.Group = ({ name, validate, fast, onChange, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($Radio.Group, { ...restProps, name: name, value: value, onChange: (event) => {
            setFieldValue(name, event.target.value);
            setFieldTouched(name, true, false);
            onChange && onChange(event);
        } })) }));
Radio.Button = $Radio.Button;
export default Radio;
