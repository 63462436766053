import { Component } from 'react';
import { FxReactSelect as Select } from '../../../../shared';
import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { FxSelect } from 'sharedV2/index';

class SelectGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crazy: false,
            clearable: true,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(value) {
        this.props.onGroupSelected(value);
    }

    render() {
        const { isLoading } = this.props;
        const { onGroupSelected, isAntd, ...rest } = this.props;
        if (this.props.isAntd) {
            return (
                <FxSelect
                    {...rest}
                    className={this.props.className}
                    placeholder={this.props.label || window.t('selectGroup')}
                    value={this.props.value}
                    options={orderBy(this.props.options, ['label', 'asc'])}
                    allowClear={get(this.props, 'clearable', this.state.clearable)}
                    onChange={this.handleSelectChange}
                    disabled={this.props.disabled}
                    loading={isLoading}
                />
            );
        }
        return (
            <Select
                className={this.props.className}
                simpleValue
                placeholder={this.props.label || window.t('selectGroup')}
                value={this.props.value}
                options={orderBy(this.props.options, ['label', 'asc'])}
                clearable={get(this.props, 'clearable', this.state.clearable)}
                onChange={this.handleSelectChange}
                disabled={this.props.disabled}
                isLoading={isLoading}
            />
        );
    }
}

SelectGroup.propTypes = {
    onGroupSelected: PropTypes.func.isRequired,
    value: PropTypes.number,
    options: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default SelectGroup;
