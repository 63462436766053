import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { isLocalEnv } from './constant';
import { getMomentTime } from 'utils/date_utils';

const fileVersion = process.env.RELEASE || getMomentTime().valueOf();

window.t = (text) => text;

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        /* default language when load the website in browser */
        lng: 'en',
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: 'en',
        /* debugger For Development environment */
        debug: isLocalEnv(),
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        },
        backend: {
            loadPath: `/public/locales/{{lng}}/{{ns}}.json?v=${fileVersion}`,
            addPath: '/public/locales/{{lng}}/{{ns}',
        },
    })
    .then(() => {
        window.t = i18n.t.bind(i18n); // Override the dummy implementation after initialization
    })
    .catch((err) => {
        console.error('Error initializing i18next:', err);
    });

export default i18n;
