import { jsx as _jsx } from "react/jsx-runtime";
import { Switch as AntSwitch } from 'antd';
import Field from './field';
export const Switch = ({ name, validate, fast, onChange, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx(AntSwitch, { ...restProps, checked: value, onChange: (checked, event) => {
            setFieldValue(name, checked);
            setFieldTouched(name, true, false);
            onChange && onChange(checked, event);
        } })) }));
Switch.displayName = 'Switch';
export default Switch;
