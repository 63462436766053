import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, find, get, isEmpty, pick, set } from 'lodash';
import * as Yup from 'yup';
import { createReportFromTemplate, fetchReportTemplates, } from 'actions/dashboard/reportTemplatesAction';
import { Field, Form, Formik } from 'formik';
import { FxSelectField, FxTextAreaFormikStacked } from '../../../shared';
import { mappedReportTemplates } from 'utils/reportTemplatesUtils';
import { REPORT_FORMAT, REPORT_REQUEST_MODE, saveAs } from 'utils/report_utils';
import { handleError } from 'constant';
import { Link } from 'react-router-dom';
import { isFetchReportTemplateAllowedForUser } from '../roles/permission_utils';
import { getEndDateWithCurrent, getMomentTime } from 'utils/date_utils';
import CalendarComponent from 'components/utils/calendar_component';
import { validateEmails } from 'utils/validation_utils';
import { FxModal, FxLoader, FxButton, FxEmailInput, FxRadioGroup, FxRadio } from 'sharedV2';
import { FxEyeOutlined } from 'sharedV2/FxIcons';
const GenerateReportForSelectedTemplateModal = (props) => {
    const { accesstoken, isOpen, onClose, onSuccess, extraParams, formatType, itemId, sendDateRange = false, isBulkPrint = false, emailSupport = false, } = props;
    const [initialValues] = useState({
        formatId: '',
        outFormat: REPORT_FORMAT.PDF,
        from: props.startDate ? props.startDate : getMomentTime().subtract(1, 'months').startOf('day'),
        to: props.endDate ? props.endDate : getMomentTime().endOf('day'),
        ...(props.selectField1
            ? { [get(props.selectField1, 'key')]: get(props.selectField1, 'defaultValue', null) }
            : {}),
    });
    const [submitError, setSubmitError] = useState('');
    const [reportTemplates, setReportTemplates] = useState([]);
    const [{ startDate, endDate }, setDateRange] = useState({
        startDate: props.startDate ? props.startDate : getMomentTime().subtract(1, 'months').startOf('day'),
        endDate: props.endDate ? props.endDate : getMomentTime().endOf('day'),
    });
    const [emails, setEmails] = useState([]);
    const [isExcel, setIsExcel] = useState(false);
    const [outputMode, setOutputMode] = useState(REPORT_FORMAT.PDF);
    const emailRef = React.useRef(false);
    const pdfPreviewRef = React.useRef(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const onSubmit = useCallback((values, { setSubmitting }) => {
        const showEmailOption = (emailSupport && emailRef.current && !pdfPreviewRef.current) || isBulkPrint;
        const outputFormat = isExcel ? outputMode : REPORT_FORMAT.PDF;
        if (showEmailOption) {
            const validEmails = filter(validateEmails(emails.join(',')), (e) => get(e, 'isValid'));
            if (+get(validEmails, 'length') <= 0) {
                alert('Please select atleast one valid email');
                setSubmitting(false);
                return;
            }
        }
        const foundTemplate = find(reportTemplates, { id: values.formatId });
        const formatUrl = get(foundTemplate, 'url');
        const formatType = get(foundTemplate, 'type');
        const requestMode = showEmailOption ? REPORT_REQUEST_MODE.EMAIL : REPORT_REQUEST_MODE.DOWNLOAD;
        const paramsFromValues = { ...pick(values, ['description']) };
        if (get(props.selectField1, 'key')) {
            set(paramsFromValues, get(props.selectField1, 'key'), get(values, get(props.selectField1, 'key')));
        }
        const params = {
            formatUrl,
            formatType,
            itemId,
            outputFormat,
            emails: showEmailOption ? emails.join(',') : null,
            ...(sendDateRange && { from: getMomentTime(values.from).startOf('day').valueOf() }),
            ...(sendDateRange && { to: getMomentTime(values.to).endOf('day').valueOf() }),
            ...extraParams,
            ...paramsFromValues,
            requestMode,
            downloadable: !pdfPreviewRef.current,
        };
        if (props.localTransporterAccount) {
            params.accountId = props.localTransporterAccount;
        }
        props
            .createReportFromTemplate(accesstoken, params)
            .then((res) => {
            setSubmitting(false);
            if (!res.error) {
                // download report here
                if (!showEmailOption) {
                    const result = get(res, 'payload.data');
                    const url = get(result, 'fileUrl');
                    if (pdfPreviewRef.current || previewModal) {
                        previewModal && setPreviewUrl(url);
                        return;
                    }
                    else {
                        saveAs(url, url);
                    }
                    if (props.updateJobWithDoc) {
                        props.updateJobWithDoc(url, 'Document');
                    }
                }
                else {
                    alert('Your request has been accepted. Bills will be sent to your email.');
                }
                if (onSuccess) {
                    onSuccess();
                    resetStates();
                }
            }
            else if (res.error) {
                throw new Error(handleError(res.payload.response));
            }
        })
            .catch((err) => {
            setPreviewModal(false);
            setSubmitting(false);
            setSubmitError(handleError(err));
        });
    }, [itemId, extraParams, reportTemplates, emails, isExcel, pdfPreviewRef.current, previewModal, outputMode]);
    useEffect(() => {
        if (!formatType) {
            return;
        }
        const params = { type: formatType };
        if (isFetchReportTemplateAllowedForUser(props.login.scope)) {
            props
                .fetchReportTemplates(accesstoken, params, props.localTransporterAccount)
                .then((res) => {
                if (res.error) {
                    throw new Error(handleError(res.payload.response));
                }
                setReportTemplates(get(res, 'payload.data', []));
            })
                .catch((err) => {
                setSubmitError(handleError(err));
            });
        }
    }, [formatType]);
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            formatId: Yup.number().required('Please select template.'),
        });
    }, []);
    const onEmail = (submitForm) => {
        pdfPreviewRef.current = false;
        emailRef.current = true;
        submitForm();
    };
    const onPreview = ({ submitForm, validateForm }) => {
        validateForm().then((errors) => {
            if (isEmpty(errors)) {
                setSubmitError('');
                setPreviewUrl('');
                setPreviewModal(true);
                pdfPreviewRef.current = true;
                submitForm();
            }
        });
    };
    const onChangeTemplate = (id, setFieldValue) => {
        const selectedTemplate = find(reportTemplates, { id });
        if (selectedTemplate) {
            const isExcel = get(selectedTemplate, 'url', '')?.split('.')?.pop() === 'xlsx';
            setIsExcel(isExcel);
        }
    };
    const resetStates = () => {
        setIsExcel(false);
        setEmails([]);
        setSubmitError('');
    };
    const modalHeight = '85vh';
    const modalWidth = '85vw';
    const PdfPreview = ({ setSubmitting }) => {
        return (_jsx(FxModal, { open: isOpen, onCancel: () => {
                setPreviewModal(false);
                pdfPreviewRef.current = false;
                setSubmitting(false);
            }, footer: null, style: { minWidth: modalWidth, minHeight: modalHeight, top: '25px' }, title: 'Preview', themeModify: { paddingMD: '8px', paddingContentHorizontalLG: '8px' }, children: previewUrl ? (_jsx("object", { data: `${previewUrl}`, type: "application/pdf", style: { width: '100%', height: modalHeight } })) : !submitError ? (_jsxs("div", { style: { height: modalHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }, children: ['    ', _jsx(FxLoader, {})] })) : (_jsx("div", { className: "text-danger", children: submitError })) }));
    };
    return (_jsx(_Fragment, { children: _jsx(FxModal, { open: isOpen, onCancel: () => {
                onClose();
                resetStates();
            }, footer: null, style: { minWidth: '40vw' }, children: _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema, children: ({ setFieldValue, isSubmitting, submitForm, values, validateForm, setSubmitting }) => {
                    return (_jsxs(Form, { children: [_jsx(Field, { name: "formatId", label: 'Template', clearable: false, options: mappedReportTemplates(reportTemplates), component: FxSelectField, onChange: (v) => onChangeTemplate(v, setFieldValue), isRequired: true, className: 'mb-0' }), _jsx(Link, { to: "/dashboard/settings/reporttemplates/add", className: "medium", target: "_blank", children: "Can't Find Template?. Add Here!" }), isExcel && (_jsxs("div", { className: "d-flex flex-wrap mt-4 mb-3", children: [_jsx("div", { className: "title-2 mr-2", children: "Output Format: " }), _jsxs(FxRadioGroup, { size: "small", value: outputMode, optionType: "button", className: "float-right", onChange: (e) => setOutputMode(e.target.value), children: [_jsx(FxRadio, { value: REPORT_FORMAT.PDF, children: "Pdf" }), _jsx(FxRadio, { value: REPORT_FORMAT.CSV, children: "Excel" })] })] })), !!props.selectField1 && (_jsx("div", { className: "mt-3", children: _jsx(Field, { name: get(props.selectField1, 'key'), label: get(props.selectField1, 'label'), clearable: false, options: get(props.selectField1, 'fieldOptions'), component: FxSelectField, className: 'mb-0' }) })), sendDateRange && (_jsx("div", { className: "w-100 mt-3 mb-2", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                        setFieldValue('from', startDate);
                                        setFieldValue('to', endDate);
                                        setDateRange({ startDate, endDate });
                                    }, startDate: startDate, endDate: getEndDateWithCurrent(endDate), showLastSixMonth: true, width: "100%" }) })), _jsx(Field, { name: `description`, label: "Description", component: FxTextAreaFormikStacked, numberOfRow: 3, showLabelonTop: true }), props.children, (isBulkPrint || emailSupport) && (_jsx(FxEmailInput, { onChange: (value) => setEmails(value) })), submitError && _jsx("div", { className: "text-danger", children: submitError }), _jsxs("div", { className: "mt-2", children: [_jsx(FxButton, { className: "pull-right", htmlType: "submit", type: "primary", disabled: isSubmitting, title: 'Download/Print', onClick: () => {
                                            emailRef.current = false;
                                            pdfPreviewRef.current = false;
                                        }, children: isSubmitting && !emailRef.current ? (_jsxs("span", { className: "", children: [_jsx("i", { className: "fa fa-spinner fa-pulse" }), " Downloading"] })) : isBulkPrint ? ('Email') : ('Download') }), !isBulkPrint && (_jsx(FxButton, { icon: _jsx(FxEyeOutlined, {}), disabled: isSubmitting, onClick: () => onPreview({ submitForm, validateForm }), children: "Preview" })), !isBulkPrint && emailSupport && (_jsx(FxButton, { onClick: () => onEmail(submitForm), className: "float-right mr-2", type: "primary", title: "Email", disabled: isSubmitting, children: isSubmitting && emailRef.current && !pdfPreviewRef.current ? (_jsxs("span", { className: "", children: [_jsx("i", { className: "fa fa-spinner fa-pulse" }), " Emailing"] })) : ('Email') })), _jsx(FxButton, { onClick: () => {
                                            onClose();
                                            setEmails([]);
                                        }, htmlType: "button", className: "float-right mr-2", children: "Close" }), _jsx("div", { className: 'clearfix' })] }), previewModal && PdfPreview({ setSubmitting })] }));
                } }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createReportFromTemplate,
        fetchReportTemplates,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        localTransporterAccount: get(state, 'local.localTransporterAccount'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GenerateReportForSelectedTemplateModal);
