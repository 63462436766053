import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { isArray } from 'lodash';
import { FxDatePicker as $DatePicker, FxDateRangePicker as $RangePicker, FxTimePicker as $TimePicker } from '../';
import Field from './field';
import moment from 'moment-timezone';
export function valueToMoment(value) {
    switch (typeof value) {
        case 'number':
        case 'string':
            return moment(value);
        case 'object':
            return value;
        default:
            return null;
    }
}
function valueArrayToMomentArray(value) {
    if (isArray(value) && value.length === 2) {
        return [valueToMoment(value[0]), valueToMoment(value[1])];
    }
    else if (isArray(value) && value.length === 1) {
        return [valueToMoment(value[0])];
    }
    return null;
}
export const DatePicker = ({ name, validate, formikFormat, onChange, fast, className, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($DatePicker, { ...restProps, value: valueToMoment(value), id: name, onChange: (date, dateString) => {
            setFieldValue(name, date ? date.valueOf() : null);
            setFieldTouched(name, true, false);
            if (onChange)
                onChange(date, dateString);
        }, className: `w-100 ${className || ''}` })) }));
export const RangePicker = ({ name, validate, onChange, className, ...restProps }) => (_jsx(Field, { name: name, validate: validate, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($RangePicker, { ...restProps, name: name, value: valueArrayToMomentArray(value), onChange: (dates, dateStrings) => {
            setFieldValue(name, dates);
            setFieldTouched(name, true, false);
            if (onChange)
                onChange(dates, dateStrings);
        }, className: `w-100 ${className || ''}` })) }));
export const TimePicker = ({ name, validate, onChange, className, ...restProps }) => (_jsx(Field, { name: name, validate: validate, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($TimePicker, { ...restProps, value: valueToMoment(value), id: name, onChange: (date, dateString) => {
            setFieldValue(name, date ? date.valueOf() : null);
            setFieldTouched(name, true, false);
            if (onChange)
                onChange(date, dateString);
        }, className: `w-100 ${className || ''}` })) }));
