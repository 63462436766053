import { endsWith, filter, get, includes, isArray, isEmpty, last, map, omitBy, toLower } from 'lodash';
import { combineNameAddress, parseAddress } from '../constant';
import {
    addressNotToUsedForAccount,
    FLEETX_ACCOUNTS,
    getFlagValueFromAccountConfig,
    showOnlyNearestAddressWithCategories,
} from 'utils/account_utils';
import { ADDRESS_BOOK_CATEGORY_OPTIONS } from 'utils/job_route_utils';

export const addressFilterOptions = (options, filter, currentValues, filteringOptions, existingValue) => {
    const MAX_ADDRESS_DISPLAY_LIMIT = 50;
    const savedOptions = [];
    const length = options.length;
    let count = 0;
    const existingValueId = existingValue && !!existingValue.match && !existingValue.match(/~/) ? existingValue : '';
    const existingValueString =
        existingValue && !!existingValue.match && !!existingValue.match(/~/)
            ? '~' + last(existingValue.split('~'))
            : '';

    for (let i = 0; i < length; i++) {
        const option = get(options, `[${i}]`, {});
        if (existingValueId && get(option, 'value', '') == existingValueId) {
            savedOptions.push(option);
        } else if (existingValueString && endsWith(get(option, 'value', ''), existingValueString)) {
            savedOptions.push(option);
        } else if (
            count < MAX_ADDRESS_DISPLAY_LIMIT &&
            (includes(toLower(get(option, 'address', '')), toLower(filter)) ||
                includes(toLower(get(option, 'label', '')), toLower(filter)))
        ) {
            savedOptions.push(option);
            count++;
        } else if (!existingValue && count >= MAX_ADDRESS_DISPLAY_LIMIT) {
            break;
        }
    }
    return savedOptions;
};

export const renderAddressBookOption = (option, state) => {
    if (state.context === 'value') {
        return get(option, 'label', parseAddress(get(option, 'address', '')));
    }
    return (
        <div>
            <div>{option.label}</div>
            {option.address && <div>{parseAddress(option.address)}</div>}
        </div>
    );
};

export const ADDRESS_FILTER_BUTTONS = {
    ENABLE: {
        name: 'Enable',
        order: 1,
        key: 'ENABLE',
    },
    DISABLE: {
        name: 'Disable',
        order: 2,
        key: 'DISABLE',
    },
};

export function getFilteredAddressBookForNearestAddress(addressBook) {
    const allowedCategories = showOnlyNearestAddressWithCategories();
    const addressNotToUsed = addressNotToUsedForAccount();

    const shouldIncludeAddress = (ad) => {
        let include = true;
        if (!isEmpty(allowedCategories)) {
            include = include && includes(allowedCategories, ad.addressBookCategory);
        }
        if (!isEmpty(addressNotToUsed)) {
            include = include && !includes(addressNotToUsed, ad.id);
        }
        return include;
    };

    if (!isEmpty(allowedCategories) || !isEmpty(addressNotToUsed)) {
        if (isArray(addressBook)) {
            return filter(addressBook, (ad) => {
                return shouldIncludeAddress(ad);
            });
        } else {
            return omitBy(addressBook, (ad) => {
                return !shouldIncludeAddress(ad);
            });
        }
    } else {
        return addressBook;
    }
}

const BISLERY_ADDRESS_BOOK_OPTIONS = [
    { value: 'DISTRIBUTOR', label: 'Distributor' },
    { value: 'RETAILER', label: 'Retailer' },
    { value: 'HOTEL_DHABA', label: 'Hotel' },
    { value: 'RESTAURANT', label: 'Restaurant' },
    { value: 'STADIUM', label: 'Stadium' },
    { value: 'HOSPITAL', label: 'Hospital' },
    { value: 'END_CONSUMER', label: 'End Consumer' },
    { value: 'OFFICE_GOVERNMENT', label: 'Office - Government' },
    { value: 'OFFICE_PRIVATE', label: 'Office - Private' },
    { value: 'OFFICE', label: 'Office' },
    { value: 'AUTHORISED_SERVICE_CENTER', label: 'Authorized Service Center' },
    { value: 'FUEL_STATION', label: 'Fuel Station' },
    { value: 'PLANT_COPACKER', label: 'Plant - Copacker' },
    { value: 'PLANT_FRANCHISE', label: 'Plant - Franchise' },
    { value: 'PLANT_BIPL', label: 'Plant BIPL' },
    { value: 'MALL', label: 'Mall' },
    { value: 'DISTRIBUTION_CENTER', label: 'Store - Dark Store' },
    { value: 'RETAIL_STORE', label: 'Retail Store' },
    { value: 'CORPORATE', label: 'Corporate' },
    { value: 'AIRPORT', label: 'Airport' },
    { value: 'RAILWAY_STATION', label: 'Railway Station' },
];

export function getAddressBookCategoryOptions() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.BISLERI:
            return BISLERY_ADDRESS_BOOK_OPTIONS;
        default:
            return ADDRESS_BOOK_CATEGORY_OPTIONS;
    }
}

export function mappedAddressBookNameOnly(addressbook) {
    return map(addressbook, (o) => {
        return createNameFromAddress(o);
    });
}

export const createNameFromAddress = (o) => {
    const address = combineNameAddress(o.name, o.address);
    if (o.id) {
        return {
            value: o.name,
            label: o.name,
            address: o.address,
        };
    } else {
        return {
            value: o.name,
            label: o.name,
            address: o.address,
        };
    }
};

export function getMaxNearRadiusValueForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case 13278:
            return 5;
        default:
            return 5000;
    }
}

export function showTemperatureFieldsInAddressBook() {
    return getFlagValueFromAccountConfig('showTemperatureFieldsInAddressBook');
}

export function getFormFieldConfigForAddressBook() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        city: {
            field: 'city',
            show: true,
            mandatory: includes([FLEETX_ACCOUNTS.FLEETX, FLEETX_ACCOUNTS.MAERSK], accountId),
        },
        pinCode: {
            field: 'pinCode',
            show: true,
            mandatory: includes([FLEETX_ACCOUNTS.FLEETX, FLEETX_ACCOUNTS.MAERSK], accountId),
        },
    };
}

export const STOPPAGE_TYPE_ICON = {
    CONSTRUCTION_SITE: {
        image: '/public/img/stoppageType/construction new.svg',
        name: 'Construction Site',
    },
    CUSTOMER: {
        image: '/public/img/stoppageType/customer new.svg',
        name: 'Customer',
    },
    DEPOT: {
        image: '/public/img/stoppageType/depot new.svg',
        name: 'Depot',
    },
    DHABA: {
        image: '/public/img/stoppageType/dhaba new.svg',
        name: 'Dhaba',
    },
    FACTORY: {
        image: '/public/img/stoppageType/factory new.svg',
        name: 'Factory',
    },
    FARM: {
        image: '/public/img/stoppageType/farm new.svg',
        name: 'Farm',
    },
    FUEL_STATION: {
        image: '/public/img/stoppageType/fuel station new.svg',
        name: 'Fuel Station',
    },
    HOTEL: {
        image: '/public/img/stoppageType/hotel new.svg',
        name: 'Hotel',
    },
    LIQUOR_SHOP: {
        image: '/public/img/stoppageType/liquor new.svg',
        name: 'Liquor Shop',
    },
    PLANT: {
        image: '/public/img/stoppageType/plant new.svg',
        name: 'Plant',
    },
    REPAIR_SHOP: {
        image: '/public/img/stoppageType/repair shop new.svg',
        name: 'Repair Shop',
    },
    ROADSIDE: {
        image: '/public/img/stoppageType/roadside new.svg',
        name: 'Roadside',
    },
    SERVICE_CENTER: {
        image: '/public/img/stoppageType/service center new.svg',
        name: 'Service Center',
    },
    TEMPLE: {
        image: '/public/img/stoppageType/temple new.svg',
        name: 'Temple',
    },
    WEIGHBRIDGE: {
        image: '/public/img/stoppageType/weighbridge new.svg',
        name: 'Weighbridge',
    },
    WORKSHOP: {
        image: '/public/img/stoppageType/workshop new.svg',
        name: 'Workshop',
    },
    TOLL: {
        image: '/public/img/stoppageType/toll new.svg',
        name: 'Toll',
    },
};
