import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox as $Checkbox } from 'antd';
import Field from './field';
export const Checkbox = ({ name, validate, fast, onChange, ...restProps }) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($Checkbox, { ...restProps, name: name, id: name, checked: value, onChange: (event) => {
            setFieldValue(name, event.target.checked);
            setFieldTouched(name, true, false);
            onChange && onChange(event);
        } })) }));
export default Checkbox;
export const CheckboxGroup = ({ name, validate, onChange, ...restProps }) => (_jsx(Field, { name: name, validate: validate, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx($Checkbox.Group, { ...restProps, value: value, onChange: (value) => {
            setFieldValue(name, value);
            setFieldTouched(name, true, false);
            onChange && onChange(value);
        } })) }));
