import axios from 'axios';
import { isViewConsignmentsAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { get, includes, set, unset } from 'lodash';
import { parseQueryParams, ROOT_API_ERP, ROOT_REPORTING_NODE_API_URL } from 'constant';
import { getMomentTime } from 'utils/date_utils';
export const CREATE_CONSIGNMENT = 'CREATE_CONSIGNMENT';
export const UPDATE_CONSIGNMENT = 'UPDATE_CONSIGNMENT';
export const DELETE_CONSIGNMENT = 'DELETE_CONSIGNMENT';
export const FETCH_CONSIGNMENTS_PAGINATED = 'FETCH_CONSIGNMENTS_PAGINATED';
export const FETCH_CONSIGNMENTS_PAGINATED_DRAFTS = 'FETCH_CONSIGNMENTS_PAGINATED_DRAFTS';
export const FETCH_CONSIGNMENTS = 'FETCH_CONSIGNMENTS';
export const FETCH_CONSIGNMENT = 'FETCH_CONSIGNMENT';
export const FETCH_CONSIGNMENT_SUMMARY = 'FETCH_CONSIGNMENT_SUMMARY';
export const FETCH_CONSIGNMENT_HISTORY = 'FETCH_CONSIGNMENT_HISTORY';
export const CANCEL_CONSIGNMENT = 'CANCEL_CONSIGNMENT';
export const FETCH_CONSIGNMENT_IDS = 'FETCH_CONSIGNMENT_IDS';
// draft
export const CREATE_CONSIGNMENT_DRAFT = 'CREATE_CONSIGNMENT_DRAFT';
export const UPDATE_CONSIGNMENT_DRAFT = 'UPDATE_CONSIGNMENT_DRAFT';
export const DELETE_CONSIGNMENT_DRAFT = 'DELETE_CONSIGNMENT_DRAFT';
export const FETCH_CONSIGNMENTS_DRAFT = 'FETCH_CONSIGNMENTS_DRAFT';
export const FETCH_CONSIGNMENT_DRAFT = 'FETCH_CONSIGNMENT_DRAFT';
export const DOWNLOAD_CONSIGNMENT_REPORT = 'DOWNLOAD_CONSIGNMENT_REPORT';
const draftSuffix = '/draft';
export function createConsignment(accesstoken, data, draft = false) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}consignment${draft ? draftSuffix : ''}`, data, config);
    return {
        type: draft ? CREATE_CONSIGNMENT_DRAFT : CREATE_CONSIGNMENT,
        promise: request,
    };
}
export function updateConsignment(accesstoken, data, draft = false) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}consignment${draft ? draftSuffix : ''}`, data, config);
    return {
        type: draft ? UPDATE_CONSIGNMENT_DRAFT : UPDATE_CONSIGNMENT,
        promise: request,
    };
}
export function fetchConsignments(accesstoken, params = {}) {
    const { startDate, endDate, billingOfficeId, billingPartyId, consignorId, consigneeId, consignmentOfficeId, consignmentNumber, fromPlace, toPlace, vehicleOwnership, adviceNumber, consignmentType, status, fromBranchId, toBranchId, toBranchIdExcept, pickupType, deliveryType, nature, withBalanceMaterialsAtBranch, pickupChallanCompleted, withFreightBill, withAdvanceUnpaid, ids, unitType, vehicleId, includeDrafts, allUnitType, anyUnitType, mappedToHireSlip, routeIds, materialIds, materialGroupIds, includeCancelled, consignmentNature, driverId, withIncompleteFreightBill, associateVendorId, withOperationBill, billingPartyBranchId, targetCurrency, includePartialHireSlip, includePartialJob, jobBookingId, } = params;
    let advanceStatus = withAdvanceUnpaid;
    if (includes(['PENDING', 'COMPLETED', 'BOTH'], withAdvanceUnpaid)) {
        const advanceStatusMapping = {
            'PENDING': true,
            'COMPLETED': false,
            'BOTH': null,
        };
        advanceStatus = advanceStatusMapping[withAdvanceUnpaid];
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate ? getMomentTime(startDate).valueOf() : '',
            to: endDate ? getMomentTime(endDate).valueOf() : '',
            type: consignmentType,
            billingOfficeId,
            billingPartyId,
            consignorId,
            consigneeId,
            consignmentOfficeId,
            consignmentNumber,
            fromPlace,
            toPlace,
            vehicleOwnership,
            adviceNumber,
            status,
            fromBranchId,
            toBranchId,
            toBranchIdExcept,
            pickupType,
            deliveryType,
            nature,
            withBalanceMaterialsAtBranch,
            pickupChallanCompleted,
            withFreightBill,
            withAdvanceUnpaid: advanceStatus,
            ids,
            unitType,
            vehicleId,
            includeDrafts,
            allUnitType,
            anyUnitType,
            mappedToHireSlip,
            routeIds,
            materialIds,
            materialGroupIds,
            includeCancelled,
            consignmentNature,
            driverId,
            includePaymentDeduction: true,
            withIncompleteFreightBill,
            associateVendorId,
            withOperationBill,
            billingPartyBranchId,
            targetCurrency,
            includePartialHireSlip,
            includePartialJob,
            jobBookingId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/listing`, config);
    return {
        type: FETCH_CONSIGNMENTS,
        promise: request,
    };
}
export function fetchConsignmentIds(accesstoken, params = {}) {
    if (!isViewConsignmentsAllowedForUser(get(window.FLEETX_LOGGED_IN_DATA, 'scope', [])))
        return;
    const { ids, size = 1000, startDate, endDate } = params;
    const from = startDate ? getMomentTime(startDate).valueOf() : '';
    const to = endDate ? getMomentTime(endDate).valueOf() : '';
    unset(params, 'startDate');
    unset(params, 'endDate');
    unset(params, 'ids');
    unset(params, 'size');
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ids: ids ? ids.join(',') : undefined,
            size,
            ...params,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/search`, config);
    return {
        type: FETCH_CONSIGNMENT_IDS,
        promise: request,
    };
}
export function fetchConsignmentsPaginated(accesstoken, params = {}) {
    const { startDate, endDate, billingOfficeId, billingPartyId, consignorId, consigneeId, consignmentOfficeId, consignmentNumber, fromPlace, toPlace, vehicleOwnership, adviceNumber, consignmentType, status, fromBranchId, toBranchId, toBranchIdExcept, pickupType, deliveryType, nature, withBalanceMaterialsAtBranch, pickupChallanCompleted, withFreightBill, withAdvanceUnpaid, ids, unitType, vehicleId, includeDrafts, allUnitType, anyUnitType, mappedToHireSlip, routeIds, materialIds, materialGroupIds, includeCancelled, consignmentNature, page, size, sortName, sortOrder, driverId, jobBookingId, paymentCompleted, materialCustomFieldValues, materialCustomFieldIds, consignmentCustomFieldIds, consignmentCustomFieldValues, currentTransporterId, } = params;
    let advanceStatus = withAdvanceUnpaid;
    if (includes(['PENDING', 'COMPLETED', 'BOTH'], withAdvanceUnpaid)) {
        const advanceStatusMapping = {
            'PENDING': true,
            'COMPLETED': false,
            'BOTH': null,
        };
        advanceStatus = advanceStatusMapping[withAdvanceUnpaid];
    }
    let sort = '';
    if (sortName) {
        sort = `${sortName}`;
    }
    if (sortName && sortOrder) {
        sort += `,${sortOrder}`;
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate ? getMomentTime(startDate).valueOf() : '',
            to: endDate ? getMomentTime(endDate).valueOf() : '',
            type: consignmentType,
            billingOfficeId,
            billingPartyId,
            consignorId,
            consigneeId,
            consignmentOfficeId,
            consignmentNumberContains: consignmentNumber,
            fromPlace,
            toPlace,
            vehicleOwnership,
            adviceNumber,
            status,
            fromBranchId,
            toBranchId,
            toBranchIdExcept,
            pickupType,
            deliveryType,
            nature,
            withBalanceMaterialsAtBranch,
            pickupChallanCompleted,
            withFreightBill,
            withAdvanceUnpaid: advanceStatus,
            ids,
            unitType,
            vehicleId,
            includeDrafts,
            allUnitType,
            anyUnitType,
            mappedToHireSlip,
            routeIds,
            materialIds,
            materialGroupIds,
            includeCancelled,
            consignmentNature,
            page: page - 1,
            size,
            sort,
            driverId,
            jobBookingId,
            includePaymentDeduction: true,
            paymentCompleted,
            materialCustomFieldValues,
            materialCustomFieldIds,
            consignmentCustomFieldIds,
            consignmentCustomFieldValues,
            currentTransporterId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/search`, config);
    return {
        type: FETCH_CONSIGNMENTS_PAGINATED,
        promise: request,
    };
}
export function fetchConsignmentsPaginatedDrafts(accesstoken, params = {}) {
    const { startDate, endDate, billingOfficeId, billingPartyId, consignorId, consigneeId, consignmentOfficeId, consignmentNumber, fromPlace, toPlace, vehicleOwnership, adviceNumber, consignmentType, status, fromBranchId, toBranchId, toBranchIdExcept, pickupType, deliveryType, nature, withBalanceMaterialsAtBranch, pickupChallanCompleted, withFreightBill, withAdvanceUnpaid, ids, unitType, vehicleId, includeDrafts, allUnitType, anyUnitType, mappedToHireSlip, routeIds, materialIds, materialGroupIds, includeCancelled, consignmentNature, page, size, driverId, materialCustomFieldValues, materialCustomFieldIds, consignmentCustomFieldIds, consignmentCustomFieldValues, } = params;
    let advanceStatus = withAdvanceUnpaid;
    if (includes(['PENDING', 'COMPLETED', 'BOTH'], withAdvanceUnpaid)) {
        const advanceStatusMapping = {
            'PENDING': true,
            'COMPLETED': false,
            'BOTH': null,
        };
        advanceStatus = advanceStatusMapping[withAdvanceUnpaid];
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate ? getMomentTime(startDate).valueOf() : '',
            to: endDate ? getMomentTime(endDate).valueOf() : '',
            type: consignmentType,
            billingOfficeId,
            billingPartyId,
            consignorId,
            consigneeId,
            consignmentOfficeId,
            consignmentNumberContains: consignmentNumber,
            fromPlace,
            toPlace,
            vehicleOwnership,
            adviceNumber,
            status,
            fromBranchId,
            toBranchId,
            toBranchIdExcept,
            pickupType,
            deliveryType,
            nature,
            withBalanceMaterialsAtBranch,
            pickupChallanCompleted,
            withFreightBill,
            withAdvanceUnpaid: advanceStatus,
            ids,
            unitType,
            vehicleId,
            includeDrafts,
            allUnitType,
            anyUnitType,
            mappedToHireSlip,
            routeIds,
            materialIds,
            materialGroupIds,
            includeCancelled,
            consignmentNature,
            page: page - 1,
            size,
            driverId,
            includePaymentDeduction: true,
            materialCustomFieldValues,
            materialCustomFieldIds,
            consignmentCustomFieldIds,
            consignmentCustomFieldValues,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/draft/search`, config);
    return {
        type: FETCH_CONSIGNMENTS_PAGINATED_DRAFTS,
        promise: request,
    };
}
export function fetchConsignment(accesstoken, id, draft = false) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}consignment${draft ? draftSuffix : ''}/${id}`, config);
    return {
        type: draft ? FETCH_CONSIGNMENT_DRAFT : FETCH_CONSIGNMENT,
        promise: request,
    };
}
export function deleteConsignment(accesstoken, id, draft = false) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}consignment${draft ? draftSuffix : ''}/${id}`, config);
    return {
        type: draft ? DELETE_CONSIGNMENT_DRAFT : DELETE_CONSIGNMENT,
        promise: request,
    };
}
export function fetchConsignmentSummary(accesstoken, params) {
    const advanceStatus = get(params, 'withAdvanceUnpaid', null);
    if (includes(['PENDING', 'COMPLETED', 'BOTH'], advanceStatus)) {
        const advanceStatusMapping = {
            'PENDING': true,
            'COMPLETED': false,
            'BOTH': null,
        };
        set(params, 'withAdvanceUnpaid', advanceStatusMapping[advanceStatus]);
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/summary`, config);
    return {
        type: FETCH_CONSIGNMENT_SUMMARY,
        promise: request,
    };
}
export function fetchNodeConsignmentReport(accesstoken, params, data) {
    let format = {};
    const { reportFormat } = data;
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/getConsignmentReport`, data, config);
    return {
        type: DOWNLOAD_CONSIGNMENT_REPORT,
        promise: request,
    };
}
export function fetchConsignmentHistory(accesstoken, { id }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/history/${id}`, config);
    return {
        type: FETCH_CONSIGNMENT_HISTORY,
        promise: request,
    };
}
export function cancelConsignments(accesstoken, { id }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_ERP}consignment/cancel/${id}`, {}, config);
    return {
        type: CANCEL_CONSIGNMENT,
        promise: request,
    };
}
