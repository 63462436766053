import { get } from 'lodash';
import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig } from 'utils/account_utils';
export function enableNewSettings() {
    if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) > 10000) {
        return true;
    }
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return (getFlagValueFromAccountConfig('enableNewSettingsPage') ||
                get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false));
    }
}
export function isAntdDateRangePickerEnabled() {
    return getFlagValueFromAccountConfig('isAntdDateRangePickerEnabled');
}
export function showGridInChart() {
    return getFlagValueFromAccountConfig('showGridInChart');
}
export function isFormFieldRequired() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.VORA_TRANS_FREIGHT:
        case FLEETX_ACCOUNTS.MAHALAKSHMI:
            return true;
        default:
            return false;
    }
}
export function isShreeCementAll() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHREE_CEMENT_ALL:
            return true;
        default:
            return false;
    }
}
export function isWebWorkerEnabledComputationForAccountFlag() {
    return getFlagValueFromAccountConfig('isWebWorkerEnabledComputationForAccount');
}
export const getPdfFileExtension = (img) => {
    const url = get(img, 'url', '').toLowerCase();
    const imageUrl = get(img, 'imageUrl', '').toLowerCase();
    const isPdf = url.endsWith('.pdf') ||
        imageUrl.endsWith('.pdf') ||
        (typeof img === 'string' && img.toLowerCase().endsWith('.pdf')); // Check if img is a string and use toLowerCase
    return isPdf;
};
export function isShowSourceAndDestinationBasedOnAddressBookType() {
    return getFlagValueFromAccountConfig('showSourceAndDestinationBasedOnAddressBookType');
}
