import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useReducer, useState } from 'react';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, toSafeInteger, filter } from 'lodash';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isCreateAdvanceAllowedForUser, isViewBranchAllowedForUser } from '../roles/permission_utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CalendarComponent from '../../utils/calendar_component';
import { getMomentTime } from '../../../utils/date_utils';
import { filtersReducer, mappedOnlyConsignorsConsignees, mappedTransporters } from '../../../utils/form_utils';
import { stringifyUrlThroughState } from '../../../constant';
import { queryString } from '../../../utils/string_utils';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { FxTab, FxTabsWrapper } from '../../../sharedV2';
import { mappedVehicleListMiniOptions } from 'components/utils/mapping_utils';
import { fetchRoutesMiniList } from '../../../actions/dashboard/route_dispatch/routes_action';
import { fetchAllTransporters } from '../../../actions/dashboard/finances/transporter_actions';
import MoreFiltersDrawerComponent from 'components/utils/more_filters/MoreFiltersDrawerComponent';
import MoreFiltersButtonComponent from 'components/utils/more_filters/MoreFiltersButtonComponent';
import PageLayoutComponent from 'components/utils/pageLayout';
import { useTranslation } from 'react-i18next';
import PaymentDashboardComponent from './PaymentDashboardComponent';
import PaymentFiltersComponent from './PaymentFiltersComponent';
import { fetchCustomers } from '../../../actions/dashboard/finances/customer_action';
import { PAYMENTS_TABS, PAYMENTS_SUB_TABS, ADJUSTMENT_NOTES_SUB_TABS, tabOptions } from './paymentUtils';
const TABS = {
    collectionDashboard: 'collectionDashboard',
    paymentsDashboard: 'paymentsDashboard',
};
const initialFiltersState = {
    startDate: getMomentTime().startOf('month'),
    endDate: getMomentTime(),
    branchId: null,
    paymentNature: null,
    adviceNumber: '',
    voucherNumber: '',
    creditAccountId: null,
};
const PaymentsComponent = (props) => {
    const { accesstoken, login, groupId, miniVehicleList, loggedInUser, transporters, customers } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [activeTab, setActiveTab] = useState(PAYMENTS_TABS.PAYMENTS);
    const [activeSubTab, setActiveSubTab] = useState(PAYMENTS_SUB_TABS.UNSETTLED);
    const [filters, dispatchFilters] = useReducer(filtersReducer, initialFiltersState, (state) => {
        const parsed = queryString.parse(location?.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        return { ...state, ...parsedFilters };
    });
    const [openFilters, setOpenFilters] = useState(false);
    const [allBranches, setAllBranches] = useState([]);
    const [showAddAdvanceOptions, setShowAddAdvanceOptions] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isInitialSummaryLoad, setIsInitialSummaryLoad] = useState(true);
    const [isGenerateReportForTemplateModalOpen, setIsGenerateReportForTemplateModalOpen] = useState(false);
    const [metaDataForTemplateGeneration, setMetaDataForTemplateGeneration] = useState({});
    const scope = login.scope;
    const { startDate, endDate, currentPage, sizePerPage, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, } = filters;
    useEffect(() => {
        if (isViewBranchAllowedForUser(props.login.scope)) {
            props.fetchBranches(accesstoken).then((res) => {
                setAllBranches(get(res, 'payload.data'));
            });
        }
        props.fetchCustomers(accesstoken);
        props.fetchAllTransporters(accesstoken);
    }, []);
    const onPageChange = (page, sizePerPage) => {
        dispatchFilters({
            payload: {
                sizePerPage,
                currentPage: page,
            },
        });
    };
    useEffect(() => {
        stringifyUrlThroughState({
            activeTab,
            activeSubTab,
            startDate,
            endDate,
            currentPage,
        });
    }, [activeTab, activeSubTab, startDate.valueOf(), endDate.valueOf(), currentPage]);
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        if (key === 'timeFilter') {
            newFilersState.startDate = get(value, '[0]', null);
            newFilersState.endDate = get(value, '[1]', null);
        }
        else {
            newFilersState[key] = value;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const onRemoveAppliedFilter = (filterName) => {
        if (filterName == 'resetFilter') {
            const newFilersState = {};
            newFilersState.vehicleId = null;
            newFilersState.pendingAmountNull = null;
            newFilersState.transporterId = null;
            dispatchFilters({ payload: newFilersState });
        }
        else {
            handleFilterChange(filterName, null);
        }
    };
    const vehicleListMiniMapped = React.useMemo(() => {
        return mappedVehicleListMiniOptions(miniVehicleList, loggedInUser, true);
    }, [miniVehicleList]);
    const transportersMapped = React.useMemo(() => {
        return mappedTransporters(transporters);
    }, [transporters]);
    const { t } = useTranslation();
    const onTabChange = (key) => {
        if (key === PAYMENTS_TABS.PAYMENTS) {
            setActiveTab(key);
            setActiveSubTab(PAYMENTS_SUB_TABS.UNSETTLED); // setting default sub-tab for Payments
        }
        else if (key === PAYMENTS_TABS.ADJUSTMENT_NOTES) {
            setActiveTab(key);
            setActiveSubTab(ADJUSTMENT_NOTES_SUB_TABS.CREDIT_NOTE); // setting default sub-tab for Adjustment Notes
        }
        else if (Object.values(PAYMENTS_SUB_TABS).includes(key) ||
            Object.values(ADJUSTMENT_NOTES_SUB_TABS).includes(key)) {
            setActiveSubTab(key); // Handle sub-tab selection
        }
        else {
            setActiveTab(key);
            setActiveSubTab(null); // Clear sub-tab if another tab is selected
        }
    };
    return (_jsxs(PageLayoutComponent, { pageIcon: null, pageLabel: t('payments'), tabTitle: t('payments'), tabs: _jsx(_Fragment, { children: _jsxs(FxTabsWrapper, { children: [_jsx(FxTab, { activeKey: activeTab, onChange: onTabChange, items: tabOptions }), (activeTab === PAYMENTS_TABS.PAYMENTS || activeTab === PAYMENTS_TABS.ADJUSTMENT_NOTES) &&
                        activeSubTab && (_jsx(FxTabsWrapper, { children: _jsx(FxTab, { activeKey: activeSubTab, onChange: onTabChange, items: tabOptions.find((tab) => tab.key === activeTab)?.subTabs, style: { marginTop: '1rem' } }) }))] }) }), children: [_jsxs("div", { children: [_jsx(MoreFiltersButtonComponent, { onClick: () => setOpenFilters(!openFilters) }), _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, startDate: startDate, endDate: endDate, onAntdPage: true, withFinancialYear: true }), _jsxs(ButtonDropdown, { isOpen: showAddAdvanceOptions, toggle: () => setShowAddAdvanceOptions(!showAddAdvanceOptions), children: [_jsx(DropdownToggle, { className: "pos-rel", children: _jsx("i", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Add advance", className: "fa fxicon-add" }) }), _jsxs(DropdownMenu, { style: { width: '100%' }, right: true, children: [isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/driver/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "Driver Advance" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/cn-advance/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "CN Advance" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/payment-receipt/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-payment-receipt-add", children: "Payment Receipt" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/payment-receipt-job/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-payment-receipt-add", children: "Job Payment Receipt" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/balance-payment/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "Balance Payment" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/account/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "On Account Form" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/bulk-payment/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "Add Bulk Payment" }) })), isCreateAdvanceAllowedForUser(scope) && (_jsx(DropdownItem, { onClick: () => {
                                            navigate('/dashboard/payments/bulk-adjustment/add');
                                        }, className: "whitespace-normal", children: _jsx("span", { "data-gacategory": "Payments", "data-gaaction": "Click", "data-galabel": "Name-dropdown-advance-add", children: "Add Bulk Adjustment" }) }))] })] })] }), _jsxs("div", { children: [_jsx(PaymentDashboardComponent, { startDate: startDate, endDate: endDate, branchId: branchId, paymentNature: paymentNature, adviceNumber: adviceNumber, creditAccountId: creditAccountId, currentPage: currentPage, sizePerPage: sizePerPage, handleFilterChange: handleFilterChange, onPageChange: onPageChange, customers: customers, voucherNumber: voucherNumber, allBranches: allBranches, transporters: transporters, activeTab: activeTab, activeSubTab: activeSubTab }), _jsx(MoreFiltersDrawerComponent, { onClose: () => setOpenFilters(!openFilters), open: openFilters, children: _jsx(PaymentFiltersComponent, { handleFilterChange: handleFilterChange, branches: allBranches, loggedInUser: loggedInUser, branchId: branchId, paymentNature: paymentNature, creditAccountId: creditAccountId, allCustomers: [
                                ...mappedTransporters(filter(props.transporters, (tp) => !!tp.ledgerId)),
                                ...mappedOnlyConsignorsConsignees(filter(customers, (el) => el.ledgerId)),
                            ] }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBranches,
        fetchVehicleListMini,
        fetchRoutesMiniList,
        fetchAllTransporters,
        fetchCustomers,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
        groupId: state.local.defaultGroupId,
        miniVehicleList: get(state, 'vehicles.vehicleListMini', []),
        routeList: state.routes.routeListMiniNew,
        transporters: state.transporters.transporterList,
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent);
