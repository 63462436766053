import axios from 'axios';
import { isArray, join } from 'lodash';
import {
    FREQUENCY,
    parseQueryParams,
    ROOT_API_ERP,
    ROOT_API_URL,
    ROOT_API_URL_V2,
    ROOT_LOGGING_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
} from 'constant';
import { REPORT_FORMAT } from 'utils/report_utils';
import { isAlarmVisible } from 'components/dashboard/roles/permission_utils';
import { sendAppAlertToSlack } from 'utils/analytics_utils';
import { getMomentTime } from 'utils/date_utils';
import { FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME } from 'actions/dashboard/transporter_analytics_action';

export const FETCH_ALERTS = 'fetch_alerts';
export const UPDATE_VEHICLE_VEHICLE_ALARM_VERIFIED_STATUS = 'update_vehicle_vehicle_alarm_verified_status';
export const FETCH_ALERTS_GRAPH = 'fetch_alerts_graph';
export const FETCH_ALERTS_GRAPH_BY_VEHICLE = 'fetch_alerts_graph_by_vehicle';
export const FILL_THEFT_REPORT = 'FILL_THEFT_REPORT';
export const FETCH_ALERTS_REPORT = 'fetch_alerts_report';
export const FETCH_FUEL_LEVEL_SUMMARY = 'fetch_fuel_level_summary';
export const SET_TEMP_ALERT = 'set_temp_alert';
export const SET_VEHICLE_WISE_THRESHOLD = 'set_vehicle_wise_threshold';
export const DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT = 'DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT';
export const DOWNLOAD_AC_TEMPERATURE_REPORT = 'DOWNLOAD_AC_TEMPERATURE_REPORT';
export const DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT_MULTI_VEHICLE =
    'DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT_MULTI_VEHICLE';

export const FETCH_ALERTS_SUMMARY = 'FETCH_ALERTS_SUMMARY';
export const FETCH_ALERTS_GRAPH_COUNT = 'FETCH_ALERTS_GRAPH_COUNT';
export const FETCH_ALERTS_GRAPH_FREQUENCY = 'FETCH_ALERTS_GRAPH_FREQUENCY';
export const SEND_BANNER_DATA = 'SEND_BANNER_DATA';
export const DELETE_ALARM = 'DELETE_ALARM';

const CancelToken = axios.CancelToken;

export function fetchAlerts(
    accesstoken,
    from,
    to,
    vehicleId,
    alertType,
    groupId,
    alertTypes,
    fetchDetails = false,
    fetchIssues,
    issueStatus,
    alertSubType,
    transporterId,
    onJobAlarm,
    showDeleted = false,
    currentTransporterId
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlerts';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isAlarmVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ALERTS,
            promise: Promise.resolve({}),
        };
    }

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            vehicleId,
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            groupId: groupId !== -1 ? groupId : null,
            type: alertType,
            types: alertTypes,
            fetchDetails,
            fetchIssues,
            issueStatus,
            subType: alertSubType,
            transporterId: transporterId,
            onJobAlarm: onJobAlarm,
            showDeleted,
            currentTransporterId: currentTransporterId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}alarms/`, config);

    return {
        type: FETCH_ALERTS,
        promise: request,
    };
}

export function updateVehicleAlarmVerifiedStatus(data) {
    const { createDate, alarmStatus, accountId, groupId, type, vehicleId } = data;
    const config = {
        params: parseQueryParams({
            createDate,
            alarmStatus,
            accountId,
            groupId,
            type,
            vehicleId,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}alarms/alarm-verified-status-and-update`, {}, config);
    return {
        type: UPDATE_VEHICLE_VEHICLE_ALARM_VERIFIED_STATUS,
        promise: request,
    };
}

export function fetchAlertsGraph(accesstoken, from, to, vehicleId, alertType, groupId, frequency, tagIds) {
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isAlarmVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        sendAppAlertToSlack(
            `fetch alerts graph denied to user with role ${role} and accountId = ${accountId}`,
            '#user-module-logs'
        );
        return {
            type: FETCH_ALERTS_GRAPH,
            promise: Promise.resolve({}),
        };
    }
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlertsGraph';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const alertTypeQuery = alertType ? `&type=${encodeURIComponent(alertType)}` : '';
    const tagIdsQuery = tagIds ? `&tagIds=${tagIds}` : '';
    const request = axios.get(
        `${ROOT_API_URL}alarms/graph?from=${from}&to=${to}${vehicleQuery}${groupQuery}${alertTypeQuery}&frequency=${
            frequency || FREQUENCY.DAYS
        }${tagIdsQuery}`,
        config
    );

    return {
        type: FETCH_ALERTS_GRAPH,
        promise: request,
    };
}

export function fetchAlertsGraphByVehicle(
    accesstoken,
    from,
    to,
    vehicleId,
    alertType,
    groupId,
    frequency = FREQUENCY.DAYS
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlertsGraphByVehicle';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const alertTypeQuery = alertType ? `&type=${encodeURIComponent(alertType)}` : '';
    const request = axios.get(
        `${ROOT_API_URL}alarms/group/vehicle?from=${from}&to=${to}${vehicleQuery}${groupQuery}${alertTypeQuery}`,
        config
    );

    return {
        type: FETCH_ALERTS_GRAPH_BY_VEHICLE,
        promise: request,
    };
}

export function triggerFuelLevelFillTheftReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/fillTheftReport`, options, config);

    return {
        type: FILL_THEFT_REPORT,
        promise: request,
    };
}

export function fetchAlertsReport(accesstoken, from, to, vehicleId, alertType, groupId, reportFormat) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const format = reportFormat ? `&format=${reportFormat}` : '&format=' + REPORT_FORMAT.PDF;
    const alertTypeQuery = alertType ? `&type=${encodeURIComponent(alertType)}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}alarms/report?from=${from}&to=${to}${vehicleQuery}${groupQuery}${alertTypeQuery}${format}`,
        config
    );

    return {
        type: FETCH_ALERTS_REPORT,
        promise: request,
    };
}

export function fetchFuelLevelSummary(from, to) {
    const config = {
        params: {
            from,
            to,
        },
    };

    const request = axios.get(`${ROOT_API_URL}alarms/fuel-level-summary`, config);

    return {
        type: FETCH_FUEL_LEVEL_SUMMARY,
        promise: request,
    };
}

export function setTempAlert(accesstoken, data, alertSettingId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.post(
        `${ROOT_API_URL_V2}alerts/settings/vehicle/alarm?alertSettingId=${alertSettingId}`,
        data,
        config
    );
    return {
        type: SET_TEMP_ALERT,
        promise: request,
    };
}

export function setVehicleWiseThreshold(accesstoken, data, alertSettingId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.put(
        `${ROOT_API_URL_V2}alerts/settings/vehicle/alarm?alertSettingId=${alertSettingId}`,
        data,
        config
    );
    return {
        type: SET_VEHICLE_WISE_THRESHOLD,
        promise: request,
    };
}

export function getDayWiseDetailsFuelReport(accesstoken, from, to, vehicleNumber) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: {
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleNumber,
        },
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/day-wise/report`, config);

    return {
        type: DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT,
        promise: request,
    };
}

export function getDayWiseDetailsFuelReportMultiVehicle(accesstoken, from, to, vehicleIds) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: {
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleIds: isArray(vehicleIds) ? join(vehicleIds, ',') : vehicleIds,
        },
    };
    const request = axios.get(`${ROOT_API_ERP}fuel_entries/day-wise/multi`, config);

    return {
        type: DOWNLOAD_DAY_WISE_DETAILS_FUEL_REPORT_MULTI_VEHICLE,
        promise: request,
    };
}

export function fetchAlertsSummary(
    accesstoken,
    {
        from,
        to,
        vehicleId,
        vehicleIds,
        alertType,
        groupId,
        driverId,
        subType,
        onJobAlarm,
        transporterId,
        issueStatus,
        types,
        currentTransporterId,
        showDiff,
        districtName,
    }
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlertsSummary';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isAlarmVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ALERTS_SUMMARY,
            promise: Promise.resolve({}),
        };
    }

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            vehicleId,
            vehicleIds: isArray(vehicleIds) ? join(vehicleIds, ',') : vehicleIds,
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            groupId: groupId !== -1 ? groupId : null,
            type: alertType,
            types,
            driverId,
            subType,
            onJobAlarm,
            transporterId,
            issueStatus,
            currentTransporterId,
            showDiff,
            districtName,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}alarms/summary`, config);

    return {
        type: FETCH_ALERTS_SUMMARY,
        promise: request,
    };
}

export function sendBannerDetails(accesstoken, data) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };

    const request = axios.post(`${ROOT_LOGGING_NODE_API_URL}misc/bannerData`, data, config);

    return {
        type: 'SEND_BANNER_DATA',
        promise: request,
    };
}

export function downloadACTemperatureReport(payload) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/acTemperatureReport`, payload, config);

    return {
        type: 'DOWNLOAD_AC_TEMPERATURE_REPORT',
        promise: request,
    };
}

export function fetchAlarmsGraphCount(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlarmsGraphCount';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_API_URL}alarms/graph/count-graph`, config);

    return {
        type: FETCH_ALERTS_GRAPH_COUNT,
        promise: request,
    };
}

export function fetchAlarmsGraphFrequency(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_alerts_action_fetchAlarmsGraphFrequency';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_API_URL}alarms/graph/frequency-graph`, config);

    return {
        type: FETCH_ALERTS_GRAPH_FREQUENCY,
        promise: request,
    };
}

export function fetchLatLngBoundariesByDistrict(accesstoken, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/alarms-district-coordinates`, config);

    return {
        type: FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME,
        promise: request,
    };
}

export function deleteAlertAlarm(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}alarms/archive`, {}, config);

    return {
        type: DELETE_ALARM,
        promise: request,
    };
}
