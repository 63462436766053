import axios from 'axios';
import { get, join } from 'lodash';
import {
    parseQueryParams,
    ROOT_API,
    ROOT_API_URL,
    ROOT_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
} from '../../../constant';

export const FETCH_ACTIVE_DEVICE_INSTALLATIONS = 'fetch_active_device_installations';
export const FETCH_TECHNICIAN_DUTIES = 'fetch_technician_duties';
export const CREATE_TECHNICIAN_DUTIES = 'create_technician_duties';
export const UPDATE_TECHNICIAN_DUTIES = 'update_technician_duties';
export const FETCH_VEHICLE_LIST_MASTER_BY_ACCOUNT_ID = 'fetch_vehicle_list_master_by_account_id';
export const FETCH_TECHNICIAN_ACTIVITY_BY_DUTY_ID = 'fetch_technician_activity_by_duty_id';
export const UPDATE_BILL_TO_CLIENT_FLAG = 'update_bill_to_client_flag';
export const FETCH_TECHNICIAN_DUTY_REASONS = 'fetch_technician_duty_reasons';
export const UPDATE_TECHNICIAN_DUTY_STATUS = 'update_technician_duty_status';
export const FETCH_TECHNICIAN_DUTY_TRACKING_REASONS = 'fetch_technician_duty_tracking_reasons';
export const FETCH_ACCOUNT_GROUPS = 'fetch_account_groups';
export const UPDATE_RESCHEDULED_DUTY_STATUS = 'update_rescheduled_duty_status';
export const UPDATE_TECHNICIAN_DUTY_KM = 'update_technician_duty_km';
export const TECHNICIAN_RUNNING_REPORT = 'technician_running_report';
export const DOWNLOAD_TECHNICIAN_RUNNING_REPORT = 'download_technician_running_report';
export const SEND_SHIPMENT = 'send_shipment';
export const SEND_PROBLEMATIC = 'send_problematic';
export const SEND_COMMENT = 'send_comment';
export const FETCH_JOB_AUDI_LISTING = 'FETCH_JOB_AUDI_LISTING';
export const FETCH_JOB_AUDI_SUMMARY = 'FETCH_JOB_AUDI_SUMMARY';
export const UPDATE_JOB_AUDIT_STATUS = 'UPDATE_JOB_AUDIT_STATUS';
export const UPDATE_INSTALLATION_STATUS = 'UPDATE_INSTALLATION_STATUS';

export const FETCH_TECHNICIAN_REVERSE_LOGISTICS = 'FETCH_TECHNICIAN_REVERSE_LOGISTICS';
export const FETCH_TECHNICIAN_ENTERPRISE_DETAILS = 'FETCH_TECHNICIAN_ENTERPRISE_DETAILS';

const CancelToken = axios.CancelToken;

export function fetchActiveDeviceInstallations(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`https://app.fleetx.io/napp/devices/settingsqueue`, config);

    return {
        type: FETCH_ACTIVE_DEVICE_INSTALLATIONS,
        promise: request,
    };
}

export function fetchTechnicianDuties(
    accesstoken,
    {
        activeOnly,
        userId,
        dutyStatus,
        startDate,
        endDate,
        dutyType,
        dutyReason,
        sort,
        activityReason,
        accountId,
        deviceType,
        reschedule,
        tagIds,
        licensePlate,
    },
    page = 1,
    size = 5000
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_operations_action_fetchTechnicianDuties';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            activeOnly,
            userId,
            dutyStatus,
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            dutyType,
            dutyReason,
            activityReason,
            accountId,
            size,
            page: page - 1,
            deviceType,
            reschedule,
            tagIds: tagIds && join(tagIds, ','),
            licensePlate,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}technician/duty`, config);

    return {
        type: FETCH_TECHNICIAN_DUTIES,
        promise: request,
    };
}

export function fetchTechnicianDutyById(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}technician/duty/${id}`, config);

    return {
        type: FETCH_TECHNICIAN_DUTIES,
        promise: request,
    };
}

export function createTechnicianDuty(accesstoken, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}technician/duty`, data, config);

    return {
        type: CREATE_TECHNICIAN_DUTIES,
        promise: request,
    };
}

export function updateTechnicianDuty(accesstoken, dutyId, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}technician/duty/${dutyId}`, data, config);

    return {
        type: UPDATE_TECHNICIAN_DUTIES,
        promise: request,
    };
}

export function fetchVehiclesListMasterByAccountId(accesstoken, accountId, showRemove = false, page = 1, size = 5000) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size,
            page: page - 1,
            accountId,
            showRemove,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/autocomplete/master`.replace('/v1', '/v2'), config);

    return {
        type: FETCH_VEHICLE_LIST_MASTER_BY_ACCOUNT_ID,
        promise: request,
    };
}

export function fetchTechnicianActivityByDutyId(accesstoken, dutyId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            dutyId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}technician/duty/tracking`, config);

    return {
        type: FETCH_TECHNICIAN_ACTIVITY_BY_DUTY_ID,
        promise: request,
    };
}
export function fetchJobAuditListing(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            // accountIds: get(params, 'accountIds', '113')
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}job-internal/listing`, config);

    return {
        type: FETCH_JOB_AUDI_LISTING,
        promise: request,
    };
}
export function fetchJobAuditSummary(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ ...params }),
    };
    const request = axios.get(`${ROOT_API_URL}job-internal/summary`, config);

    return {
        type: FETCH_JOB_AUDI_SUMMARY,
        promise: request,
    };
}
export function sendProblematic(accesstoken, params, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}job-internal/problematic`, data, config);

    return {
        type: SEND_PROBLEMATIC,
        promise: request,
    };
}
export function sendComment(accesstoken, params, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}job-internal/comment`, data, config);

    return {
        type: SEND_COMMENT,
        promise: request,
    };
}

export function updateJobAuditStatus(accesstoken, params, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}job-internal/status`, data, config);

    return {
        type: UPDATE_JOB_AUDIT_STATUS,
        promise: request,
    };
}

export function updateInstallationStatus(accesstoken, params, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}job-internal/installation-status`, data, config);

    return {
        type: UPDATE_INSTALLATION_STATUS,
        promise: request,
    };
}

export function updateBillToClientFlagInTechnicianActivity(accesstoken, dutyId, billToClient = false) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            billToClient,
        },
    };
    const request = axios.put(`${ROOT_API_URL}technician/duty/tracking/${dutyId}/bill-to-client`, null, config);

    return {
        type: UPDATE_BILL_TO_CLIENT_FLAG,
        promise: request,
    };
}

export function fetchTechniciansDutyReasons(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}technician/duty/reasons`, config);

    return {
        type: FETCH_TECHNICIAN_DUTY_REASONS,
        promise: request,
    };
}

export function fetchTechniciansDutyTrackingReasons(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}technician/duty/tracking/reasons`, config);

    return {
        type: FETCH_TECHNICIAN_DUTY_TRACKING_REASONS,
        promise: request,
    };
}

export function updateTechnicianDutyStatus(accesstoken, dutyId, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}technician/duty/client/${dutyId}`, data, config);

    return {
        type: UPDATE_TECHNICIAN_DUTY_STATUS,
        promise: request,
    };
}

export function fetchAccountGroups(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}groups/account/${accountId}`, config);

    return {
        type: FETCH_ACCOUNT_GROUPS,
        promise: request,
    };
}

export function updateRescheduledDutyStatus(accesstoken, id, date, status) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            date,
            status,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}technician/duty/reschedule/${id}`, null, config);

    return {
        type: UPDATE_RESCHEDULED_DUTY_STATUS,
        promise: request,
    };
}

export function updateTechnicianDutyKM(accesstoken, id, distance) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            distance,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}technician/duty/running-report/km`, null, config);

    return {
        type: UPDATE_TECHNICIAN_DUTY_KM,
        promise: request,
    };
}

export function fetchTechnicianRunningReport(accesstoken, { from, to, sort, page = 1, size = 500 }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            page: page - 1,
            size,
            sort,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}technician/duty/running-report`, config);

    return {
        type: TECHNICIAN_RUNNING_REPORT,
        promise: request,
    };
}

export function fetchDownloadTechnicianRunningReport(accesstoken, options) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerTechniciansRunningReport`, data, config);

    return {
        type: DOWNLOAD_TECHNICIAN_RUNNING_REPORT,
        promise: request,
    };
}

export function sendShipment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}shipment/send`, data, config);

    return {
        type: SEND_SHIPMENT,
        promise: request,
    };
}

export function fetchTechnicianReverseLogistics(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}technicians/reverse`, config);

    return {
        type: FETCH_TECHNICIAN_REVERSE_LOGISTICS,
        promise: request,
    };
}

export function fetchEnterpriseDashboardDetails(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}technicians/inventory/dashboard`, config);

    return {
        type: FETCH_TECHNICIAN_ENTERPRISE_DETAILS,
        promise: request,
    };
}

export function fetchJobChecklistGeneration(accesstoken, jobId, email) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            email,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/job-checklist/send`, config);

    return {
        type: FETCH_TECHNICIAN_ENTERPRISE_DETAILS,
        promise: request,
    };
}
