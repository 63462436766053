import axios from 'axios';
import { ROOT_API_ERP, parseQueryParams } from '../../constant';
export const FETCH_FINANCING = 'FETCH_FINANCING';
export const FETCH_FINANCING_BY_ID = 'FETCH_FINANCING_BY_ID';
export const CREATE_FINANCING = 'CREATE_FINANCING';
export const UPDATE_FINANCING = 'UPDATE_FINANCING';
export const DELETE_FINANCING_BY_ID = 'DELETE_FINANCING_BY_ID';
export const CALCULATE_EMI = 'CALCULATE_EMI';
export const FETCH_FINANCING_PAYMENT = 'FETCH_FINANCING_PAYMENT';
export const CREATE_FINANCING_PAYMENT = 'CREATE_FINANCING_PAYMENT';
export const FETCH_FINANCING_SUMMARY = 'FETCH_FINANCING_SUMMARY';
export const FETCH_EMI_LISITNG = 'FETCH_EMI_LISITNG';
export const FETCH_EMI_DETAILS_BY_ID = 'FETCH_EMI_DETAILS_BY_ID';
export function fetchFinancing(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-emi/listing`, config);
    return {
        type: FETCH_FINANCING,
        promise: request,
    };
}
export function fetchFinancingById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-emi/${id}`, config);
    return {
        type: FETCH_FINANCING_BY_ID,
        promise: request,
    };
}
export function createFinancing(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}vehicle-emi`, data, config);
    return {
        type: CREATE_FINANCING,
        promise: request,
    };
}
export function updateFinancing(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}vehicle-emi`, data, config);
    return {
        type: UPDATE_FINANCING,
        promise: request,
    };
}
export function deleteFinancingEntryById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}vehicle-emi/${id}`, config);
    return {
        type: DELETE_FINANCING_BY_ID,
        promise: request,
    };
}
export function calculateEmiAmount(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}vehicle-emi/calculate/emi`, data, config);
    return {
        type: CALCULATE_EMI,
        promise: request,
    };
}
export function fetchFinancingPayment(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}emi-settlement/listing`, config);
    return {
        type: FETCH_FINANCING_PAYMENT,
        promise: request,
    };
}
export function createFinancingPayment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}emi-settlement`, data, config);
    return {
        type: CREATE_FINANCING_PAYMENT,
        promise: request,
    };
}
export function fetchFinancingSummary(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}emi-settlement/summary`, config);
    return {
        type: FETCH_FINANCING_SUMMARY,
        promise: request,
    };
}
export function fetchEmiLisitng(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-emi/emi-details`, config);
    return {
        type: FETCH_EMI_LISITNG,
        promise: request,
    };
}
export function fetchEmiDetailsById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}emi-settlement/installment/listing?emiId=${id}`, config);
    return {
        type: FETCH_EMI_DETAILS_BY_ID,
        promise: request,
    };
}
