/**
 * Created by abhay on 2017-07-20.
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import moment from 'moment-timezone';
import {
    DATE_FORMAT,
    DATE_FORMAT_TIME,
    DATE_FORMAT_TIME_24,
    DEFAULT_START_DATE_DAYS,
    DEFAULT_START_DATE_DAYS_HISTORY_PAGE,
    getCurrentYearRange,
    getDateRangeFromInputString,
    getEndDate,
    getHumanizeTime,
    getInputStringFromDateRange,
    getLastYearRange,
    getMomentTime,
    isDateEqual,
    parseDateFromUserInput,
} from '../../utils/date_utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setLocalDate } from '../../actions/dashboard/local_action';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import {
    accountLevelMinDateSelection,
    enableUserInputBoxForCalendar,
    includeHourlyTimeRangesInCal,
    includeYearlyTimeRangesInCal,
    isUnrestrictedCalendar,
    show24HoursCalenderFormatForAccount,
} from '../../utils/account_utils';
import { getCurrentFinancialYear, getLastFinancialYear } from '../../constant';
import { get, includes, isEmpty, set, split, trim } from 'lodash';
import { FxButton } from 'sharedV2';
import { FxCalendarOutlined, FxCaretDownOutlined } from 'sharedV2/FxIcons';
import { getCustomDateRanges, getIsCustomDateRangesEnabledInCalendar } from 'utils/accountUtils/date_utils';

const handleStaleRangeFor = [
    'Today',
    'Last 8 Hours',
    'Last 12 Hours',
    'Last 24 Hours',
    'Last 7 Days',
    'Last 30 Days',
    'Last 15 Days',
];

class CalendarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: getMomentTime()
                .subtract(
                    props.showPastAndCurrMonthScreen ? DEFAULT_START_DATE_DAYS_HISTORY_PAGE : DEFAULT_START_DATE_DAYS,
                    'days'
                )
                .startOf('day'),
            endDate: props.endDate ? props.endDate : getEndDate(),
            minDate: accountLevelMinDateSelection(),
            alwaysShowCalendars: true,
            opens: 'left',
            drops: 'down',
            userInput: enableUserInputBoxForCalendar()
                ? getInputStringFromDateRange(
                      getMomentTime().subtract(DEFAULT_START_DATE_DAYS, 'days').startOf('day'),
                      getEndDate(),
                      props.singleDatePicker,
                      false
                  )
                : '',
            isFocused: false,
            invalidDateError: '',
            isCustomDateRangesEnabledInCalendar: getIsCustomDateRangesEnabledInCalendar(),
            customDateRanges: getCustomDateRanges(),
        };

        let yearlyRange = {};
        if (this.includeYears()) {
            yearlyRange = {
                'This Year': getCurrentYearRange(),
                'Last Year': getLastYearRange(),
            };
        }

        let additionalYearlyRanges = {};
        const financialYearStartMonth = 3; // April (0-indexed, 3 = April)
        const financialYearEndMonth = 2; // March

        if (props.showLast5Years) {
            additionalYearlyRanges['Last 5 Financial Years (Including Current)'] = [
                getMomentTime().subtract(4, 'years').month(financialYearStartMonth).startOf('month').date(1), // Start of 4 years ago (including current year)
                getMomentTime().add(1, 'years').month(financialYearEndMonth).endOf('month').date(31), // End of the next financial year
            ];
        }

        if (!props.withoutRanges) {
            let financialYearRange = {};
            if (props.withFinancialYear) {
                financialYearRange = {
                    'This Financial Year': getCurrentFinancialYear(),
                    'Last Financial Year': getLastFinancialYear(),
                };
            }
            let hourlyTimeRanges = {};
            if (this.includeHours()) {
                hourlyTimeRanges = {
                    'Last 8 Hours': [getMomentTime().subtract(8, 'hours'), getEndDate()],
                    'Last 12 Hours': [getMomentTime().subtract(12, 'hours'), getEndDate()],
                    'Last 24 Hours': [getMomentTime().subtract(24, 'hours'), getEndDate()],
                };
            }

            let daysRanges = {};
            if (!this.props.only7Days) {
                daysRanges['Last 15 Days'] = [getMomentTime().subtract(14, 'days').startOf('day'), getEndDate()];
            }
            if (!(this.props.only7Days || this.props.only15Days)) {
                daysRanges['Last 30 Days'] = [getMomentTime().subtract(29, 'days').startOf('day'), getEndDate()];
            }

            let monthRanges = {};
            if (!(this.props.only7Days || this.props.only15Days)) {
                const now = getMomentTime();
                const endOfMonth = now.clone().endOf('month');
                const today = now.clone().endOf('day');
                const endDate = this.props.maxDate
                    ? this.props.maxDate
                    : endOfMonth.isBefore(today)
                    ? endOfMonth
                    : today;
                monthRanges['This Month'] = [getMomentTime().startOf('month'), endDate];
                monthRanges['Last Month'] = [
                    getMomentTime().subtract(1, 'month').startOf('month'),
                    getMomentTime().subtract(1, 'month').endOf('month'),
                ];
                if (this.props.showLastSixMonth) {
                    monthRanges['Last 6 Months'] = [
                        getMomentTime().subtract(6, 'month'),
                        getMomentTime().endOf('month'),
                    ];
                }
            }

            let weekRanges = {};
            weekRanges['This Week'] = [getMomentTime().startOf('isoweek'), getMomentTime().endOf('isoweek')];
            weekRanges['Last Week'] = [
                getMomentTime().subtract(1, 'week').startOf('isoweek'),
                getMomentTime().subtract(1, 'week').endOf('isoweek'),
            ];

            let configCustomDateRanges = {};
            let { customDateRanges, isCustomDateRangesEnabledInCalendar } = this.state;
            if (isCustomDateRangesEnabledInCalendar && customDateRanges) {
                configCustomDateRanges = customDateRanges.reduce((acc, range) => {
                    acc[range.rangeName] = [getMomentTime(range.fromTime), getMomentTime(range.toTime)];
                    return acc;
                }, {});
            }

            this.state.ranges = {
                ...hourlyTimeRanges,
                Today: [getMomentTime().startOf('day'), getEndDate()],
                Yesterday: [
                    getMomentTime().subtract(1, 'days').startOf('day'),
                    getMomentTime().subtract(1, 'days').endOf('day'),
                ],
                'Last 7 Days': [getMomentTime().subtract(6, 'days').startOf('day'), getEndDate()],
                ...weekRanges,
                ...daysRanges,
                ...monthRanges,
                ...yearlyRange,
                ...financialYearRange,
                ...additionalYearlyRanges,
                ...configCustomDateRanges,
            };
        }

        if (!props.withoutTime) {
            this.state.timePicker = true;
            this.state.timePickerIncrement = 1;
        }
    }

    componentDidMount() {
        const newState = {};
        if (this.props.startDate) {
            newState.startDate = this.props.startDate;
        }
        if (this.props.endDate) {
            newState.endDate = this.props.endDate;
        }
        if (this.props.maxDate) {
            newState.maxDate = getEndDate(this.props.maxDate);
        }
        if (this.props.minDate && !accountLevelMinDateSelection()) {
            newState.minDate = this.props.minDate;
        }
        if (this.props.drops) {
            newState.drops = this.props.drops;
        }
        if (this.props.opens) {
            newState.opens = this.props.opens;
        }
        if (enableUserInputBoxForCalendar()) {
            const userInput = getInputStringFromDateRange(
                newState.startDate ? newState.startDate : this.state.startDate,
                newState.endDate ? newState.endDate : this.state.endDate,
                this.props.singleDatePicker,
                !!this.state.timePicker
            );
            if (userInput) {
                newState.userInput = userInput;
            }
        }
        this.setState(newState);
    }

    handleEvent(event, picker) {
        return;
        if (
            moment.duration(picker.endDate.diff(picker.startDate)).asMonths() > 6 &&
            !this.props.withFinancialYear &&
            !this.props.showLastSixMonth &&
            !this.props.withoutRanges
        ) {
            // nothing
        } else {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate,
            });
        }
    }

    onApplyEvent(event, picker) {
        let newStartDate = picker.startDate;
        let newEndDate = this.handleStaleEndDate(picker.chosenLabel, picker.endDate);

        const unrestrictedCalendar = isUnrestrictedCalendar();

        let allowedDateRangeInfo = null;
        if (this.props.isDateRangeAllowed) {
            allowedDateRangeInfo = this.props.isDateRangeAllowed(newStartDate, newEndDate);
        }
        if (allowedDateRangeInfo && !allowedDateRangeInfo[0]) {
            alert(allowedDateRangeInfo[1]);
            return false;
        } else if (
            moment.duration(newEndDate.diff(newStartDate)).asMonths() > 6 &&
            !this.props.withFinancialYear &&
            !this.props.showLastSixMonth &&
            !this.props.withoutRanges &&
            !this.includeYears()
        ) {
            alert('Period can not be greater than 6 months.');
            return false;
        } else if (this.props.only7Days && moment.duration(newEndDate.diff(newStartDate)).asDays() > 7) {
            alert('Period can not be greater than 7 days.');
            return false;
        } else if (this.props.only15Days && moment.duration(newEndDate.diff(newStartDate)).asDays() > 15) {
            alert('Period can not be greater than 15 days.');
            return false;
        } else if (this.props.only30Days && moment.duration(newEndDate.diff(newStartDate)).asDays() > 30) {
            alert('Period can not be greater than 30 days.');
            return false;
        } else if (!unrestrictedCalendar && moment.duration(newEndDate.diff(newStartDate)).asDays() > 32) {
            alert('Period can not be greater than 31 days. Please contact support for longer data.');
            return false;
        } else {
            this.props.onDateSelected(
                newStartDate,
                newEndDate,
                picker.chosenLabel == 'Custom Range' ? 'Custom Date Range' : picker.chosenLabel
            );
            this.props.setLocalDate(newStartDate, newEndDate);
            this.setState({
                chosenLabel: picker.chosenLabel,
                userInput: getInputStringFromDateRange(
                    newStartDate,
                    newEndDate,
                    this.props.singleDatePicker,
                    !!this.state.timePicker
                ),
                invalidDateError: '',
                startDate: newStartDate,
                endDate: newEndDate,
            });
        }
    }

    onInputFocus = () => {
        this.setState({ isFocused: true });
    };
    onInputChange = () => {
        const userInputActivated = this.state.userInputActivated;
        if (!userInputActivated) {
            return;
        }
        this.setState({
            invalidDateError: '',
            isFocused: false,
        });

        const parsedData = getDateRangeFromInputString(this.state.userInput, this.props.singleDatePicker);

        if (parsedData.error) {
            this.setState({
                invalidDateError: parsedData.error,
            });
            return;
        }
        const { startDate, endDate } = parsedData;
        const startDateObj = getMomentTime(startDate);
        const endDateObj = getMomentTime(endDate);
        this.setState(
            {
                startDate: startDateObj,
                endDate: endDateObj,
                userInputActivated: false,
            },
            () => {
                this.props.onDateSelected(startDateObj, endDateObj);
            }
        );
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newState = {};
        if (nextProps.startDate) {
            newState.startDate = nextProps.startDate;
            newState.chosenLabel = null;
        }
        if (nextProps.endDate) {
            newState.endDate = nextProps.endDate;
        }

        if (enableUserInputBoxForCalendar()) {
            const userInput = getInputStringFromDateRange(
                newState.startDate ? newState.startDate : this.state.startDate,
                newState.endDate ? newState.endDate : this.state.endDate,
                this.props.singleDatePicker,
                !!this.state.timePicker
            );
            if (userInput) {
                newState.userInput = userInput;
            }
        }
        this.setState(newState);
    }

    handleStaleEndDate = (rangeLabel, endDate) => {
        if (rangeLabel && this.state.ranges[rangeLabel] && includes(handleStaleRangeFor, rangeLabel)) {
            // update ranges if stale.
            const updateRanges = {};
            const newEndDate = getEndDate();
            updateRanges[rangeLabel] = [this.state.ranges[rangeLabel][0], newEndDate];
            this.setState({
                ranges: {
                    ...this.state.ranges,
                    ...updateRanges,
                },
            });
            return newEndDate;
        }
        return endDate;
    };

    render() {
        const is24Hours = show24HoursCalenderFormatForAccount(this.props.loggedInUser);
        const format = this.props.withoutTime ? DATE_FORMAT : is24Hours ? DATE_FORMAT_TIME_24 : DATE_FORMAT_TIME;
        const start = this.state.startDate.format(format);
        const end = this.state.endDate.format(format);
        const label = this.props.singleDatePicker || start === end ? start : start + ' - ' + end;
        const buttonSize = this.props.onAntdPage ? undefined : this.props.buttonSize || '';
        let containerStyles = this.props.containerStyles ? this.props.containerStyles : {};
        containerStyles = set(containerStyles, 'display', 'inline-block');
        if (this.props.width) {
            containerStyles = set(containerStyles, 'width', this.props.width);
        }

        const passedLabel = this.props.passedLabel;

        const inputValue = this.state.isFocused
            ? this.state.userInput
            : passedLabel
            ? passedLabel
            : this.getFormatedLabel(label);
        const inputWidth = `${inputValue}`.indexOf('-') !== -1 ? '21.5rem' : 'auto';

        const ButtonComp = this.props.onAntdPage ? FxButton : Button;

        return (
            <>
                {!this.props.disabled && (
                    <DateRangePicker
                        // locale={{ format: format }}
                        alwaysShowCalendars={this.state.alwaysShowCalendars}
                        opens={this.state.opens}
                        maxDate={this.state.maxDate}
                        minDate={this.state.minDate}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        onEvent={this.handleEvent.bind(this)}
                        drops={this.state.drops}
                        containerStyles={containerStyles}
                        singleDatePicker={this.props.singleDatePicker}
                        timePicker={this.state.timePicker}
                        timePickerIncrement={this.state.timePickerIncrement}
                        timePicker24Hour={is24Hours}
                        onApply={this.onApplyEvent.bind(this)}
                        applyClass="btn-success"
                        autoUpdateInput={true}
                    >
                        <>
                            {enableUserInputBoxForCalendar() && (
                                <InputGroup>
                                    <InputGroupText
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '.6rem',
                                            marginRight: '-1.6rem',
                                            zIndex: 4,
                                        }}
                                    >
                                        <i className="fa fa-calendar" />
                                    </InputGroupText>
                                    <Input
                                        size={this.props.onAntdPage ? 'sm' : undefined}
                                        disabled={this.props.disabled}
                                        style={{ width: inputWidth, paddingLeft: '2rem' }}
                                        onChange={(e) => {
                                            this.setState({
                                                userInput: e.currentTarget.value,
                                                userInputActivated: true,
                                            });
                                        }}
                                        onClick={this.onInputFocus}
                                        onFocus={this.onInputFocus}
                                        onBlur={this.onInputChange}
                                        type="text"
                                        className={`rounded form-control ${
                                            this.state.invalidDateError ? 'text-danger' : ''
                                        }`}
                                        value={inputValue}
                                    />
                                </InputGroup>
                            )}

                            {!enableUserInputBoxForCalendar() && (
                                <ButtonComp
                                    className={this.props.onAntdPage ? '' : 'selected-date-range-btn'}
                                    style={
                                        this.props.onAntdPage
                                            ? {
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: '0.8rem',
                                              }
                                            : {
                                                  width: '100%',
                                                  borderRadius: '4px',
                                              }
                                    }
                                    size={buttonSize}
                                    disabled={this.props.disabled}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {!this.props.onAntdPage ? (
                                        <div className="pull-left">
                                            <i className="fa fa-calendar mr-1" />
                                        </div>
                                    ) : (
                                        <FxCalendarOutlined />
                                    )}

                                    <div className="d-inline">
                                        <span className="whitespace-nowrap">
                                            {passedLabel ? passedLabel : this.getFormatedLabel(label)}
                                        </span>
                                    </div>
                                    {!this.props.onAntdPage ? (
                                        <span className="fa fa-caret-down ml-1" />
                                    ) : (
                                        <FxCaretDownOutlined />
                                    )}
                                    {!this.props.onAntdPage && <div className="clearfix" />}
                                </ButtonComp>
                            )}
                        </>
                    </DateRangePicker>
                )}
                {this.props.disabled && (
                    <div
                        style={{
                            width: '100%',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            padding: '0.5rem',
                            backgroundColor: '#e4e5e6',
                        }}
                    >
                        <div className="pull-left">
                            <i className="fa fa-calendar" />
                        </div>
                        <div className="pull-right">
                            <span className="whitespace-normal">
                                {passedLabel ? passedLabel : this.getFormatedLabel(label)}
                            </span>
                        </div>
                        <div className="clearfix" />
                    </div>
                )}
            </>
        );
    }

    includeHours() {
        return this.props.hideHourlyRange ? false : includeHourlyTimeRangesInCal();
    }

    includeYears() {
        return this.props.hideYears ? false : includeYearlyTimeRangesInCal();
    }

    getFormatedLabel(label) {
        if (this.props.singleDatePicker) {
            return label;
        }
        if (this.props.withoutRanges) {
            return label;
        }

        if (!this.state.chosenLabel) {
            if (this.props.withFinancialYear) {
                if (
                    isDateEqual(getCurrentFinancialYear()[0], this.state.startDate) &&
                    (isDateEqual(getCurrentFinancialYear()[1], this.state.endDate) ||
                        isDateEqual(this.state.endDate, getMomentTime()))
                ) {
                    return 'This Financial Year';
                }
                if (
                    isDateEqual(getLastFinancialYear()[0], this.state.startDate) &&
                    isDateEqual(getLastFinancialYear()[1], this.state.endDate)
                ) {
                    return 'Last Financial Year';
                }
            }

            if (this.includeYears()) {
                if (
                    isDateEqual(getCurrentYearRange()[0], this.state.startDate) &&
                    (isDateEqual(getCurrentYearRange()[1], this.state.endDate) ||
                        isDateEqual(this.state.endDate, getMomentTime()))
                ) {
                    return 'This Year';
                }
                if (
                    isDateEqual(getLastYearRange()[0], this.state.startDate) &&
                    isDateEqual(getLastYearRange()[1], this.state.endDate)
                ) {
                    return 'Last Year';
                }
            }

            if (this.includeHours()) {
                if (
                    isDateEqual(this.state.ranges?.['Last 8 Hours']?.[0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'Last 8 Hours';
                }
                if (
                    isDateEqual(this.state.ranges?.['Last 12 Hours']?.[0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'Last 12 Hours';
                }
                if (
                    isDateEqual(this.state.ranges?.['Last 24 Hours']?.[0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'Last 24 Hours';
                }
            }

            if (
                isDateEqual(this.state.ranges.Today[0], this.state.startDate) &&
                isDateEqual(this.state.endDate, getEndDate())
            ) {
                return 'Today';
            }
            if (
                isDateEqual(this.state.ranges.Yesterday[0], this.state.startDate) &&
                isDateEqual(this.state.ranges.Yesterday[1], this.state.endDate)
            ) {
                return 'Yesterday';
            }
            if (
                isDateEqual(this.state.ranges['This Week'][0], this.state.startDate) &&
                isDateEqual(this.state.endDate, getEndDate())
            ) {
                return 'This Week';
            }
            if (
                isDateEqual(this.state.ranges['Last Week'][0], this.state.startDate) &&
                isDateEqual(this.state.ranges['Last Week'][1], this.state.endDate)
            ) {
                return 'Last Week';
            }
            if (
                isDateEqual(this.state.ranges['Last 7 Days'][0], this.state.startDate) &&
                isDateEqual(this.state.endDate, getEndDate())
            ) {
                return 'Last 7 Days';
            }

            if (!(this.props.only7Days || this.props.only15Days)) {
                if (
                    isDateEqual(this.state.ranges['Last 15 Days'][0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'Last 15 Days';
                }
                if (
                    isDateEqual(this.state.ranges['Last 30 Days'][0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'Last 30 Days';
                }
                if (
                    isDateEqual(this.state.ranges['This Month'][0], this.state.startDate) &&
                    isDateEqual(this.state.endDate, getEndDate())
                ) {
                    return 'This Month';
                }
                if (
                    isDateEqual(this.state.ranges['Last Month'][0], this.state.startDate) &&
                    isDateEqual(this.state.ranges['Last Month'][1], this.state.endDate)
                ) {
                    return 'Last Month';
                }
                if (
                    this.props.showLastSixMonth &&
                    isDateEqual(this.state.ranges['Last 6 Months'][0], this.state.startDate) &&
                    isDateEqual(this.state.ranges['Last 6 Months'][1], this.state.endDate)
                ) {
                    return 'Last 6 Months';
                }
            }
            return this.props.showCustomLabel ? 'Custom Range' : label;
        }
        if (this.state.chosenLabel === 'Custom Range') {
            return this.props.showCustomLabel ? 'Custom Range' : label;
        }
        return this.state.chosenLabel;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLocalDate }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        loggedInUser: get(state, 'settings.loggedInUser', {}),
        accountConfig: state.config.accountConfig,
    };
}

CalendarComponent.propTypes = {
    showLastSixMonth: PropTypes.bool,
    singleDatePicker: PropTypes.bool,
    withoutRanges: PropTypes.bool,
    withoutTime: PropTypes.bool,
    onDateSelected: PropTypes.func,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    passedLabel: PropTypes.string,
    showCustomLabel: PropTypes.bool,
    containerStyles: PropTypes.object,
    disabled: PropTypes.bool,
    only15Days: PropTypes.bool,
    only30Days: PropTypes.bool,
    only7Days: PropTypes.bool,
    showPastAndCurrMonthScreen: PropTypes.bool,
    hideHourlyRange: PropTypes.bool,
    hideYears: PropTypes.bool,
    width: PropTypes.string,
    onAntdPage: PropTypes.bool,
    isDateRangeAllowed: PropTypes.func,
    withFinancialYear: PropTypes.bool,
    showLast5Years: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
