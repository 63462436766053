import axios from 'axios';
import {
    FREQUENCY,
    isSkipped,
    getSkipLevel,
    parseQueryParams,
    ROOT_API_URL,
    ROOT_API_URL_V2,
    ROOT_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
    ROOT_LOGGING_NODE_API_URL,
} from '../../constant';
import { REPORT_FORMAT } from '../../utils/report_utils';
import { queryString } from '../../utils/string_utils';
import { preferredTimeStampFormat, useFieldsParamInMergedTripAPi } from '../../utils/account_utils';
import { getMomentTime } from '../../utils/date_utils';
import { isTripsVisible } from '../../components/dashboard/roles/permission_utils';

export const FETCH_TRIPS = 'fetch_trips';
export const FETCH_TRIPS_WITHOUT_AGGREGATIONS = 'fetch_trips_within_fixed_times';
export const FETCH_TRIPS_GRAPH = 'fetch_trips_graph';
export const FETCH_TRIPS_REPORT = 'fetch_trips_report';
export const FETCH_TRIPS_MERGED = 'fetch_trips_merged';
export const FETCH_TRIPS_MERGED_V2 = 'fetch_trips_merged_v2';
export const FETCH_LATEST_TRIP_BY_VEHICLE = 'fetch_latest_trip_by_vehicle';
export const FETCH_LATEST_TRIP_BY_TOKEN = 'fetch_latest_trip_by_token';
export const FETCH_TRIP_SHARE_URL = 'fetch_trip_share_url';
export const FETCH_ROUTE_POINTS_SPEED = 'fetch_route_points_speed';
export const FETCH_ROUTE_POINTS = 'fetch_route_points';
export const FETCH_MOVEMENT_POINTS = 'fetch_movement_points';
export const FETCH_ASSET_TRACKER_POINTS = 'fetch_asset_tracker_points';
export const DELETE_TRIP_MASTER = 'delete_trip_master';
export const FETCH_A_TO_B_TRIPS = 'fetch_a_to_b_trips';
export const FETCH_TRIP_TOLLS = 'fetch_trip_tolls';
export const GENERATE_TIMELINE_URL = 'generate_timeline_url';
export const GET_SHARED_TRIP_TIMELINE_DATA = 'get_shared_trip_timeline_data';
export const GET_A_TO_B_TRIPS_BULK_REPORT = 'get_a_to_b_trips_bulk_report';
export const GET_INTRA_PLANT_REPORT = 'get_intra_plant_report';
export const GET_STOPPAGES_REPORT = 'get_stoppages_report';

const CancelToken = axios.CancelToken;

export function fetchTrips(
    accesstoken,
    from,
    to,
    vehicleId,
    groupId,
    skipList = false,
    dontStoreInRedux = false,
    ignoreExtraRunning = false,
    moreOptions = {}
) {
    const { fetchInstantFuel } = moreOptions || {};
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchTrips';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isTripsVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_TRIPS,
            promise: Promise.resolve({}),
        };
    }
    if (accesstoken) {
        const config = {
            headers: { Authorization: `Bearer ${accesstoken}` },
            cancelToken: new CancelToken(function executor(c) {
                window[cancelCallbackKey] = c;
            }),
            params: parseQueryParams({
                vehicleId,
                groupId,
                from: getMomentTime(from).valueOf(),
                to: getMomentTime(to).valueOf(),
                skipList: !!skipList,
                ignoreExtraRunning,
                fetchInstantFuel,
            }),
        };
        const request = axios.get(`${ROOT_API_URL}trips/`, config);
        return {
            type: dontStoreInRedux ? '' : FETCH_TRIPS,
            promise: request,
        };
    }
}

export function fetchTripsWithoutAggregations(accesstoken, from, to, vehicleId, groupId, pageNo, pageSize) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchTripsWithinFixedTimes';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const limit = pageSize;
    const skip = pageSize * (pageNo - 1);
    if (accesstoken) {
        const config = {
            headers: { Authorization: `Bearer ${accesstoken}` },
            cancelToken: new CancelToken(function executor(c) {
                window[cancelCallbackKey] = c;
            }),
        };
        const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';
        const skipQuery = limit && skip ? `&skip=${skip}` : '';

        const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
        const request = axios.get(
            `${ROOT_API_URL}trips/page/?from=${from}&to=${to}${vehicleQuery}${groupQuery}${limitQuery}${skipQuery}`,
            config
        );
        return {
            type: FETCH_TRIPS_WITHOUT_AGGREGATIONS,
            promise: request,
        };
    }
}

export function fetchTripsGraph(accesstoken, from, to, vehicleId, groupId, frequency = FREQUENCY.DAYS) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchTripsGraph';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    if (accesstoken) {
        const config = {
            headers: { Authorization: `Bearer ${accesstoken}` },
            cancelToken: new CancelToken(function executor(c) {
                window[cancelCallbackKey] = c;
            }),
        };
        const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
        const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
        const request = axios.get(
            `${ROOT_API_URL}trips/graph?type=DAYS&from=${from}&to=${to}${vehicleQuery}${groupQuery}&frequency=${frequency}`,
            config
        );
        return {
            type: FETCH_TRIPS_GRAPH,
            promise: request,
        };
    }
}

export function fetchTripsReport(accesstoken, from, to, vehicleId, groupId, reportFormat) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const format = reportFormat ? `&format=${reportFormat}` : '&format=' + REPORT_FORMAT.PDF;
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}trips/report?from=${from}&to=${to}${vehicleQuery}${groupQuery}${format}`,
        config
    );

    return {
        type: FETCH_TRIPS_REPORT,
        promise: request,
    };
}

export function fetchLatestTripByVehicleId(vehicleId, accesstoken, timeAfter) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            vehicleId,
            timeAfter: timeAfter ? getMomentTime(timeAfter).valueOf() + 1 : '', // if set will give route points only after that time
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/latest/`, config);

    return {
        type: FETCH_LATEST_TRIP_BY_VEHICLE,
        promise: request,
    };
}

export function fetchTripByVehicleId(vehicleId, tripId, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}trips/${tripId}/?vehicleId=${vehicleId}`, config);
    return {
        type: FETCH_LATEST_TRIP_BY_VEHICLE,
        promise: request,
    };
}

export function fetchTripByToken(tripToken) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}trips/share/${tripToken}`, config);
    return {
        type: FETCH_LATEST_TRIP_BY_TOKEN,
        promise: request,
    };
}

export function fetchTripShareUrl(accesstoken, tripId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}trips/generate/url?tripId=${tripId}`, config);
    return {
        type: FETCH_TRIP_SHARE_URL,
        promise: request,
    };
}

export function fetchMergedTrips(
    accesstoken,
    from,
    to,
    vehicleId,
    split = false,
    skipOBD = false,
    trackingMode,
    assetFallback = false,
    deviceId,
    skipCancel = true,
    moreParams = {}
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchMergedTrips';
    if (window[cancelCallbackKey] && !skipCancel) {
        window[cancelCallbackKey]();
    }

    const { fields, accountId, fetchUnloadingStoppage } = moreParams || {};

    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        'Content-Type': 'application/json',
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            fields: useFieldsParamInMergedTripAPi() ? fields : '',
            accountId,
            fetchUnloadingStoppage,
        }),
    };

    const skip = isSkipped(from, to);

    const skipQuery = skip ? `&skip=true` : '';
    const skipOBDQuery = skipOBD ? `&skipOBD=true` : '';
    const splitQuery = split ? `&split=true` : '';
    const trackingModeQuery = trackingMode ? `&trackingMode=${trackingMode}` : '';
    const assetFallbackQuery = assetFallback ? `&assetFallback=${assetFallback}` : '';
    const deviceIdQuery = deviceId ? `&deviceId=${deviceId}` : '';

    const request = axios.get(
        `${ROOT_API_URL}trips/merged?from=${from}&to=${to}&vehicleId=${vehicleId}${skipQuery}${splitQuery}${skipOBDQuery}${trackingModeQuery}${assetFallbackQuery}${deviceIdQuery}`,
        config
    );
    return {
        type: FETCH_TRIPS_MERGED,
        promise: request,
    };
}

export function fetchMergedTripsV2(
    accesstoken,
    from,
    to,
    vehicleId,
    split = false,
    skipOBD = false,
    trackingMode,
    skipCancel = true,
    skipRoute = null,
    moreParams = {}
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchMergedTrips';
    if (window[cancelCallbackKey] && !skipCancel) {
        window[cancelCallbackKey]();
    }

    const { fields } = moreParams || {};

    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        'Content-Type': 'application/json',
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            skipRoute,
            fields: useFieldsParamInMergedTripAPi() ? fields : '',
        }),
    };

    const skip = isSkipped(from, to);
    const skipLevel = getSkipLevel(from, to);

    const skipQuery = skip ? `&skip=true` : '';
    const skipLevelQuery = skip ? `&skipLevel=${skipLevel}` : '';
    const skipOBDQuery = skipOBD ? `&skipOBD=true` : '';
    const splitQuery = split ? `&split=true` : '';
    const trackingModeQuery = trackingMode ? `&trackingMode=${trackingMode}` : '';

    const request = axios.get(
        `${ROOT_API_URL_V2}trips/merged?from=${from}&to=${to}&vehicleId=${vehicleId}${skipQuery}${splitQuery}${skipOBDQuery}${trackingModeQuery}${skipLevelQuery}`,
        config
    );
    return {
        type: FETCH_TRIPS_MERGED_V2,
        promise: request,
    };
}

export function fetchMovementPoints(accesstoken, from, to, vehicleId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };

    const request = axios.get(`${ROOT_API_URL}trips/movement?from=${from}&to=${to}&vehicleId=${vehicleId}`, config);
    return {
        type: FETCH_MOVEMENT_POINTS,
        promise: request,
    };
}

export function fetchMovementPointsAsPromise(accesstoken, from, to, vehicleId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };

    return axios.get(`${ROOT_API_URL}trips/movement?from=${from}&to=${to}&vehicleId=${vehicleId}`, config);
}

export function fetchRoutePointsWithSpeed(accesstoken, from, to, vehicleId, speed = 80) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(
        `${ROOT_API_URL}trips/route/points?from=${from}&to=${to}&vehicleId=${vehicleId}&speed=${speed}`,
        config
    );
    return {
        type: FETCH_ROUTE_POINTS_SPEED,
        promise: request,
    };
}

export function fetchRoutePointsWithType(accesstoken, from, to, vehicleId, type, isFuelData, skipInterval) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            type,
            isFuelData,
            skipInterval,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/route/points/byType`, config);
    return {
        type: FETCH_ROUTE_POINTS,
        promise: request,
    };
}

export function sendFuelAlarmFeedback(accesstoken, data) {
    console.log('data', data);
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };

    const request = axios.post(`${ROOT_LOGGING_NODE_API_URL}misc/fuelAlarmFeedback`, data, config);

    return {
        type: 'SEND_FUEL_ALARM_FEEDBACK',
        promise: request,
    };
}

export function fetchRoutePointsWithTypeFuel(accesstoken, from, to, vehicleId, type, isFuelData) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_fuel_level_fetchRoutePointsWithTypeFuel';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },

        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            type,
            isFuelData,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/route/points/byType`, config);
    return {
        type: FETCH_ROUTE_POINTS,
        promise: request,
    };
}

export function fetchAssetMovementDetails(accesstoken, from, to, vehicleId, vehicleNumber) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            vehicleNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/route/points/asset`, config);
    return {
        type: FETCH_ASSET_TRACKER_POINTS,
        promise: request,
    };
}

export function deleteTrip(tripId) {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const data = {
        password: 'Fleetx12345Fleetx',
    };
    const request = axios.post(`${ROOT_API_URL}trips/delete/${tripId}`, queryString.stringify(data), config);
    return {
        type: DELETE_TRIP_MASTER,
        promise: request,
    };
}

export function fetchAToBTrips(accesstoken, addressFrom, addressTo, from, to, vehicleId) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_fetchAToBTrips';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            addressFrom,
            addressTo,
            from: from ? from.valueOf() : '',
            to: to ? to.valueOf() : '',
            vehicleId,
        }),
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/atob`, config);
    return {
        type: FETCH_A_TO_B_TRIPS,
        promise: request,
    };
}

export function fetchTripTolls(accesstoken, from, to, vehicleId, accountId = null) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/tolls`, config);

    return {
        type: FETCH_TRIP_TOLLS,
        promise: request,
    };
}

export function generateTimelineUrl(accesstoken, from, to, vehicleId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/generate/timeline/url`, config);

    return {
        type: GENERATE_TIMELINE_URL,
        promise: request,
    };
}

export function getSharedTripTimelineData(timelineShareToken) {
    const request = axios.get(`${ROOT_API_URL}trips/share/timeline/${timelineShareToken}`);

    return {
        type: GET_SHARED_TRIP_TIMELINE_DATA,
        promise: request,
    };
}

export function getAToBTripsBulkReport(accesstoken, formData, fetchStoppages = false, isFuelReport = false) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_trips_action_getAToBTripsBulkReport';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'multipart/form-data',
        },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            fetchStoppages,
        }),
        'responseType': 'arraybuffer',
    };
    const request = axios.post(`${ROOT_API_URL}trips/aggregations/${isFuelReport ? 'fuel' : 'bulk'}`, formData, config);

    return {
        type: GET_A_TO_B_TRIPS_BULK_REPORT,
        promise: request,
    };
}

export function fetchNodeIntraPlantReport(accesstoken, reportFormat, source, startDate, endDate, plantTagId) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            source,
            startDate,
            endDate,
            plantTagId,
        }),
    };
    //'responseType': 'arraybuffer',
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getIntraPlantReport`, config);

    return {
        type: GET_INTRA_PLANT_REPORT,
        promise: request,
    };
}

export function fetchNodeStoppageReport(
    accesstoken,
    reportRequestType,
    vehicleId,
    groupId,
    filterConfig,
    startDate,
    endDate,
    reportFormat
) {
    let format = {};
    if (reportRequestType === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const preferredDateFormat = preferredTimeStampFormat();

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            reportRequestType,
            reportFormat,
            vehicleId,
            groupId,
            startDate,
            endDate,
            includeLocation: filterConfig.includeStoppageLocation,
            includeDistance: filterConfig.includeStoppageDistance,
            includeComment: filterConfig.includeTimelineComment,
            includeLastLocation: filterConfig.includeStoppageLastLocation,
            includeStoppageTime: filterConfig.stoppageTimeMinutes.toString(),
            includeMapLink: filterConfig.includeMinuteMapLink,
            includeLatLon: filterConfig.includeMinuteLatLon,
            showOnlyAddressbookStops: filterConfig.showOnlyAddressbookStops,
            preferredDateFormat: preferredDateFormat,
        }),
    };
    //'responseType': 'arraybuffer',
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getStoppageReport`, config);

    return {
        type: GET_STOPPAGES_REPORT,
        promise: request,
    };
}
