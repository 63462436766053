import axios from 'axios';
import { get } from 'lodash';
import { ROOT_API_ERP, parseQueryParams } from '../../../constant';
export const FETCH_BATTERY_BRANDS = 'FETCH_BATTERY_BRANDS';
export const BATTERY_FILTER = 'BATTERY_FILTER';
export const BATTERY_FILTER_OUT_OF_STOCK = 'BATTERY_FILTER_OUT_OF_STOCK';
export const FETCH_BATTERY_MODEL = 'FETCH_BATTERY_MODEL';
export const FETCH_BATTERY_LIST = 'FETCH_BATTERY_LIST';
export const ARCHIVE_BATTERY = 'ARCHIVE_BATTERY';
export const UNARCHIVE_BATTERY = 'UNARCHIVE_BATTERY';
export const INWARD_EVENTS = 'INWARD_EVENTS';
export const OUTWARD_EVENTS = 'OUTWARD_EVENTS';
export const ADD_BATTERY_INWARD = 'ADD_BATTERY_INWARD';
export const ADD_BATTERY_OUTWARD = 'ADD_BATTERY_OUTWARD';
export const CREATE_BATTERY = 'CREATE_BATTERY';
export const FETCH_BATTERY_BY_ID = 'FETCH_BATTERY_BY_ID';
export const FETCH_EVENT_HISTORY = 'FETCH_EVENT_HISTORY';
export const FETCH_BATTERY_HISTORY = 'FETCH_BATTERY_HISTORY';
export const ISSUE_REMOVE_BATTERY = 'ISSUE_REMOVE_BATTERY';
export const FETCH_ISSUE_REMOVE_BATTERY = 'FETCH_ISSUE_REMOVE_BATTERY';
export const ATTACH_BATTERY = 'ATTACH_BATTERY';
export const DETACH_BATTERY = 'DETACH_BATTERY';
export const UPDATE_BATTERY = 'UPDATE_BATTERY';
export const FETCH_BATTERY_SUMMARY = 'FETCH_BATTERY_SUMMARY';
export const BATTERY_STOCK_REPORT = 'BATTERY_STOCK_REPORT';
export const BATTERY_STOCK_REPORT_NEW = 'BATTERY_STOCK_REPORT_NEW';
// DO NOT USE EVENT RELATED ACTIONS FROM THIS FILE. USE THEM FROM event_actions.tsx FILE.
export function fetchBatteryBrands(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}battery/brands`, config);
    return {
        type: FETCH_BATTERY_BRANDS,
        promise: request,
    };
}
export function batteryFilter(accesstoken, stateList, storeId, vendorId, batteryAttached, enabled, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            stateList,
            storeId,
            vendorId,
            batteryAttached,
            enabled,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}battery/filter`, config);
    return {
        type: BATTERY_FILTER,
        promise: request,
    };
}
export function batteryFilterOutOfStock(accesstoken, inwardStoreId, outwardStoreId, outwardStationaryNumber) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            inwardStoreId,
            outwardStoreId,
            outwardStationaryNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}battery/filter/stock-out`, config);
    return {
        type: BATTERY_FILTER_OUT_OF_STOCK,
        promise: request,
    };
}
export function fetchBatteryModel(accesstoken) {
    const config = { 'headers': { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}battery/models/`, config);
    return {
        type: FETCH_BATTERY_MODEL,
        promise: request,
    };
}
export function fetchBatteryList(accesstoken, params) {
    const storeId = get(params, 'storeId');
    const brandId = get(params, 'brandId');
    const attachedStatus = get(params, 'attachedStatus');
    const dateFilter = get(params, 'dateFilter');
    const fromDate = get(params, 'fromDate');
    const toDate = get(params, 'toDate');
    const enabled = get(params, 'enabled');
    const config = {
        'headers': { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            storeId,
            brandId,
            attachedStatus,
            dateFilter,
            fromDate,
            toDate,
            enabled,
            size: 5000,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}battery/search`, config);
    return {
        type: FETCH_BATTERY_LIST,
        promise: request,
    };
}
export function fetchBatterySummary(accesstoken, params) {
    const storeId = get(params, 'storeId');
    const brandId = get(params, 'brandId');
    const attachedStatus = get(params, 'attachedStatus');
    const dateFilter = get(params, 'dateFilter');
    const fromDate = get(params, 'fromDate');
    const toDate = get(params, 'toDate');
    const enabled = get(params, 'enabled');
    const config = {
        'headers': { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            storeId,
            brandId,
            attachedStatus,
            dateFilter,
            fromDate,
            toDate,
            enabled,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}battery/summary`, config);
    return {
        type: FETCH_BATTERY_SUMMARY,
        promise: request,
    };
}
export function archiveBattery(accesstoken, data, type) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (data.id) {
        request = axios.patch(`${ROOT_API_ERP}battery/${type}/${data.id}`, data, config);
    }
    return {
        type: ARCHIVE_BATTERY,
        promise: request,
    };
}
export function fetchInwardEvents(accesstoken, params) {
    const entityType = get(params, 'entityType', 'BATTERY');
    const branchId = get(params, 'branchId');
    const natures = get(params, 'natures');
    const storeId = get(params, 'storeId');
    const vendorId = get(params, 'vendorId');
    const targetCurrency = get(params, 'targetCurrency');
    const conversionDate = get(params, 'conversionDate');
    const fromMrnDate = get(params, 'fromMrnDate');
    const toMrnDate = get(params, 'toMrnDate');
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            entityType,
            branchId,
            natures,
            storeId,
            vendorId,
            targetCurrency,
            conversionDate,
            fromMrnDate,
            toMrnDate,
            size: 5000,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}event/inward/`, config);
    return {
        type: INWARD_EVENTS,
        promise: request,
    };
}
export function fetchOutwardEvents(accesstoken, params) {
    const entityType = get(params, 'entityType', 'BATTERY');
    const branchId = get(params, 'branchId');
    const nature = get(params, 'nature');
    const storeId = get(params, 'storeId');
    const fromMrnDate = get(params, 'fromMrnDate');
    const toMrnDate = get(params, 'toMrnDate');
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            entityType,
            branchId,
            nature,
            storeId,
            size: 5000,
            fromMrnDate,
            toMrnDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}event/outward/`, config);
    return {
        type: OUTWARD_EVENTS,
        promise: request,
    };
}
export function addBatteryInward(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}event/inward`, values, config);
    return {
        type: ADD_BATTERY_INWARD,
        promise: request,
    };
}
export function addBatteryOutward(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}event/outward`, values, config);
    return {
        type: ADD_BATTERY_OUTWARD,
        promise: request,
    };
}
export function createBattery(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}battery`, values, config);
    return {
        type: CREATE_BATTERY,
        promise: request,
    };
}
export function fetchBatteryById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}battery/${id}`, config);
    return {
        type: FETCH_BATTERY_BY_ID,
        promise: request,
    };
}
export function fetchEventHistory(accesstoken, params) {
    const entityType = get(params, 'entityType', 'BATTERY');
    const entityId = get(params, 'entityId');
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            entityType,
            entityId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}event/history`, config);
    return {
        type: FETCH_EVENT_HISTORY,
        promise: request,
    };
}
export function fetchBatteryHistory(accesstoken, batteryId, vehicleId) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            id: batteryId,
            vehicleId,
        }),
    };
    let request = axios.get(`${ROOT_API_ERP}battery/audit/summary`, config);
    return {
        type: FETCH_BATTERY_HISTORY,
        promise: request,
    };
}
export function issueRemoveBattery(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}event/issuance-removal`, values, config);
    return {
        type: ISSUE_REMOVE_BATTERY,
        promise: request,
    };
}
export function fetchIssuanceRemovalBattery(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params,
    };
    const request = axios.get(`${ROOT_API_ERP}event/issuance-removal`, config);
    return {
        type: FETCH_ISSUE_REMOVE_BATTERY,
        promise: request,
    };
}
export function attachBattery(accesstoken, data) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}battery/vehicle-attach`, data, config);
    return {
        type: ATTACH_BATTERY,
        promise: request,
    };
}
export function detachBattery(accesstoken, data) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}battery/vehicle-detach`, data, config);
    return {
        type: DETACH_BATTERY,
        promise: request,
    };
}
export function updateBattery(accesstoken, data) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}battery`, data, config);
    return {
        type: UPDATE_BATTERY,
        promise: request,
    };
}
export function batteryStockReport(accesstoken, params) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params,
    };
    const request = axios.get(`${ROOT_API_ERP}report/battery/old`, config);
    return {
        type: BATTERY_STOCK_REPORT,
        promise: request,
    };
}
export function batteryStockReportNew(accesstoken, params) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params,
    };
    const request = axios.get(`${ROOT_API_ERP}report/battery/new`, config);
    return {
        type: BATTERY_STOCK_REPORT_NEW,
        promise: request,
    };
}
