import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { FxCol, FxRow, FxSelect, FxTypography, FxButton, FxTable, FxSelectSingleDateV2, FxToolTip, FxSelectField, FxTextFieldStackedLabelV2, } from '../../../../sharedV2';
import CalendarComponent from '../../../utils/calendar_component';
import { mappedCustomers } from '../../../../utils/form_utils';
import { mappedBillNumbers, paymentTypeOptions } from '../paymentUtils';
import { Field } from 'formik';
import { FxDeleteOutlined } from '../../../../sharedV2/FxIcons';
const BulkPaymentSectionComponent = ({ index, section, customers, onChange, onBillNumberChange, removeSection, formikProps, }) => {
    const { allBillNumbers, entries } = section;
    const { setFieldValue } = formikProps;
    const columns = [
        {
            title: 'Bill Number',
            dataIndex: 'billNumber',
            key: 'billNumber',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.billNumber`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Bill Number", disabled: true })),
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.paymentType`, component: FxSelectField, options: paymentTypeOptions, placeholder: "Payment Type" })),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, component: FxTextFieldStackedLabelV2, type: "text", placeholder: "Amount" })),
        },
        {
            title: 'Raise Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.date`, type: "text", component: FxSelectSingleDateV2 })),
        },
        {
            title: 'Advice No.',
            dataIndex: 'adviceNo',
            key: 'adviceNo',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.adviceNo`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Advice No." })),
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.remarks`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Remarks" })),
        },
    ];
    const entriesWithBillNumber = entries
        .map((entry, entryIndex) => ({
        ...entry,
        key: `${index}-${entryIndex}`,
        sectionIndex: index,
        entryIndex,
    }))
        .filter((entry) => entry.billNumber);
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { style: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    borderBottom: '1px solid #f0f0f0',
                }, children: _jsx(FxTypography.Title, { level: 5, children: `Section ${index + 1}` }) }), _jsxs(FxRow, { style: { marginTop: '1.5rem' }, children: [_jsx(FxCol, { xs: 24, md: 6, children: _jsx(FxSelect, { placeholder: "Customer Name", options: mappedCustomers(customers), onChange: (value) => {
                                onChange(index, 'customerId', value, setFieldValue);
                            } }) }), _jsx(FxCol, { xs: 24, md: 6, children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                onChange(index, 'startDate', startDate, setFieldValue);
                                onChange(index, 'endDate', endDate, setFieldValue);
                            }, initialStartDate: section.startDate, initialEndDate: section.endDate, width: 300 }) }), _jsx(FxCol, { xs: 24, md: 8, children: _jsx(FxSelect, { options: mappedBillNumbers(allBillNumbers), placeholder: "Bill Number", onChange: (selected) => {
                                const selectedBills = selected.map((selectedId) => {
                                    const bill = allBillNumbers.find((bill) => bill.id === selectedId);
                                    if (bill) {
                                        return {
                                            billId: bill.id,
                                            billNumber: get(bill, 'basicDetails.billNumber'),
                                        };
                                    }
                                    return null;
                                });
                                onBillNumberChange(selectedBills, index);
                            }, mode: "multiple" }) }), entriesWithBillNumber.length > 0 && (_jsx(FxTable, { columns: columns, dataSource: entriesWithBillNumber, pagination: false, style: { marginTop: '1rem' } })), _jsx(FxCol, { xs: 24, md: 4, children: index > 0 && (_jsx(FxToolTip, { title: "Remove Section", children: _jsx(FxButton, { type: "primary", onClick: removeSection, icon: _jsx(FxDeleteOutlined, {}), children: "Remove Section" }) })) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkPaymentSectionComponent);
