import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_NODE_API_URL } from '../../constant';
export const FETCH_DRIVER_SCORE_CONFIG = 'fetch_driver_score_config';
export const CREATE_DRIVER_SCORE_CONFIG = 'create_driver_score_config';
export const UPDATE_DRIVER_SCORE_CONFIG = 'update_driver_score_config';
export const DELETE_DRIVER_SCORE_CONFIG = 'delete_driver_score_config';
export const FETCH_DRIVER_OVERVIEW = 'fetch_driver_overview';
export const FETCH_DRIVER_TREND_SCORE = 'FETCH_DRIVER_TREND_SCORE';
export const FETCH_DRIVER_SCORE_GRAPH = 'FETCH_DRIVER_SCORE_GRAPH';
export function createDriverScoreConfig(accesstoken, data, type = 'SPEED') {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}alarms-score`, data, config);
    return {
        type: CREATE_DRIVER_SCORE_CONFIG,
        promise: request,
    };
}
export function updateDriverScoreConfig(accesstoken, data, type = 'SPEED') {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}alarms-score`, data, config);
    return {
        type: UPDATE_DRIVER_SCORE_CONFIG,
        promise: request,
    };
}
export function deleteDriverScoreConfig(accesstoken, type, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}alarms-score/${id}`, config);
    return {
        type: DELETE_DRIVER_SCORE_CONFIG,
        promise: request,
    };
}
export function fetchDriverScoreConfig(accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_URL}alarms-score`, config);
    return {
        type: FETCH_DRIVER_SCORE_CONFIG,
        promise: request,
    };
}
export function fetchDriverScoreConfigById(accesstoken, id, from, to, filter) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({ from, to, transporterId: filter.transporterId }),
    };
    const request = axios.get(`${ROOT_API_URL}driver-analytics/${id}`, config);
    return {
        type: FETCH_DRIVER_SCORE_CONFIG,
        promise: request,
    };
}
export function fetchDriverOverview(accesstoken, driverId, from, to) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({ from, to, driverId }),
    };
    const request = axios.get(`${ROOT_API_URL}driver-analytics/overview`, config);
    return {
        type: FETCH_DRIVER_OVERVIEW,
        promise: request,
    };
}
export function fetchDriverTrendScore(from, to, ignoreZeroTrips, ignoreNoAssignment, vehicleGroupId) {
    const config = {
        params: parseQueryParams({ from, to, ignoreZeroTrips, ignoreNoAssignment, vehicleGroupId }),
    };
    const request = axios.get(`${ROOT_API_URL}driver-analytics/driver-trend-score`, config);
    return {
        type: FETCH_DRIVER_TREND_SCORE,
        promise: request,
    };
}
export function fetchDriverScoreGraph(from, to, ignoreZeroTrips, ignoreNoAssignment, vehicleGroupId) {
    const config = {
        params: parseQueryParams({ from, to, ignoreZeroTrips, ignoreNoAssignment, vehicleGroupId }),
    };
    const request = axios.get(`${ROOT_API_URL}driver-analytics/driver-score-graph`, config);
    return {
        type: FETCH_DRIVER_SCORE_GRAPH,
        promise: request,
    };
}
export function fetchDriverOverviewNode(accesstoken, driverId, transporterId, from, to) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({ startDate: from, endDate: to, driverId, transporterId }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/overview/driver-analytics`, config);
    return {
        type: FETCH_DRIVER_OVERVIEW,
        promise: request,
    };
}
