import { jsx as _jsx } from "react/jsx-runtime";
const AccountConfigComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-account-config" */ 'components/dashboard/config/account_config_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditApprovalMatrixComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-approvalmatrix" */ 'components/dashboard/config/approval-matrix/add_edit_approval_matrix'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ApprovalMatrixComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-approvalmatrix" */ 'components/dashboard/config/approval-matrix/approval_matrix_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditAutoJobConfigComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-auto-job" */ 'components/dashboard/config/auto-job/add_edit_auto_job_config_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AutoJobListingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-auto-job" */ 'components/dashboard/config/auto-job/auto_job_listing_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ConfigComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-config" */ 'components/dashboard/config/config_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DispatchPlannerConfig = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-dispatch-planner" */ 'components/dashboard/config/dispatch_planner/dispatch_planner_config'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DriverScoreConfig = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-driver-score" */ 'components/dashboard/config/driver_score_config'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditEscalationMatrixComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-issue-matrix" */ 'components/dashboard/config/issue-matrix/issue_escalation_matrix'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RiskReportComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-config-risk-report" */ 'components/dashboard/config/risk-report/risk_report_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditRiskRouteReportConfig = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-config-risk-report" */ 'components/dashboard/config/risk-report/route-report/add_edit_risk_route_report_config'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TripStratificationComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-config-trip-stratification" */ 'components/dashboard/config/trip_stratification/trip_stratification_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FormTemplatesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-form-templates" */ 'components/dashboard/form-templates/FormTemplatesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TagsInternalComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-internal-tags" */ 'components/dashboard/internal_tools/tags/tags_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RealtimeShareLinksListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-realtime-share" */ 'components/dashboard/realtime/realtime_share_list_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobChecklistGenerationModalComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-job-checklist-generation" */ 'components/dashboard/route_dispatch/jobs/job-checklist-generation/jobChecklistGeneration'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RealtimeJobShareListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-realtime-job-share" */ 'components/dashboard/route_dispatch/jobs/share/real_time_job_share_list_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditServiceTaskComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-servicetasks" */ 'components/dashboard/servicetasks/add_edit_service_task_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ServiceTasksComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-servicetasks" */ 'components/dashboard/servicetasks/service_tasks_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountCancel = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-account-cancel" */ 'components/dashboard/settings/account_cancel'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountEdit = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-account-edit" */ 'components/dashboard/settings/account_edit'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountSubscription = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-account-subscription" */ 'components/dashboard/settings/account_subscription'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AlertSettingsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-alerts" */ 'components/dashboard/settings/alerts/alert_settings_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AlertSettingsEditComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-alerts" */ 'components/dashboard/settings/alerts/alert_settings_edit_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const BillTypeComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-settings-billtype" */ 'components/dashboard/settings/bill_type'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditContactRenewalTypeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-contactrenewaltypes" */ 'components/dashboard/settings/contactrenewaltypes/add_edit_contact_renewal_type_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ContactRenewalTypesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-contactrenewaltypes" */ 'components/dashboard/settings/contactrenewaltypes/contact_renewal_types_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CreateFeatureComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-createfeature" */ 'components/dashboard/settings/createfeature/create_feature_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EntityRenewalTypes = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-entityrenewaltypes" */ 'components/dashboard/settings/entityrenewaltypes/EntityRenewalTypes'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
import FleetxPaymentReceivableModeDetailsComponent from 'components/dashboard/settings/fleetx_payment_receivable_mode_details_component';
const AddEditGroupComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-groups" */ 'components/dashboard/settings/groups/add_edit_group_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const GroupsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-groups" */ 'components/dashboard/settings/groups/groups_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobCheckList = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-job-checklist" */ 'components/dashboard/settings/JobCheckList'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditConsignorCustomField = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-job-checklist" */ 'components/dashboard/settings/jobs/add_edit_consignor_custom_fields'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CustomFields = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-job-checklist" */ 'components/dashboard/settings/jobs/consignor_custom_fields'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditTyreConfiguration = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-tyres" */ 'components/dashboard/settings/tyres/add_edit_tyre_configuration'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditTyreConfigurationPosition = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-tyres" */ 'components/dashboard/settings/tyres/add_edit_tyre_configuration_position'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const SettingsAddTyreConfiguration = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-tyres" */ 'components/dashboard/settings/tyres/settings_add_tyre_configuration_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditUserTypeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-usertypes" */ 'components/dashboard/settings/usertypes/add_edit_user_type_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UserTypesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-usertypes" */ 'components/dashboard/settings/usertypes/user_types_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleEnableDisableComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicle-enable-disable" */ 'components/dashboard/settings/vehicle_enable_disable'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditVehicleRenewalTypeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-vehiclerenewaltypes" */ 'components/dashboard/settings/vehiclerenewaltypes/add_edit_vehicle_renewal_type_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleRenewalTypesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-vehiclerenewaltypes" */ 'components/dashboard/settings/vehiclerenewaltypes/vehicle_renewal_types_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TagsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-tags" */ 'components/dashboard/tags/tags_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddTimeFenceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-timefences" */ 'components/dashboard/timefence/add_time_fence_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TimeFenceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-timefences" */ 'components/dashboard/timefence/time_fences_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TmsUnits = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-tms-units" */ 'components/dashboard/tms_units/tmsUnitComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UserPersonalisationConfigComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-userpersonalisation" */ 'components/dashboard/userPersonalisation/userPersonalisationConfigComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ForgotProfilePasswordComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-forgotProfilePassword" */ 'components/dashboard/users/forgot_profile_password_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ResetProfilePasswordComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-resetProfilePassword" */ 'components/dashboard/users/reset_profile_password_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UpdatePasswordComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-updatePassword" */ 'components/dashboard/users/update_password_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UpdateProfilePasswordComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-updateProfilePassword" */ 'components/dashboard/users/update_profile_password_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RolesComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-roles" */ 'components/dashboard/roles/roles_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TransporterMappings = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-transporter-mapping" */ 'components/dashboard/finances/transporters/transporter_mapping'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountSetup = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-account-setup" */ 'components/dashboard/settings/account_setup'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const LanguageChangeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-language-change" */ 'components/dashboard/settings/localisation/language/language_change_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const PartEntryStoreComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-part-entry-store" */ 'components/dashboard/parts_inventory/part_entry_store/part_entry_store_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditSlotsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-shiftwise-slots" */ 'components/dashboard/shiftwiseDriver/slots/AddEditSlotsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import ErrorBoundary from 'components/utils/error_boundary_component';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
// Routes
import expenseRoutes from './expense';
import vendorRoutes from './vendors';
import usersRoutes from './users';
import firmsRoutes from './firms';
import customersRoutes from './customers';
import clientStoreRoutes from './clientstore';
import transporterRoutes from './transporter';
import productRoutes from './product';
import vehicleSizeRoutes from './vehiclesize';
import geofenceRoutes from './geofence';
import logShiftRoutes from './logshift';
import commoditiesRoutes from './commodities';
import customChargesRoutes from './customcharges';
import partsModelsRoutes from './partsmodels';
import partsRoutes from './parts';
import partsMakersRoutes from './partmakers';
import partsCategoriesRoutes from './partcategories';
import stationaryRoutes from './stationary';
import officeBranchRoutes from './officeBranch';
import questionRoutes from './questions';
import stagesRoutes from './stages';
import loadTypesRoutes from './loadtypes';
import materialRoutes from './materials';
import materialGroupsRoutes from './materialGroups';
import gstRatesRoutes from './gstRates';
import chargeHeadsRoutes from './chargeHeads';
import ledgersRoutes from './ledgers';
import accountGroupRoutes from './accountGroup';
import divisionRoutes from './division';
import vehiceReminderRoutes from './vehicleReminder';
import currencyChargesRoutes from './currencyConversion';
import reasonMasterRoutes from './reasonMaster';
import reportTemplatesRoutes from './reportTemplate';
import bussinessVerticalRoutes from './bussinessVertical';
import trainingRoutes from './training';
import customLineItmesRoutes from './customLineItems';
const AddEditEwayBillProfileComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-ewaybillprofile" */ 'components/dashboard/ewayBill/ewayBillProfile/AddEditEwayBillProfileComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ERPProfileComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-erp-profile" */ 'components/dashboard/erpProfile/ERPProfileComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CustomSampleFileComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-custom-sample-file" */ 'components/dashboard/customSampleFile/CustomSampleFileComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ThirdPartyIntegrationComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-third-party-integration" */ 'components/dashboard/settings/3rdPartyIntegration/ThirdPartyIntegrationComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CloneVehicleComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-clone-vehicle" */ 'components/dashboard/settings/cloneVehicles/CloneVehicles'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ModuleFeedbackComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-module-feedback" */ 'components/dashboard/settings/moduleFeedback/ModuleFeedbackComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AppGlobalConfig = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-app-global-config" */ 'components/dashboard/AppGlobalConfig/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddCustomDateRanges = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-config-add-custom-date-ranges" */ 'components/dashboard/config/add-custom-date/add_custom_date_ranges'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const settingsRoutes = [
    {
        path: 'ewaybillprofile',
        lazy: AddEditEwayBillProfileComponent,
    },
    {
        path: 'erpprofile',
        lazy: ERPProfileComponent,
    },
    {
        path: 'customSampleFile',
        lazy: CustomSampleFileComponent,
    },
    {
        path: 'cloneVehicles',
        lazy: CloneVehicleComponent,
    },
    {
        path: 'module-feedback',
        lazy: ModuleFeedbackComponent,
    },
    {
        path: '3rdPartyIntegration',
        lazy: ThirdPartyIntegrationComponent,
    },
    {
        path: 'app-global-config',
        lazy: AppGlobalConfig,
    },
    {
        path: 'account/edit',
        lazy: AccountEdit,
    },
    {
        path: 'account/subscription',
        lazy: AccountSubscription,
    },
    {
        path: 'jobChecklist',
        lazy: JobCheckList,
    },
    {
        path: 'account/cancel',
        lazy: AccountCancel,
    },
    {
        path: 'groups/add',
        lazy: AddEditGroupComponent,
    },
    {
        path: 'groups/edit/:id',
        lazy: AddEditGroupComponent,
    },
    {
        path: 'groups',
        lazy: GroupsComponent,
    },
    {
        path: 'issue_matrix',
        lazy: AddEditEscalationMatrixComponent,
    },
    {
        path: 'usertypes/add',
        lazy: AddEditUserTypeComponent,
    },
    {
        path: 'usertypes/edit/:id',
        lazy: AddEditUserTypeComponent,
    },
    {
        path: 'usertypes',
        lazy: UserTypesComponent,
    },
    {
        path: 'alerts/edit/:id/:id2',
        lazy: AlertSettingsEditComponent,
    },
    {
        path: 'alerts/edit/:id/',
        lazy: AlertSettingsEditComponent,
    },
    {
        path: 'billtype/',
        lazy: BillTypeComponent,
    },
    {
        path: 'servicetasks/add',
        lazy: AddEditServiceTaskComponent,
    },
    {
        path: 'servicetasks/edit/:id',
        lazy: AddEditServiceTaskComponent,
    },
    {
        path: 'servicetasks',
        lazy: ServiceTasksComponent,
    },
    {
        path: 'vehiclerenewaltypes/add',
        lazy: AddEditVehicleRenewalTypeComponent,
    },
    {
        path: 'vehiclerenewaltypes/edit/:id',
        lazy: AddEditVehicleRenewalTypeComponent,
    },
    {
        path: 'vehiclerenewaltypes',
        lazy: VehicleRenewalTypesComponent,
    },
    {
        path: 'contactrenewaltypes/add',
        lazy: AddEditContactRenewalTypeComponent,
    },
    {
        path: 'contactrenewaltypes/edit/:id',
        lazy: AddEditContactRenewalTypeComponent,
    },
    {
        path: 'contactrenewaltypes',
        lazy: ContactRenewalTypesComponent,
    },
    {
        path: 'approval_matrix/add',
        lazy: AddEditApprovalMatrixComponent,
    },
    {
        path: 'approval_matrix/edit/:id',
        lazy: AddEditApprovalMatrixComponent,
    },
    {
        path: 'approval_matrix',
        lazy: ApprovalMatrixComponent,
    },
    {
        path: 'alerts',
        lazy: AlertSettingsComponent,
    },
    {
        path: 'createFeature',
        lazy: CreateFeatureComponent,
    },
    {
        path: 'entityrenewaltypes',
        lazy: EntityRenewalTypes,
    },
    {
        path: 'dispatchplanner',
        lazy: DispatchPlannerConfig,
    },
    {
        path: 'timefences/',
        lazy: TimeFenceComponent,
    },
    {
        path: 'timefences/add',
        lazy: AddTimeFenceComponent,
    },
    {
        path: 'timefences/edit/:id',
        lazy: AddTimeFenceComponent,
    },
    {
        path: 'tags',
        lazy: TagsComponent,
    },
    {
        path: 'internaltags',
        lazy: TagsInternalComponent,
    },
    {
        path: 'config',
        lazy: ConfigComponent,
    },
    {
        path: 'account-config',
        lazy: AccountConfigComponent,
    },
    {
        path: 'auto-jobs/all',
        lazy: AutoJobListingComponent,
    },
    {
        path: 'risk-report/all',
        lazy: RiskReportComponent,
    },
    {
        path: 'risk-report/edit/:id',
        lazy: AddEditRiskRouteReportConfig,
    },
    {
        path: 'trip-stratification/all',
        lazy: TripStratificationComponent,
    },
    {
        path: 'auto-jobs/edit/:id',
        lazy: AddEditAutoJobConfigComponent,
    },
    {
        path: 'userpersonalisationconfig',
        lazy: UserPersonalisationConfigComponent,
    },
    {
        path: 'updatepassword/',
        lazy: UpdatePasswordComponent,
    },
    {
        path: 'updateprofilepassword/',
        lazy: UpdateProfilePasswordComponent,
    },
    {
        path: 'forgotprofilepassword/',
        lazy: ForgotProfilePasswordComponent,
    },
    {
        path: 'resetprofilepassword',
        lazy: ResetProfilePasswordComponent,
    },
    {
        path: 'jobshares',
        lazy: RealtimeJobShareListComponent,
    },
    {
        path: 'customfields/edit/:id',
        lazy: AddEditConsignorCustomField,
    },
    {
        path: 'customfields/add',
        lazy: AddEditConsignorCustomField,
    },
    {
        path: 'customfields',
        lazy: CustomFields,
    },
    {
        path: 'shares',
        lazy: RealtimeShareLinksListComponent,
    },
    {
        path: 'tyreconfiguration/add/:id',
        lazy: AddEditTyreConfigurationPosition,
    },
    {
        path: 'tyreconfiguration/edit/:id',
        lazy: AddEditTyreConfigurationPosition,
    },
    {
        path: 'tyreconfiguration/add',
        lazy: AddEditTyreConfiguration,
    },
    {
        path: 'tyreconfiguration',
        lazy: SettingsAddTyreConfiguration,
    },
    {
        path: 'vehicle-enable-disable',
        lazy: VehicleEnableDisableComponent,
    },
    {
        path: 'formTemplates',
        lazy: FormTemplatesComponent,
    },
    {
        path: 'driversscore',
        lazy: DriverScoreConfig,
    },
    {
        path: 'tmsUnits',
        lazy: TmsUnits,
    },
    {
        path: 'jobs/job-checklist-generation',
        lazy: JobChecklistGenerationModalComponent,
    },
    {
        path: 'roles',
        lazy: RolesComponent,
    },
    {
        path: 'setup',
        lazy: AccountSetup,
    },
    {
        path: 'transporter-mapping',
        lazy: TransporterMappings,
    },
    {
        path: 'language',
        lazy: LanguageChangeComponent,
    },
    {
        path: 'partentrystore',
        lazy: PartEntryStoreComponent,
    },
    {
        path: 'shiftwise/slots',
        lazy: AddEditSlotsComponent,
    },
    {
        path: 'payment/modes',
        element: (_jsx(ErrorBoundary, { children: _jsx(FleetxPaymentReceivableModeDetailsComponent, { isMobile: false }) })),
    },
    {
        path: 'expenses/*',
        children: expenseRoutes,
    },
    {
        path: 'vendors/*',
        children: vendorRoutes,
    },
    {
        path: 'firms/*',
        children: firmsRoutes,
    },
    {
        path: 'users/*',
        children: usersRoutes,
    },
    {
        path: 'customers/*',
        children: customersRoutes,
    },
    {
        path: 'stores/*',
        children: clientStoreRoutes,
    },
    {
        path: 'products/*',
        children: productRoutes,
    },
    {
        path: 'transporters/*',
        children: transporterRoutes,
    },
    {
        path: 'vehicleSize/*',
        children: vehicleSizeRoutes,
    },
    {
        path: 'geofences/*',
        children: geofenceRoutes,
    },
    {
        path: 'shift/*',
        children: logShiftRoutes,
    },
    {
        path: 'custom-charges/*',
        children: customChargesRoutes,
    },
    {
        path: 'commodities/*',
        children: commoditiesRoutes,
    },
    {
        path: 'parts/*',
        children: partsRoutes,
    },
    {
        path: 'partmodels/*',
        children: partsModelsRoutes,
    },
    {
        path: 'partcategories/*',
        children: partsCategoriesRoutes,
    },
    {
        path: 'partmakers/*',
        children: partsMakersRoutes,
    },
    {
        path: 'stationary/*',
        children: stationaryRoutes,
    },
    {
        path: 'branch/*',
        children: officeBranchRoutes,
    },
    {
        path: 'questions/*',
        children: questionRoutes,
    },
    {
        path: 'stages/*',
        children: stagesRoutes,
    },
    {
        path: 'ratecharts/loadtypes/*',
        children: loadTypesRoutes,
    },
    {
        path: 'ratecharts/materials/*',
        children: materialRoutes,
    },
    {
        path: 'ratecharts/materialgroups/*',
        children: materialGroupsRoutes,
    },
    {
        path: 'consignments/gstrates/*',
        children: gstRatesRoutes,
    },
    {
        path: 'consignments/chargeheads/*',
        children: chargeHeadsRoutes,
    },
    {
        path: 'consignments/ledgers/*',
        children: ledgersRoutes,
    },
    {
        path: 'consignments/account-groups/*',
        children: accountGroupRoutes,
    },
    {
        path: 'consignments/divisions/*',
        children: divisionRoutes,
    },
    {
        path: 'vehiclerenewalreminder/*',
        children: vehiceReminderRoutes,
    },
    {
        path: 'currency-conversion/*',
        children: currencyChargesRoutes,
    },
    {
        path: 'reasonMaster/*',
        children: reasonMasterRoutes,
    },
    {
        path: 'reporttemplates/*',
        children: reportTemplatesRoutes,
    },
    {
        path: 'business-verticals',
        children: bussinessVerticalRoutes,
    },
    {
        path: 'training',
        children: trainingRoutes,
    },
    {
        path: 'custom-line-items/*',
        children: customLineItmesRoutes,
    },
    {
        path: 'custom-date-range',
        lazy: AddCustomDateRanges,
    },
];
export default settingsRoutes;
