import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../../constant';
export const ADD_TYRE_INWARD = 'add_tyre_inward';
export const ADD_TYRE_OUTWARD = 'add_tyre_outward';
export const TYRE_HISTORY = 'tyre_history';
export const TYRE_FILTER = 'tyre_filter';
export const TYRE_FILTER_OUT_OF_STOCK = 'tyre_filter_out_of_stock';
export const ADD_TYRE_TAGS = 'add_tyre_tags';
export const DELETE_TYRE_TAGS = 'delete_tyre_tags';
export const RF_TAG_LISTING = 'rf_tag_listing';
export const RF_TAG_LISTING_ALL = 'rf_tag_listing_all';
export const FETCH_TYRE_INWARD_EVENTS = 'FETCH_TYRE_INWARD_EVENTS';
export const ADD_EDIT_NEW_TYRE = 'ADD_EDIT_NEW_TYRE';
export const FETCH_TYRE_BY_ID = 'FETCH_TYRE_BY_ID';
export const ISSUE_REMOVE_TYRE = 'ISSUE_REMOVE_TYRE';
export const FETCH_TYRE_ISSUANCE_REMOVAL_EVENTS = 'FETCH_TYRE_ISSUANCE_REMOVAL_EVENTS';
export const FETCH_TYRE_SCRAP_THEFT_REPORT = 'FETCH_TYRE_SCRAP_THEFT_REPORT';
export const NEW_TYRE_STOCK_REPORT_DOWNLOAD = 'NEW_TYRE_STOCK_REPORT_DOWNLOAD';
export const OLD_TYRE_STOCK_REPORT_DOWNLOAD = 'OLD_TYRE_STOCK_REPORT_DOWNLOAD';
export const ALL_VEHICLE_TYRE_REPORT_DOWNLOAD = 'ALL_VEHICLE_TYRE_REPORT_DOWNLOAD';
export const TYRE_OUTWARD_REPORT_DOWNLOAD = 'TYRE_OUTWARD_REPORT_DOWNLOAD';
export const TYRE_OUTWARD_REPORT_V2_DOWNLOAD = 'TYRE_OUTWARD_REPORT_V2_DOWNLOAD';
export const TYRE_INWARD_REPORT_DOWNLOAD = 'TYRE_INWARD_REPORT_DOWNLOAD';
export const TYRE_ISSUANCE_REPORT_DOWNLOAD = 'TYRE_ISSUANCE_REPORT_DOWNLOAD';
export const TYRE_REMOVAL_REPORT_DOWNLOAD = 'TYRE_REMOVAL_REPORT_DOWNLOAD';
export const TYRE_SWAP_REPORT = 'TYRE_SWAP_REPORT';
export function addTyreInward(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}tyres/inward`, values, config);
    return {
        type: ADD_TYRE_INWARD,
        promise: request,
    };
}
export function addTyreOutward(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}tyres/outward`, values, config);
    return {
        type: ADD_TYRE_OUTWARD,
        promise: request,
    };
}
export function tyreHistory(accesstoken, tyreId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            tyreId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/history`, config);
    return {
        type: TYRE_HISTORY,
        promise: request,
    };
}
export function tyreFilter(accesstoken, stateList, storeId, vendorId, tyreAttached, enabled) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            stateList,
            storeId,
            vendorId,
            tyreAttached,
            enabled,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/filter`, config);
    return {
        type: TYRE_FILTER,
        promise: request,
    };
}
export function tyreFilterOutOfStock(accesstoken, inwardStoreId, outwardStoreId, outwardStationaryNumber) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            inwardStoreId,
            outwardStoreId,
            outwardStationaryNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/filter/stock-out`, config);
    return {
        type: TYRE_FILTER_OUT_OF_STOCK,
        promise: request,
    };
}
export function addTyreTags(accesstoken, tyreId, rfTagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            tyreId,
            rfTagId,
        }),
    };
    const request = axios.put(`${ROOT_API_ERP}tyre/theft/tag`, null, config);
    return {
        type: ADD_TYRE_TAGS,
        promise: request,
    };
}
export function deleteTyreTags(accesstoken, tyreId, rfTagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            tyreId,
            rfTagId,
        }),
    };
    const request = axios.delete(`${ROOT_API_ERP}tyre/theft/tag`, config);
    return {
        type: DELETE_TYRE_TAGS,
        promise: request,
    };
}
export function rfTagListing(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}tyre/theft/tag/detached`, config);
    return {
        type: RF_TAG_LISTING,
        promise: request,
    };
}
export function rfTagListingAll(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}tyre/theft/tag`, config);
    return {
        type: RF_TAG_LISTING_ALL,
        promise: request,
    };
}
export function fetchTyreInwardEvents(accesstoken, fromMrnDate, toMrnDate, nature, branchId, stationaryNumber, vendorId, storeId, targetCurrency, conversionDate) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromMrnDate,
            toMrnDate,
            natures: nature,
            branchId,
            stationaryNumber,
            size: 5000,
            vendorId,
            storeId,
            targetCurrency,
            conversionDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/inward`, config);
    return {
        type: FETCH_TYRE_INWARD_EVENTS,
        promise: request,
    };
}
export function fetchTyreOutwardEvents(accesstoken, storeId, nature, branchId, otherParams) {
    const { fromMrnDate = null, toMrnDate = null } = otherParams;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            storeId,
            nature,
            branchId,
            size: 5000,
            fromMrnDate,
            toMrnDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/outward`, config);
    return {
        type: FETCH_TYRE_INWARD_EVENTS,
        promise: request,
    };
}
export function addEditTyre(data, isEdit, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (isEdit && data.id) {
        request = axios.put(`${ROOT_API_ERP}tyres/${data.id}`, data, config);
    }
    else {
        request = axios.post(`${ROOT_API_ERP}tyres/`, data, config);
    }
    return {
        'type': ADD_EDIT_NEW_TYRE,
        'promise': request,
    };
}
export function fetchTyreById(accesstoken, id) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.get(`${ROOT_API_ERP}tyres/?id=${id}`, config);
    return {
        'type': FETCH_TYRE_BY_ID,
        'promise': request,
    };
}
export function issueRemoveTyre(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}tyres/issuance-removal`, values, config);
    return {
        type: ISSUE_REMOVE_TYRE,
        promise: request,
    };
}
export function fetchTyreIssuanceRemovalEvents(accesstoken, storeId, nature, branchId, vehicleId, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            storeId,
            nature,
            branchId,
            vehicleId,
            from,
            to,
            size: 5000,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/issuance-removal`, config);
    return {
        type: FETCH_TYRE_ISSUANCE_REMOVAL_EVENTS,
        promise: request,
    };
}
export function triggerTyreScrapAndTheftReport(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-theft-scrap`, config);
    return {
        type: FETCH_TYRE_SCRAP_THEFT_REPORT,
        promise: request,
    };
}
export function newTyreStockReportDownload(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre/new`, config);
    return {
        type: NEW_TYRE_STOCK_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function oldTyreStockReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        // responseType: 'blob',
        params: params,
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre/old`, config);
    return {
        type: OLD_TYRE_STOCK_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function allVehicleTyreReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: params,
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-configuration-report`, config);
    return {
        type: ALL_VEHICLE_TYRE_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function tyreOutwardReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-outward`, config);
    return {
        type: TYRE_OUTWARD_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function tyreOutwardReportV2Download(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-outward-report`, config);
    return {
        type: TYRE_OUTWARD_REPORT_V2_DOWNLOAD,
        promise: request,
    };
}
export function tyreIssuanceReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-issuance-report`, config);
    return {
        type: TYRE_ISSUANCE_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function tyreRemovalReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-removal-report`, config);
    return {
        type: TYRE_REMOVAL_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function tyreInwardReportDownload(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-inward-report`, config);
    return {
        type: TYRE_INWARD_REPORT_DOWNLOAD,
        promise: request,
    };
}
export function tyreSwapReport(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/tyre-swap-report`, config);
    return {
        type: TYRE_SWAP_REPORT,
        promise: request,
    };
}
