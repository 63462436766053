import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditFinancingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-financing-add-edit" */ 'components/dashboard/financing/form/AddEditFinancingComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FinancingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-financing" */ 'components/dashboard/financing/FinancingComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const financingRoutes = [
    {
        path: 'add',
        lazy: AddEditFinancingComponent,
    },
    {
        path: 'edit/:id/',
        lazy: AddEditFinancingComponent,
    },
    {
        index: true,
        lazy: FinancingComponent,
    },
];
export default financingRoutes;
