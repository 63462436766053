import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { get } from 'lodash';
import { FxCard, FxButton, FxCol, FxRow, FxTypography } from '../../../../sharedV2';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import AdjustmentBillComponent from './AdjustmentBillComponent';
import BulkPaymentSectionComponent from './BulkPaymentSectionComponent';
import { getEndDate, getMomentTime } from '../../../../utils/date_utils';
import { fetchCustomers } from '../../../../actions/dashboard/finances/customer_action';
import { fetchFreightBillsSearch } from '../../../../actions/dashboard/consignments/freightbill/freightBillActions';
import { FxPlusIcon } from '../../../../sharedV2/FxIcons';
import { preparePayloadData } from '../paymentUtils';
import { handleError } from '../../../../constant';
import { createAdjustmentNotes } from '../../../../actions/dashboard/payment_forms/actions';
const initialValues = {
    branch: '',
    creditBookType: 'CREDIT_NOTE',
    debitBookType: 'DEBIT_NOTE',
    creditStationaryId: '',
    debitStationaryId: '',
    sections: [
        {
            customerId: '',
            startDate: getMomentTime().subtract(7, 'days'),
            endDate: getEndDate(),
            allBillNumbers: [],
            entries: [
                {
                    billNumber: '',
                    paymentType: '',
                    amount: '',
                    date: null,
                    adviceNo: '',
                    remarks: '',
                },
            ],
        },
    ],
};
const AddBulkAdjustmentFormComponent = (props) => {
    const { accesstoken, customers } = props;
    const navigate = useNavigate();
    useEffect(() => {
        props.fetchCustomers(accesstoken);
    }, []);
    const fetchBills = async (sections, setFieldValue) => {
        try {
            // Filter sections with valid data
            const validSections = sections.filter((section) => section.customerId && section.startDate && section.endDate);
            if (!validSections.length)
                return;
            const results = await Promise.all(validSections.map((section, index) => props
                .fetchFreightBillsSearch(accesstoken, {
                billingPartyId: section.customerId,
                startDate: section.startDate.valueOf(),
                endDate: section.endDate.valueOf(),
            })
                .then((response) => ({
                index,
                bills: get(response, 'payload.data.content', []),
            }))));
            // Use setFieldValue to update the sections
            results.forEach((result) => {
                setFieldValue(`sections[${result.index}].allBillNumbers`, result.bills);
            });
        }
        catch (error) {
            console.error('Error fetching bills:', error);
        }
    };
    const handleSectionChange = (index, field, value, setFieldValue) => {
        setFieldValue(`sections[${index}].${field}`, value);
    };
    const handleBillNumberChange = (selectedBillNumber, sectionIndex, setFieldValue) => {
        console.log('selectedBillNumber', selectedBillNumber);
        const newEntries = selectedBillNumber.map((bill) => ({
            billNumber: get(bill, 'billNumber'),
            billId: get(bill, 'billId'),
            paymentType: '',
            amount: '',
            date: null,
            adviceNo: '',
            remarks: '',
        }));
        setFieldValue(`sections[${sectionIndex}].entries`, newEntries);
    };
    const onSubmit = (values, action) => {
        const { setSubmitting } = action;
        console.log('Form Values:', values);
        const payload = preparePayloadData(values);
        console.log('Payload:', payload);
        props
            .createAdjustmentNotes(accesstoken, payload)
            .then((result) => {
            setSubmitting(false);
            if (!result.error) {
                navigate('/dashboard/financing');
            }
            else {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            setSubmitting(false);
            alert(handleError(err));
        });
    };
    const addEditSchema = (values) => {
        return Yup.object().shape({});
    };
    const goBack = () => {
        navigate(-1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FxCard, { children: _jsx(FxTypography.Title, { level: 5, children: "Add Bulk Adjustment" }) }), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: () => Yup.lazy((values) => {
                    return addEditSchema(values);
                }), validateOnBlur: true, enableReinitialize: true, children: (formikProps) => {
                    const { isSubmitting, values, errors, setFieldValue } = formikProps;
                    console.log('errors-->', errors);
                    // Fetch the bills when sections change
                    useEffect(() => {
                        fetchBills(values.sections, setFieldValue);
                    }, [
                        JSON.stringify(values.sections.map(({ customerId, startDate, endDate }) => ({
                            customerId,
                            startDate,
                            endDate,
                        }))),
                        setFieldValue,
                    ]);
                    return (_jsxs(Form, { children: [_jsx(FxCard, { style: { marginTop: '10px' }, children: _jsx(AdjustmentBillComponent, { formikProps: formikProps, isReadyToFetchBillNumber: true, branchRequired: true, colClass: 6 }) }), _jsx(FxCard, { style: { marginTop: '10px' }, children: _jsx(FieldArray, { name: "sections", children: ({ remove, push }) => (_jsxs(_Fragment, { children: [values.sections.map((section, index) => (_jsx(BulkPaymentSectionComponent, { index: index, section: section, customers: customers, onChange: (index, field, value, setFieldValue) => {
                                                    handleSectionChange(index, field, value, setFieldValue);
                                                }, onBillNumberChange: (selectedBills) => handleBillNumberChange(selectedBills, index, setFieldValue), removeSection: () => remove(index), formikProps: formikProps }, index))), _jsxs(FxRow, { children: [_jsx(FxCol, { xs: 24, sm: 12, md: 12 }), _jsx(FxCol, { xs: 24, sm: 12, md: 12, children: _jsx(FxButton, { type: "primary", onClick: () => push({
                                                                customerId: '',
                                                                startDate: getMomentTime().subtract(7, 'days'),
                                                                endDate: getEndDate(),
                                                                allBillNumbers: [],
                                                                entries: [], // Explicitly reset entries here
                                                            }), icon: _jsx(FxPlusIcon, {}), style: { width: '25%', marginTop: '1rem', float: 'right' }, children: "Add New Section" }) })] })] })) }) }), _jsxs(FxRow, { style: {
                                    background: 'rgb(255 255 255)',
                                    borderTop: '1px solid #ffffff',
                                    boxShadow: 'rgb(242 242 242) 0px -4px 3px',
                                    position: 'sticky',
                                    marginLeft: '-36px',
                                    marginRight: '-36px',
                                    bottom: 0,
                                    marginBottom: '-25px',
                                    alignItems: 'center',
                                    zIndex: '100',
                                }, children: [_jsx(FxCol, { xs: 24, sm: 12, md: 12 }), _jsx(FxCol, { xs: 24, sm: 12, md: 12, className: "mt-2", children: _jsxs("div", { className: "mb-2 mr-4 float-right", children: [_jsx("button", { type: "submit", "data-testid": "freight-bill-submit-button", className: "btn btn-primary mr-3", disabled: isSubmitting, children: "Submit" }), _jsx(FxButton, { type: "default", onClick: goBack, "data-testid": "freight-bill-cancel-button", children: "Cancel" })] }) })] })] }));
                } })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomers,
        fetchFreightBillsSearch,
        createAdjustmentNotes,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkAdjustmentFormComponent);
