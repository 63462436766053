import { handle } from 'redux-pack';
import { FETCH_FINANCING, FETCH_FINANCING_BY_ID, FETCH_FINANCING_PAYMENT, FETCH_FINANCING_SUMMARY, FETCH_EMI_DETAILS_BY_ID, FETCH_EMI_LISITNG, } from 'actions/dashboard/financing_action';
const initialState = {
    isLoading: false,
    loans: [],
    totalElements: 0,
    error: null,
    loansPaymentData: null,
    financingSummary: null,
    emiDetails: null,
    emiDataOptions: null,
};
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_FINANCING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loans: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case FETCH_FINANCING_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loanDetailsById: payload.data,
                }),
            });
        case FETCH_FINANCING_PAYMENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loansPaymentData: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case FETCH_FINANCING_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    financingSummary: payload.data,
                }),
            });
        case FETCH_EMI_DETAILS_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    emiDetails: payload.data,
                }),
            });
        case FETCH_EMI_LISITNG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    emiDataOptions: payload.data,
                }),
            });
        default:
            return state;
    }
};
