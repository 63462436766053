import { jsx as _jsx } from "react/jsx-runtime";
import { Input as $Input } from 'antd';
import * as React from 'react';
import Field from './field';
const Input = React.forwardRef(({ name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }, ref) => {
    return (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value, onChange, onBlur } }) => (_jsx($Input, { ...restProps, ref: ref, name: name, id: name, value: value, onChange: (event) => {
                onChange(event);
                $onChange && $onChange(event);
            }, onBlur: (event) => {
                onBlur(event);
                $onBlur && $onBlur(event);
            } })) }));
});
const TypedInput = Input;
// @ts-ignore
TypedInput.Password = React.forwardRef(({ name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }, ref) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value, onChange, onBlur } }) => (_jsx($Input.Password, { ...restProps, ref: ref, name: name, id: name, value: value, onChange: (event) => {
            onChange(event);
            $onChange && $onChange(event);
        }, onBlur: (event) => {
            onBlur(event);
            $onBlur && $onBlur(event);
        } })) })));
TypedInput.TextArea = React.forwardRef(({ name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }, ref) => (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value, onChange, onBlur } }) => (_jsx($Input.TextArea, { ...restProps, ref: ref, name: name, id: name, value: value, onChange: (event) => {
            onChange(event);
            $onChange && $onChange(event);
        }, onBlur: (event) => {
            onBlur(event);
            $onBlur && $onBlur(event);
        } })) })));
export { TypedInput as Input };
export default TypedInput;
