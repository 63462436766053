import {
    concat,
    filter,
    find,
    forEach,
    get,
    indexOf,
    isEmpty,
    split,
    map,
    forEachRight,
    findLast,
    includes,
    toSafeInteger,
    keys,
    values,
    join,
    trim,
    toLower,
} from 'lodash';
import { getAllWordsCapitalized } from './string_utils';
import { addDataRow } from './report_utils';
import { FLEETX_ACCOUNTS, isExpressRoadwaysAccount } from 'utils/account_utils';
import { getHierarchyArray, getEntityTypeTag } from './account_utils';
import { getMomentTime } from 'utils/date_utils';

export const TAG_FILTER_OP = {
    AND: 'AND',
    OR: 'OR',
};

export const renderTagOption = (option, dontShowParent = false) => {
    const optionsLevel = option.label.split('/');
    const l = optionsLevel.length;
    const parentPart = getAllWordsCapitalized(optionsLevel.slice(0, l - 1).join('/'));
    const tagName = getAllWordsCapitalized(optionsLevel[l - 1].toLowerCase());

    const extraSlash = l > 1 ? '/' : '';
    return (
        <span>
            {!dontShowParent && (
                <span className="lightgray" style={{ opacity: 0.5 }}>
                    {parentPart}
                    {extraSlash}
                </span>
            )}
            <span className="text-black font-bold"> {tagName}</span>
        </span>
    );
};

export function mappedTags(tags, isEditModeOn, tagToEdit) {
    const options = [];
    forEach(tags, (tag) => {
        if (tag.deletable || tag.type === 'ASSIGN_NON_DELETABLE') {
            if (!isEditModeOn) {
                options.push({
                    value: tag.id,
                    label: tag.jointName,
                });
            } else {
                //dont include child tags as parent
                if (
                    indexOf(tag.grandParentIds, tagToEdit.id) < 0 &&
                    tag.parentId !== tagToEdit.id &&
                    tag.id !== tagToEdit.id
                ) {
                    options.push({
                        value: tag.id,
                        label: getAllWordsCapitalized(tag.jointName),
                    });
                }
            }
        }
    });
    return options;
}

export function mappedTagsForFilter(
    tags,
    selectedTags,
    tagsWithCount,
    dontShowJointName = false,
    removeDisabled = false
) {
    const options = [];
    let count;
    let label;
    let name;
    forEach(tags, (tag) => {
        count = tagsWithCount && tagsWithCount[tag.id] ? tagsWithCount[tag.id]['count'] : 0;
        name = dontShowJointName ? tag.name : tag.jointName;
        label = count > 0 ? `${name} (${count})` : `${name}`;
        label = getAllWordsCapitalized(label);
        options.push({
            value: tag.id,
            label,
            type: tag.type,
            disabled: removeDisabled ? false : !!find(selectedTags, { value: tag.id }),
            count,
        });
    });
    return options;
}

export const ADD_TAG_TO_TYPE = {
    VEHICLE: 'vehicle',
    ADDRESS: 'address',
};

export const getLeafTagsFromListOfTagIds = (selectedTags, allTags, loggedInUser) => {
    const attachedTags = [];
    map(selectedTags, (tag) => {
        const option = find(allTags, { id: +tag.value });
        if (option) {
            attachedTags.push(option);
        }
    });
    const hierarchyArrayInOrder = getHierarchyArray(loggedInUser);
    const typeWiseTags = getTagsOfType(hierarchyArrayInOrder, attachedTags, '', loggedInUser);
    const leafTags = [];
    map(attachedTags, (tag) => {
        const children = findAllChildrenOfTag(tag, typeWiseTags, hierarchyArrayInOrder);
        if (isEmpty(children)) {
            leafTags.push(tag);
        }
    });
    return leafTags;
};

export const findAllChildrenOfTag = (tag, allTypeWiseTags, hierarchyArrayInOrder) => {
    const currentIndex = indexOf(hierarchyArrayInOrder, tag.type);
    const length = get(hierarchyArrayInOrder, 'length', 0);
    const newOptions = [];
    if (currentIndex > -1) {
        for (let i = currentIndex + 1; i < length; i++) {
            const allOptionsAtTagLevel = get(allTypeWiseTags, hierarchyArrayInOrder[i]);
            map(allOptionsAtTagLevel, (option) => {
                if (option.parentId === tag.id) {
                    if (!find(newOptions, { id: tag.id })) {
                        newOptions.push(option);
                    }
                } else if (indexOf(option.grandParentIds, tag.id) > -1) {
                    if (!find(newOptions, { id: tag.id })) {
                        newOptions.push(option);
                    }
                }
            });
        }
    }
    return [...newOptions];
};

export function isTagsFilterConditionMatched(
    attachedTagIds,
    allTags,
    tagFilter,
    PrevToFilter,
    vehicleJob,
    loggedInUser
) {
    let toFilter = PrevToFilter;
    if (tagFilter) {
        const filterType = get(tagFilter, 'selectedFilter');
        let selectedTags = get(tagFilter, 'selectedTags', []);
        if (filterType === 'HIERARCHY') {
            selectedTags = getLeafTagsFromListOfTagIds(selectedTags, allTags, loggedInUser);
        }
        let possibleIds = [];
        let tempTag;
        forEach(attachedTagIds, (id) => {
            tempTag = find(allTags, { id: id });

            possibleIds.push(id); //collect tag id

            if (tempTag) {
                if (tempTag.grandParentIds) {
                    possibleIds = possibleIds.concat(tempTag.grandParentIds); //collect tag grandparent id
                }
                if (tempTag.parentId) {
                    possibleIds.push(tempTag.parentId); // collect tag parent id
                }
            }
        });
        const matchedTagsVehicle = filter(selectedTags, (tag) => {
            return indexOf(possibleIds, tag.value ? tag.value : tag.id) > -1;
        });
        const matchedJobTags = filter(selectedTags, (tag) => {
            const vehicleJobTags = getVehicleJobTags(vehicleJob);
            return indexOf(vehicleJobTags, tag.value ? tag.value : tag.id) > -1;
        });

        const matchedTags = [...matchedTagsVehicle, ...matchedJobTags];

        if (tagFilter.selectedFilter === 'AND') {
            toFilter = matchedTags.length >= selectedTags.length;
        } else if (tagFilter.selectedFilter === 'OR') {
            // OR
            toFilter = matchedTags.length > 0;
        } else if (tagFilter.selectedFilter === 'NOT') {
            toFilter = matchedTags.length >= 1;
            return !toFilter;
        } else {
            // HIERARCHY
            // entityHierarchyTags have parents belonging to a hierarchy but they dont 'really' belong to the hierarchy
            // think of them as entities associated with a particular level of hierarchy
            // for e.g. For tag type CITY,  PLANT belongs to hierarchy, however VENDOR is a special specialHierarchy Tag
            const entityHierarchyType = getEntityTypeTag(loggedInUser);
            const entityHierarchyTags = filter(selectedTags, (tag) => {
                return tag.type === entityHierarchyType;
            });
            if (!isEmpty(entityHierarchyTags) && matchedTags) {
                toFilter = handleEntityHierarchySiblings(matchedTags, entityHierarchyType);
            } else {
                toFilter = matchedTags.length > 0;
            }
        }
    }
    return toFilter;
}

export function handleEntityHierarchySiblings(matchedTags, type) {
    let found;
    const specialFilterTagExist = filter(matchedTags, (tag) => {
        return tag.type === 'VENDOR';
    });
    if (!isEmpty(specialFilterTagExist)) {
        found = filter(matchedTags, (tag) => {
            if (tag.type !== type) {
                return true;
            }
        });
    }
    if (!isEmpty(found)) {
        return true;
    } else {
        return false;
    }
}

export function getParentsFromAttachedTagIds(attachedTagIds, allTags, tagFilter) {
    let possibleIds = [];
    if (tagFilter) {
        let tempTag;
        forEach(attachedTagIds, (id) => {
            tempTag = find(allTags, { id: id });

            possibleIds.push(id); //collect tag id

            if (tempTag) {
                if (tempTag.grandParentIds) {
                    possibleIds = possibleIds.concat(tempTag.grandParentIds); //collect tag grandparent id
                }
                if (tempTag.parentId) {
                    possibleIds.push(tempTag.parentId); // collect tag parent id
                }
            }
        });
    }
    return possibleIds;
}

export function getItemCountPerTag(list, tags, jobTags, findTagsByKey = 'tagIds') {
    if (!tags || tags.length === 0 || !list || list.length === 0) {
        return;
    }
    let tagsObject = {};
    forEach(tags, (tag) => {
        tagsObject[tag.id] = { ...tag, count: 0 };
    });

    let tag, parentId, grandParentIds;

    forEach(list, (item) => {
        forEach(get(item, findTagsByKey, []), (tagId) => {
            tag = tagsObject[tagId];
            if (tag) {
                parentId = tag['parentId'];
                grandParentIds = tag['grandParentIds'];

                tag['count']++;

                if (parentId && tagsObject[parentId]) {
                    tagsObject[parentId]['count']++;
                }

                if (grandParentIds) {
                    forEach(grandParentIds, (pTagIds) => {
                        tagsObject[pTagIds]['count']++;
                    });
                }
            }
        });

        // if (!!item.vehicleId && !isEmpty(jobTags)) {
        //     let tempTags = [];
        //     if (jobTags[item.vehicleId]) {
        //         tempTags = Array.isArray(jobTags[item.vehicleId]) ? jobTags[item.vehicleId] : [jobTags[item.vehicleId]];
        //     }
        //     forEach(tempTags, tagId => {
        //         tag = tagsObject[tagId];
        //         if (tag) {
        //             parentId = tag['parentId'];
        //             grandParentIds = tag['grandParentIds'];
        //
        //             tag['count']++;
        //
        //             if (parentId) {
        //                 tagsObject[parentId]['count']++;
        //             }
        //
        //             if (grandParentIds) {
        //                 forEach(grandParentIds, pTagIds => {
        //                     tagsObject[pTagIds]['count']++;
        //                 });
        //             }
        //         }
        //     });
        // }
    });

    return tagsObject;
}

export function getVehicleJobTags(vehicleJob) {
    const jobTags = get(vehicleJob, 'tagId');
    if (!jobTags) {
        return [];
    }
    if (typeof jobTags === 'object') {
        if (isEmpty(jobTags)) {
            return [];
        }
        return jobTags;
    } else {
        return [jobTags];
    }
}

export function getTagNamesListStation(tags, attachedTags) {
    if (!tags || !attachedTags) {
        return [];
    }
    let tagNamesList = [],
        branch,
        temp,
        temp2;
    forEach(attachedTags, (tagId) => {
        temp = find(tags, { id: tagId });
        if (temp) {
            tagNamesList.push(temp.jointName);

            if (temp.type === 'BRANCH') {
                branch = temp.jointName;
            }
            if (temp.type === 'STATION') {
                temp2 = split(temp.jointName, '/');
                if (temp2 && temp2.length > 1) {
                    branch = temp2[0];
                }
            }
        }
    });

    if (!branch) {
        branch = '-';
    }

    return [tagNamesList, branch];
}

export function getTagNamesList(tags, attachedTags) {
    if (!tags || !attachedTags) {
        return [];
    }
    let tagNamesList = [],
        branch,
        temp;
    forEach(attachedTags, (tagId) => {
        temp = find(tags, { id: tagId });
        if (temp) {
            tagNamesList.push(temp.jointName);
            if (temp.type == 'BRANCH') {
                branch = temp.type == 'BRANCH' ? temp.jointName : '-';
            }
        }
    });
    if (!branch) {
        branch = '-';
    }

    return [tagNamesList, branch];
}

export function getTagsListFromAttachedTags(tags, attachedTags) {
    if (!tags || !attachedTags) {
        return [];
    }
    let tagNamesList = [],
        temp;
    forEach(attachedTags, (tagId) => {
        temp = find(tags, { id: tagId });
        if (temp) {
            tagNamesList.push(temp);
        }
    });

    return tagNamesList;
}

export const SPECIAL_TAG_TYPES = {
    STATION: 'STATION',
    BRANCH: 'BRANCH',
    DEFAULT: 'DEFAULT',
};

export const addFromToBranchStationDataToRow = (addressTags, k1, k2, dataRow, tags, includeTags) => {
    let addressBranchTags = [],
        addressStationTags = [],
        temp,
        tagNames,
        temp2;
    forEach(addressTags, (tagId) => {
        temp = find(tags, { id: tagId });
        if (temp) {
            if (temp.type === SPECIAL_TAG_TYPES.STATION) {
                temp2 = split(temp.jointName, '/');
                if (temp2 && temp2.length > 1) {
                    addressBranchTags.push(temp2[0]);
                    addressStationTags.push(temp2[1]);
                } else {
                    addressBranchTags.push(temp.jointName);
                }
            }
        }
    });
    tagNames = addressBranchTags.join(',');
    addDataRow(includeTags, dataRow, k1, tagNames);
    tagNames = addressStationTags.join(',');
    addDataRow(includeTags, dataRow, k2, tagNames);
};

export function concatTagsForFiltering(
    loggedInUser,
    tagsAttachedToVehicle,
    tagsAttachedToSavedAddress,
    tagsAttachedToSavedEndAddress,
    tagsAttachedToSavedStartAddress
) {
    // if (isExpressRoadwaysAccount(loggedInUser) && get(tagsAttachedToSavedEndAddress, 'length', 0) > 0) {
    //     tagsAttachedToSavedAddress = [];
    // }
    return concat(
        tagsAttachedToVehicle,
        tagsAttachedToSavedAddress,
        tagsAttachedToSavedEndAddress,
        tagsAttachedToSavedStartAddress
    );
}

export const collectTagsUpTheHierarchy = (attachedTagIds, tags) => {
    let possibleIds = [];
    let tempTag;
    forEach(attachedTagIds, (id) => {
        tempTag = find(tags, { id: id });

        possibleIds.push(id); //collect tag id

        if (tempTag) {
            if (tempTag.grandParentIds) {
                possibleIds = possibleIds.concat(tempTag.grandParentIds); //collect tag grandparent id
            }
            if (tempTag.parentId) {
                possibleIds.push(tempTag.parentId); // collect tag parent id
            }
        }
    });
    return possibleIds;
};

export const getTagsOfType = (allTypes, tags, options, loggedInUser) => {
    const allTypeWiseTags = {};
    options = options ? options : tags;
    const attachedTagIds = get(loggedInUser, 'tagIdsSet', []);
    const allParentAndGrandParentIds = collectTagsUpTheHierarchy(attachedTagIds, tags);

    map(allTypes, (type) => {
        const typeWiseTags = [];
        map(options, (tag) => {
            if (
                tag.type === type &&
                (get(attachedTagIds, 'length') === 0 || indexOf(allParentAndGrandParentIds, tag.id) > -1)
            ) {
                typeWiseTags.push(tag);
            }
        });
        allTypeWiseTags[type] = [...typeWiseTags];
    });
    return allTypeWiseTags;
};

export const getTripStatusFromTagIds = (tagIdsList, tripStatus = []) => {
    const keys = Object.keys(tripStatus);
    let found = -1;
    forEach(tagIdsList, function (tag) {
        const indexTag = indexOf(keys, tag.toString());
        if (indexTag > 0) {
            found = keys[indexTag];
        }
    });
    if (found > -1) {
        return tripStatus[found];
    }
    return '-';
};

export const HASTI_10049_ACCOUNT_SYSTEM_TAGS = {
    'Up Coming': 20192,
    'On Going': 20193,
    'Factory': 20194,
};

export const BLR_LOGISTICS_SYSTEM_TAGS = {
    TRIP_ASSIGNED: 22438,
    ARRIVED_AT_LOADING_POINT: 22439,
    LOADING_COMPLETED: 22440,
    ADVANCE_PAID: 22441,
    TRIP_STARTED: 22442,
    ARRIVED_AT_DELIVERY_POINT: 22443,
    UNLOADING_COMPLETED: 22444,
    READY_TO_LOAD: 22445,
    BREAKDOWN_START: 22446,
    BREAKDOWN_END: 22447,
    DRIVER_ON_LEAVE_START: 22448,
    DRIVER_ON_LEAVE_END: 22449,
    ACCIDENT: 22740,
    NO_DRIVER_START_DATE: 23336,
    PREVENTIVE_MAINTAINANCE_START_DATE: 23765,
    PREVENTIVE_MAINTAINANCE_END_DATE: 23766,
    ACCIDENT_END_DATE: 23768,
};
export const BLR_CUSTOM_FIELD_TO_TAG_MAPPING = {
    1828: BLR_LOGISTICS_SYSTEM_TAGS.ADVANCE_PAID,
    1829: BLR_LOGISTICS_SYSTEM_TAGS.READY_TO_LOAD,
    1830: BLR_LOGISTICS_SYSTEM_TAGS.ARRIVED_AT_LOADING_POINT,
    1831: BLR_LOGISTICS_SYSTEM_TAGS.LOADING_COMPLETED,
    1832: BLR_LOGISTICS_SYSTEM_TAGS.TRIP_STARTED,
    1833: BLR_LOGISTICS_SYSTEM_TAGS.ARRIVED_AT_DELIVERY_POINT,
    1834: BLR_LOGISTICS_SYSTEM_TAGS.UNLOADING_COMPLETED,
    1837: BLR_LOGISTICS_SYSTEM_TAGS.ACCIDENT,
    1949: BLR_LOGISTICS_SYSTEM_TAGS.BREAKDOWN_START,
    1950: BLR_LOGISTICS_SYSTEM_TAGS.BREAKDOWN_END,
    1951: BLR_LOGISTICS_SYSTEM_TAGS.DRIVER_ON_LEAVE_START,
    1952: BLR_LOGISTICS_SYSTEM_TAGS.DRIVER_ON_LEAVE_END,
    1864: BLR_LOGISTICS_SYSTEM_TAGS.TRIP_ASSIGNED,
    2029: BLR_LOGISTICS_SYSTEM_TAGS.NO_DRIVER_START_DATE,
    1948: BLR_LOGISTICS_SYSTEM_TAGS.PREVENTIVE_MAINTAINANCE_START_DATE,
    2063: BLR_LOGISTICS_SYSTEM_TAGS.PREVENTIVE_MAINTAINANCE_END_DATE,
    1920: BLR_LOGISTICS_SYSTEM_TAGS.ACCIDENT_END_DATE,
};

export function getMatchTagAndCustomFieldName(tag, cf) {
    return (
        `${toLower(tag.name)}`.match(toLower(get(cf, 'field.keyName'))) ||
        `${toLower(get(cf, 'field.keyName'))}`.match(`${toLower(tag.name)}`)
    );
}

export function getTagToAddToVehicleBasedOnCustomField({ tags, customFields, vehicle, tagsToRemoveFromVehicle }) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    let tagId = null;
    if (accountId === FLEETX_ACCOUNTS.BLR_LOGISTICS) {
        const customFieldsToCheck = map(BLR_CUSTOM_FIELD_TO_TAG_MAPPING, (v, k) => toSafeInteger(k));
        // find custom field with latest timestamp
        let customFieldFilled;
        forEach(customFields, (cf) => {
            const fieldId = get(cf, 'field.id');
            const fieldValue = cf.value;
            const lastFieldValue = get(customFieldFilled, 'value');
            if (includes(customFieldsToCheck, fieldId) && fieldValue) {
                if (lastFieldValue) {
                    if (getMomentTime(fieldValue).isSameOrAfter(lastFieldValue)) {
                        customFieldFilled = cf;
                    }
                } else {
                    customFieldFilled = cf;
                }
            }
        });
        const foundTag = find(tags, (tag) => {
            return getMatchTagAndCustomFieldName(tag, customFieldFilled);
        });
        tagId = foundTag?.id;
        const tagIdsSet = get(vehicle, 'tagIdsSet', []);
        tagId =
            includes(tagIdsSet, tagId) && (!tagsToRemoveFromVehicle || tagsToRemoveFromVehicle.length === 1)
                ? null
                : tagId;
    }
    return tagId;
}

export function getTagToRemoveFromVehicleBasedOnCustomFields({ tags, customFields, vehicle }) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    let tagIds = null;
    if (accountId === FLEETX_ACCOUNTS.BLR_LOGISTICS) {
        const tagIdsSet = get(vehicle, 'tagIdsSet', []);
        const customFieldsToCheck = filter(customFields, (cf) => {
            return includes(keys(BLR_CUSTOM_FIELD_TO_TAG_MAPPING), cf.field.id.toString());
        });
        tagIds = filter(tagIdsSet, (tId) => {
            const foundTag = find(tags, { id: tId });
            return !!find(customFieldsToCheck, (cf) => {
                return getMatchTagAndCustomFieldName(foundTag, cf);
            });
        });
        tagIds = !isEmpty(tagIds) ? join(tagIds, ',') : null;
    }

    return tagIds;
}

export const USER_PHOTO_TAGS = [
    { value: 'FACE_IMAGE', label: 'FACE_IMAGE' },
    { value: 'OTHER', label: 'OTHER' },
];
