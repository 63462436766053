import { jsx as _jsx } from "react/jsx-runtime";
import { Select as $Select } from 'antd';
import Field from './field';
import { FxSelect } from 'sharedV2';
export const Select = ({ name, validate, fast, children, onChange, onBlur, ...restProps }) => {
    return (_jsx(Field, { name: name, validate: validate, fast: fast, children: ({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (_jsx(FxSelect, { showSearch: true, optionFilterProp: "label", ...restProps, onChange: (value, option) => {
                setFieldValue(name, value);
                onChange && onChange(value, option);
            }, onBlur: (value) => {
                setFieldTouched(name);
                onBlur && onBlur(value);
            }, 
            // setting undefined will show the placeholder
            value: value === '' || value === null ? undefined : value, id: name, children: children })) }));
};
export default Select;
Select.Option = $Select.Option;
Select.OptGroup = $Select.OptGroup;
