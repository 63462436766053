import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, filter, isEqual, find, includes } from 'lodash';
import { Field } from 'formik';
import { FxSkeleton } from '../../../../shared';
import { getMappedBookTypes, mappedBranches, mappedStationaries } from '../../../utils/mapping_utils';
import { fetchStationaries, getNextStationary, validateStationary, fetchStationary, } from 'actions/dashboard/stationary/actions';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { isViewBranchAllowedForUser, isViewStationaryAllowedForUser } from '../../roles/permission_utils';
import { FxCol, FxRow, FxSelectField, FxTextFieldStackedLabelV2 } from '../../../../sharedV2';
class AdjustmentBillComponent extends Component {
    initalCreditStationaryId = null;
    initalDebitStationaryId = null;
    initalCreditStationaryNumber = null;
    initalDebitStationaryNumber = null;
    branchSetRef = false;
    state = {
        allCreditStationaries: [],
        allDebitStationaries: [],
        stationaryNature: null,
        isBranchesLoading: true,
        allBranches: [],
        deletedStationaries: [],
        isDeletedStationariesModalOpen: false,
        selectedDeletedStationary: null,
        isBranchChangedManully: false,
    };
    componentDidMount = async () => {
        if (isViewBranchAllowedForUser(this.props.login.scope)) {
            const respBranches = await this.props.fetchBranches(this.props.accesstoken, true);
            const allBranches = get(respBranches, 'payload.data');
            this.setState({
                allBranches,
                isBranchesLoading: false,
            });
        }
        const { branch, creditBookType, debitBookType } = this.props.formikProps.values;
        if (branch && creditBookType) {
            this.fetchCreditStationaries(branch, creditBookType, () => {
                this.onCreditStationarySelect(this.props.formikProps.values.creditStationaryId, branch, creditBookType, this.props.formikProps);
            });
        }
        if (branch && debitBookType) {
            this.fetchDebitStationaries(branch, debitBookType, () => {
                this.onDebitStationarySelect(this.props.formikProps.values.debitStationaryId, branch, debitBookType, this.props.formikProps);
            });
        }
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isReadyToFetchBillNumber) {
            if (!this.props.formikProps.values.branch &&
                get(this.props.formikProps.values, 'creditStationaryId') &&
                !this.branchSetRef) {
                this.branchSetRef = true;
                this.initalCreditStationaryId = get(this.props.formikProps.values, 'creditStationaryId');
                this.initalCreditStationaryNumber = get(this.props.formikProps.values, 'creditStationaryNumber');
                if (isViewStationaryAllowedForUser(this.props.login.scope)) {
                    this.props
                        .fetchStationary(this.props.accesstoken, get(this.props.formikProps.values, 'creditStationaryId'))
                        .then((res) => {
                        const stationary = get(res, 'payload.data');
                        if (stationary && stationary.branch.id) {
                            this.props.formikProps.setFieldValue('branch', stationary.branch.id);
                            this.fetchCreditStationaries(stationary.branch.id, stationary.creditBookType, () => {
                                this.onCreditStationarySelect(this.props.formikProps.values.creditStationaryId, stationary.branch.id, stationary.creditBookType, this.props.formikProps);
                            });
                        }
                    });
                }
            }
            if (!this.props.formikProps.values.branch &&
                get(this.props.formikProps.values, 'debitStationaryId') &&
                !this.branchSetRef) {
                this.branchSetRef = true;
                this.initalDebitStationaryId = get(this.props.formikProps.values, 'debitStationaryId');
                this.initalDebitStationaryNumber = get(this.props.formikProps.values, 'debitStationaryNumber');
                if (isViewStationaryAllowedForUser(this.props.login.scope)) {
                    this.props
                        .fetchStationary(this.props.accesstoken, get(this.props.formikProps.values, 'debitStationaryId'))
                        .then((res) => {
                        const stationary = get(res, 'payload.data');
                        if (stationary && stationary.branch.id) {
                            this.props.formikProps.setFieldValue('branch', stationary.branch.id);
                            this.fetchDebitStationaries(stationary.branch.id, stationary.debitBookType, () => {
                                this.onDebitStationarySelect(this.props.formikProps.values.debitStationaryId, stationary.branch.id, stationary.debitBookType, this.props.formikProps);
                            });
                        }
                    });
                }
            }
        }
        if (!isEqual(prevProps.formikProps.values, this.props.formikProps.values)) {
            const { branch, creditBookType, creditStationaryId } = this.props.formikProps.values;
            if (!isEqual(prevProps.formikProps.values.branch, this.props.formikProps.values.branch) &&
                prevState.isBranchChangedManully == this.state.isBranchChangedManully) {
                this.fetchCreditStationaries(branch, creditBookType, () => {
                    this.onCreditStationarySelect(creditStationaryId, branch, creditBookType, this.props.formikProps);
                });
            }
            else {
                if (branch && creditBookType && creditStationaryId) {
                    this.onCreditStationarySelect(creditStationaryId, branch, creditBookType, this.props.formikProps);
                }
            }
        }
        if (!isEqual(prevProps.formikProps.values, this.props.formikProps.values)) {
            const { branch, debitBookType, debitStationaryId } = this.props.formikProps.values;
            if (!isEqual(prevProps.formikProps.values.branch, this.props.formikProps.values.branch) &&
                prevState.isBranchChangedManully == this.state.isBranchChangedManully) {
                this.fetchCreditStationaries(branch, debitBookType, () => {
                    this.onDebitStationarySelect(debitStationaryId, branch, debitBookType, this.props.formikProps);
                });
            }
            else {
                if (branch && debitBookType && debitStationaryId) {
                    this.onDebitStationarySelect(debitStationaryId, branch, debitBookType, this.props.formikProps);
                }
            }
        }
    };
    fetchCreditStationaries = (branch, creditBookType, onCreditStationarySelect) => {
        if (isViewStationaryAllowedForUser(this.props.login.scope)) {
            this.props.fetchStationaries(this.props.accesstoken, branch, creditBookType).then((res) => {
                this.setState({
                    allCreditStationaries: get(res, 'payload.data'),
                }, () => {
                    if (!onCreditStationarySelect) {
                        this.props.formikProps.setFieldValue('creditStationaryId', '');
                        this.props.formikProps.setFieldValue('creditStationaryNumber', '');
                    }
                    else {
                        onCreditStationarySelect();
                    }
                });
            });
        }
    };
    fetchDebitStationaries = (branch, debitBookType, onDebitStationarySelect) => {
        if (isViewStationaryAllowedForUser(this.props.login.scope)) {
            this.props.fetchStationaries(this.props.accesstoken, branch, debitBookType).then((res) => {
                this.setState({
                    allDebitStationaries: get(res, 'payload.data'),
                }, () => {
                    if (!onDebitStationarySelect) {
                        this.props.formikProps.setFieldValue('debitStationaryId', '');
                        this.props.formikProps.setFieldValue('debitStationaryNumber', '');
                    }
                    else {
                        onDebitStationarySelect();
                    }
                });
            });
        }
    };
    onCreditStationarySelect = (val, branch, creditBookType, formikProps) => {
        if (val) {
            formikProps.setFieldValue('creditStationaryId', val);
            const stationary = filter(this.state.allCreditStationaries, (stationary) => stationary.id == val);
            const stationaryNature = get(stationary, '[0].nature');
            this.setState({
                stationaryNature,
            }, () => {
                if (this.state.stationaryNature == 'AUTO' &&
                    branch &&
                    creditBookType &&
                    !formikProps.values.creditStationaryNumber) {
                    if (this.initalCreditStationaryId != val) {
                        this.props
                            .getNextStationary(this.props.accesstoken, branch, creditBookType, get(stationary, '[0].bookName'))
                            .then((res) => {
                            const nextStationaryNumber = get(res, 'payload.data.[0].nextStationaryNumber', '');
                            if (!nextStationaryNumber) {
                                alert('Book name not valid or disabled !!');
                                formikProps.setFieldValue('creditStationaryId', '');
                            }
                            formikProps.setFieldValue('creditStationaryNumber', nextStationaryNumber);
                            this.props.onCreditStationaryNumberPopulate &&
                                this.props.onCreditStationaryNumberPopulate(nextStationaryNumber);
                        });
                    }
                    else {
                        formikProps.setFieldValue('creditStationaryNumber', this.initalCreditStationaryNumber);
                        this.props.onCreditStationaryNumberPopulate &&
                            this.props.onCreditStationaryNumberPopulate(this.initalCreditStationaryNumber);
                    }
                }
                else if (includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature) &&
                    !formikProps.values.creditStationaryNumber) {
                    if (this.initalCreditStationaryId != val) {
                        formikProps.setFieldValue('creditStationaryNumber', `${get(stationary, '[0].code')}-`);
                    }
                    else {
                        formikProps.setFieldValue('creditStationaryNumber', this.initalCreditStationaryNumber);
                        this.props.onCreditStationaryNumberPopulate &&
                            this.props.onCreditStationaryNumberPopulate(this.initalCreditStationaryNumber);
                    }
                }
            });
        }
    };
    onDebitStationarySelect = (val, branch, debitBookType, formikProps) => {
        if (val) {
            formikProps.setFieldValue('debitStationaryId', val);
            const stationary = filter(this.state.allDebitStationaries, (stationary) => stationary.id == val);
            const stationaryNature = get(stationary, '[0].nature');
            this.setState({
                stationaryNature,
            }, () => {
                if (this.state.stationaryNature == 'AUTO' &&
                    branch &&
                    debitBookType &&
                    !formikProps.values.debitStationaryNumber) {
                    if (this.initalDebitStationaryId != val) {
                        this.props
                            .getNextStationary(this.props.accesstoken, branch, debitBookType, get(stationary, '[0].bookName'))
                            .then((res) => {
                            const nextStationaryNumber = get(res, 'payload.data.[0].nextStationaryNumber', '');
                            if (!nextStationaryNumber) {
                                alert('Book name not valid or disabled !!');
                                formikProps.setFieldValue('creditStationaryId', '');
                            }
                            formikProps.setFieldValue('debitStationaryNumber', nextStationaryNumber);
                            this.props.onDebitStationaryNumberPopulate &&
                                this.props.onDebitStationaryNumberPopulate(nextStationaryNumber);
                        });
                    }
                    else {
                        formikProps.setFieldValue('debitStationaryNumber', this.initalDebitStationaryNumber);
                        this.props.onDebitStationaryNumberPopulate &&
                            this.props.onDebitStationaryNumberPopulate(this.initalDebitStationaryNumber);
                    }
                }
                else if (includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature) &&
                    !formikProps.values.debitStationaryNumber) {
                    if (this.initalDebitStationaryId != val) {
                        formikProps.setFieldValue('debitStationaryNumber', `${get(stationary, '[0].code')}-`);
                    }
                    else {
                        formikProps.setFieldValue('debitStationaryNumber', this.initalDebitStationaryNumber);
                        this.props.onDebitStationaryNumberPopulate &&
                            this.props.onDebitStationaryNumberPopulate(this.initalDebitStationaryNumber);
                    }
                }
            });
        }
    };
    validateCreditBillNumber = (creditStationaryNumber, creditStationaryId, setFieldError) => {
        if (creditStationaryNumber && creditStationaryId) {
            this.props
                .validateStationary(this.props.accesstoken, creditStationaryId, creditStationaryNumber)
                .then((res) => {
                if (!get(res, 'payload.data')) {
                    setFieldError('creditStationaryNumber', 'Invalid Stationary Number');
                }
                else {
                    this.props.onCreditStationaryNumberPopulate &&
                        this.props.onCreditStationaryNumberPopulate(creditStationaryNumber);
                }
            })
                .catch((e) => { });
        }
    };
    validateDebitBillNumber = (debitStationaryNumber, debitStationaryId, setFieldError) => {
        if (debitStationaryNumber && debitStationaryId) {
            this.props
                .validateStationary(this.props.accesstoken, debitStationaryId, debitStationaryNumber)
                .then((res) => {
                if (!get(res, 'payload.data')) {
                    setFieldError('debitStationaryNumber', 'Invalid Stationary Number');
                }
                else {
                    this.props.onDebitStationaryNumberPopulate &&
                        this.props.onDebitStationaryNumberPopulate(debitStationaryNumber);
                }
            })
                .catch((e) => { });
        }
    };
    wrapper = (children) => {
        const { removeDivWrapper = false } = this.props;
        if (!removeDivWrapper) {
            return _jsx(FxRow, { gutter: 16, children: children });
        }
        else {
            return _jsx(_Fragment, { children: children });
        }
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
    render() {
        const { branch, creditBookType, creditStationaryId, debitBookType, debitStationaryId, } = this.props.formikProps.values;
        const { colClass = 8 } = this.props;
        return this.wrapper(_jsxs(_Fragment, { children: [isViewBranchAllowedForUser(this.props.login.scope) && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: this.state.isBranchesLoading || !this.props.isReadyToFetchBillNumber ? (_jsx(FxSkeleton, { width: "100%", height: "40px", className: "mt-4", children: _jsx("div", {}) })) : (_jsx(Field, { size: this.props.size, name: "branch", options: mappedBranches(this.state.allBranches), component: FxSelectField, onChange: (val) => {
                            this.props.onBranchSelect &&
                                this.props.onBranchSelect(val, find(this.state.allBranches, { id: val }));
                            this.fetchCreditStationaries(val, creditBookType, null);
                            this.fetchDebitStationaries(val, debitBookType, null);
                        }, isRequired: this.props.branchRequired, disabled: get(this.props, 'fieldDisabled.branch', false), allowClear: true, label: get(this.props, 'fieldLabel.branch', 'Select Branch'), "data-testid": "branch-field" })) })), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditBookType", options: getMappedBookTypes(), component: FxSelectField, 
                        // onSelected={(val) => this.fetchStationaries(branch, val, null, true)}
                        disabled: true, label: 'Credit Book Type', "data-testid": "book-type-field" }) }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitBookType", options: getMappedBookTypes(), component: FxSelectField, 
                        // onSelected={(val) => this.fetchStationaries(branch, val, null, true)}
                        disabled: true, label: 'Debit Book Type', "data-testid": "book-type-field" }) }), branch && creditBookType && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditStationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.state.allCreditStationaries), component: FxSelectField, onChange: (val) => {
                            if (this.props.onCreditStationarySelectCb) {
                                this.props.onCreditStationarySelectCb(val);
                            }
                            this.onCreditStationarySelect(val, branch, creditBookType, this.props.formikProps);
                        }, label: 'Select Credit Book Name', "data-testid": "book-name-field" }) })), branch && debitBookType && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitStationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.state.allDebitStationaries), component: FxSelectField, onChange: (val) => {
                            if (this.props.onDebitStationarySelectCb) {
                                this.props.onDebitStationarySelectCb(val);
                            }
                            this.onDebitStationarySelect(val, branch, debitBookType, this.props.formikProps);
                        }, label: 'Select Debit Book Name', "data-testid": "book-name-field" }) })), branch && creditBookType && creditStationaryId && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditStationaryNumber", type: "text", component: FxTextFieldStackedLabelV2, disabled: this.state.stationaryNature == 'AUTO' ||
                            get(this.props, 'fieldDisabled.creditStationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                            ? (creditStationaryNumber) => this.validateCreditBillNumber(creditStationaryNumber, creditStationaryId, this.props.formikProps.setFieldError)
                            : () => { }, isRequired: !!creditStationaryId, label: get(this.props, 'fieldLabel.creditStationaryNumber', 'Credit Stationary Number'), "data-testid": "stationary-number-field" }) })), branch && debitBookType && debitStationaryId && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitStationaryNumber", type: "text", component: FxTextFieldStackedLabelV2, disabled: this.state.stationaryNature == 'AUTO' ||
                            get(this.props, 'fieldDisabled.debitStationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                            ? (debitStationaryNumber) => this.validateDebitBillNumber(debitStationaryNumber, debitStationaryId, this.props.formikProps.setFieldError)
                            : () => { }, isRequired: !!debitStationaryId, label: get(this.props, 'fieldLabel.debitStationaryNumber', 'Debit Stationary Number'), "data-testid": "stationary-number-field" }) }))] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStationaries,
        getNextStationary,
        validateStationary,
        fetchBranches,
        fetchStationary,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentBillComponent);
