import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { FxButton, FxFlex, FxImage, FxTypography } from 'sharedV2';
import { useNavigate } from 'react-router';
import { image, isMobileView } from 'constant';
const NotFoundComponent = (props) => {
    const {} = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsx(FxFlex, { justify: "center", align: "center", className: "py-5", style: {
            height: isMobileView() ? '90%' : 'calc(100vh - 47px)',
            marginLeft: '-25px',
            marginRight: '-25px',
            background: 'linear-gradient(to bottom, transparent 80%, rgba(0, 120, 212, 0.2) 100%)',
        }, children: _jsxs(FxFlex, { vertical: true, children: [_jsx(FxImage, { src: image('/public/img/errorPage/404.svg'), preview: false, alt: "404-error-icon", className: "d-flex justify-content-center", style: { marginBottom: '3rem', height: '120px' } }), _jsx(FxImage, { src: image('/public/img/errorPage/truck.svg'), preview: false, alt: "truck-icon", className: "d-flex justify-content-center", style: { height: '200px' } }), _jsxs(FxFlex, { vertical: true, children: [_jsxs(FxTypography.Paragraph, { className: 'text-center mb-4', children: ["Looks like you have reached a bump on your path.", _jsx("br", {}), "You can check the link again or go back to homepage"] }), _jsx(FxButton, { onClick: () => navigate(-1), style: { alignSelf: 'center', width: 'auto' }, type: "primary", children: t('goBack') })] })] }) }));
};
export default NotFoundComponent;
