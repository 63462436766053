import { getMappedBookTypes, mappedBranches, mappedOptionsFromArray, mappedStationaries, } from '../components/utils/mapping_utils';
import * as Yup from 'yup';
import { filter, get, includes, isArray, isEmpty } from 'lodash';
import { mappedExpenseType } from 'utils/form_utils';
// ACCOUNT GROUPS
export const BASE_LEDGER_GROUP_IDS = {
    DIRECT_INCOMES: 16,
    TAX_RECEIVABLE: 11,
    LOANS_AND_ADVANCES: 4,
    DEPOSITS: 8,
    SUNDRY_DEBTORS: 10,
    DIRECT_EXPENSES: 12,
    TAX_PAYABLE: 37,
};
export const ERP_PROFILE_FORM_TYPES = {
    JOB_ERP_PROFILE: 'JOB_ERP_PROFILE',
    FREIGHT_BILL_ERP_PROFILE: 'FREIGHT_BILL_ERP_PROFILE',
    SUPPLEMENTARY_BILL_ERP_PROFILE: 'SUPPLEMENTARY_BILL_ERP_PROFILE',
    NON_SUPPLEMENTARY_BILL_ERP_PROFILE: 'NON_SUPPLEMENTARY_BILL_ERP_PROFILE',
    GST_LEDGER_CONFIGURATION: 'GST_LEDGER_CONFIGURATION',
    CN_ADVANCE_LEDGER: 'CN_ADVANCE_LEDGER',
    OTHER_LEDGERS: 'OTHER_LEDGERS',
    TRANSACTIONS: 'TRANSACTIONS',
    CHALLAN_ERP_PROFILE: 'CHALLAN_ERP_PROFILE',
    VOUCHERS_ERP_PROFILE: 'VOUCHERS_ERP_PROFILE',
    DRIVER_ADVANCE: 'DRIVER_ADVANCE',
    WORK_ORDER: 'WORK_ORDER',
    TRIP_SETTLEMENT: 'TRIP_SETTLEMENT',
    TYRE_THEFT: 'TYRE_THEFT',
    TYRE_SCRAP: 'TYRE_SCRAP',
    PROCUREMENT_RATE_CHART: 'PROCUREMENT_RATE_CHART',
    HIRE_SLIP_ERP_PROFILE: 'HIRE_SLIP_ERP_PROFILE',
    BATTERY_THEFT: 'BATTERY_THEFT',
    BATTERY_SCRAP: 'BATTERY_SCRAP',
};
export const ERP_PROFILE_FORM_TYPES_LABELS = {
    JOB_ERP_PROFILE: 'Job Form',
    FREIGHT_BILL_ERP_PROFILE: 'Freight Bill',
    SUPPLEMENTARY_BILL_ERP_PROFILE: 'Supplementary Bill',
    NON_SUPPLEMENTARY_BILL_ERP_PROFILE: 'Non Supplementary Bill',
    GST_LEDGER_CONFIGURATION: 'GST Ledger',
    CN_ADVANCE_LEDGER: 'Consignment Advance Ledger',
    OTHER_LEDGERS: 'Other Ledger',
    TRANSACTIONS: 'Transactions',
    CHALLAN_ERP_PROFILE: 'Challan Form',
    VOUCHERS_ERP_PROFILE: 'Voucher Form',
    DRIVER_ADVANCE: 'Driver Advance Form',
    WORK_ORDER: 'Work Order Form',
    TRIP_SETTLEMENT: 'Trip Settlement Form',
    TYRE_THEFT: 'Tyre Theft Form',
    TYRE_SCRAP: 'Tyre Scrap Form',
    PROCUREMENT_RATE_CHART: 'Procurement Rate Chart',
    HIRE_SLIP_ERP_PROFILE: 'Hire Slip Form',
    BATTERY_THEFT: 'Battery Theft Form',
    BATTERY_SCRAP: 'Battery Scrap Form',
};
export const branchFieldConfig = {
    name: 'branchId',
    label: 'Branch',
    type: 'dropdown',
    dropdownOptionsGetter: (props, state, values) => {
        return mappedBranches(state.branches);
    },
};
export const bookTypeFieldConfig = {
    name: 'bookType',
    label: 'Book Type',
    type: 'dropdown',
    dropdownOptionsGetter: (propsIProps, state, formikProps) => {
        return getMappedBookTypes();
    },
    originalProps: {
        disabled: true,
    },
};
export const stationarySubFieldConfig = [
    branchFieldConfig,
    bookTypeFieldConfig,
    {
        name: 'stationaryId',
        label: 'Stationary',
        type: 'dropdown',
        dropdownOptionsGetter: (props, state, formikProps) => {
            return mappedStationaries(state.stationaries);
        },
    },
];
export const getFilteredAndMappedLedgerBasedOnBaseGroup = (ledgers, baseGroupIds) => {
    const baseGroupIdsLocal = !baseGroupIds || isArray(baseGroupIds) ? baseGroupIds : [baseGroupIds];
    if (!isEmpty(baseGroupIdsLocal)) {
        return mappedOptionsFromArray(filter(ledgers, (l) => includes(baseGroupIdsLocal, get(l, 'group.baseGroupId'))), 'ledgerName');
    }
    else {
        return mappedOptionsFromArray(ledgers, 'ledgerName');
    }
};
export function getERPProfileFormSetting(formType) {
    let config = [];
    let initialValues = { formType };
    switch (formType) {
        case ERP_PROFILE_FORM_TYPES.JOB_ERP_PROFILE:
            initialValues = {
                ...initialValues,
                bookType: 'JOB_NUMBER',
            };
            config = [...stationarySubFieldConfig];
            break;
        case ERP_PROFILE_FORM_TYPES.CHALLAN_ERP_PROFILE:
            initialValues = {
                ...initialValues,
                bookType: 'CHALLAN_FORM',
            };
            config = [...stationarySubFieldConfig];
            break;
        case ERP_PROFILE_FORM_TYPES.VOUCHERS_ERP_PROFILE:
            initialValues = {
                ...initialValues,
                bookType: 'VOUCHER',
            };
            config = [...stationarySubFieldConfig];
            break;
        case ERP_PROFILE_FORM_TYPES.FREIGHT_BILL_ERP_PROFILE:
        case ERP_PROFILE_FORM_TYPES.SUPPLEMENTARY_BILL_ERP_PROFILE:
        case ERP_PROFILE_FORM_TYPES.NON_SUPPLEMENTARY_BILL_ERP_PROFILE:
            config = [
                {
                    name: 'salesLedgerId',
                    label: 'Sales Ledger',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.DIRECT_INCOMES);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.DRIVER_ADVANCE:
            config = [
                {
                    name: 'tripAdvanceLedgerId',
                    label: 'Trip Advance Account',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.WORK_ORDER:
            config = [
                {
                    name: 'repairMaintenanceLedgerId',
                    label: 'Repair Maintenance Account',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.TRIP_SETTLEMENT:
            config = [
                {
                    name: 'driverPoolLedgerId',
                    label: 'Driver Pool Account',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.TYRE_THEFT:
        case ERP_PROFILE_FORM_TYPES.TYRE_SCRAP:
        case ERP_PROFILE_FORM_TYPES.BATTERY_THEFT:
        case ERP_PROFILE_FORM_TYPES.BATTERY_SCRAP:
            config = [
                {
                    name: 'debitLedgerId',
                    label: 'Debit Ledger',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.GST_LEDGER_CONFIGURATION:
            config = [
                {
                    name: 'gstLedgerInput.igstLedgerId',
                    label: 'IGST Ledger Input',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_RECEIVABLE);
                    },
                },
                {
                    name: 'gstLedgerInput.cgstLedgerId',
                    label: 'CGST Ledger Input',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_RECEIVABLE);
                    },
                },
                {
                    name: 'gstLedgerInput.sgstLedgerId',
                    label: 'SGST Ledger Input',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_RECEIVABLE);
                    },
                },
                {
                    name: 'gstLedgerOutput.igstLedgerId',
                    label: 'IGST Ledger Output',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_PAYABLE);
                    },
                },
                {
                    name: 'gstLedgerOutput.cgstLedgerId',
                    label: 'CGST Ledger Output',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_PAYABLE);
                    },
                },
                {
                    name: 'gstLedgerOutput.sgstLedgerId',
                    label: 'SGST Ledger Output',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.TAX_PAYABLE);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.CN_ADVANCE_LEDGER:
            config = [
                {
                    name: 'debitLedgerId',
                    label: 'Debit Ledger',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.LOANS_AND_ADVANCES);
                    },
                },
                {
                    name: 'creditLedgerId',
                    label: 'Credit Ledger',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, [
                            BASE_LEDGER_GROUP_IDS.DEPOSITS,
                            BASE_LEDGER_GROUP_IDS.SUNDRY_DEBTORS,
                        ]);
                    },
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.TRANSACTIONS:
            config = [
                {
                    name: 'transactions',
                    label: 'Transactions',
                    type: 'fieldArray',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedExpenseType();
                    },
                    subFields: [
                        {
                            name: 'expenseType',
                            label: 'Expense Type',
                            type: 'dropdown',
                            dropdownOptionsGetter: (props, state, formikProps) => {
                                return mappedExpenseType();
                            },
                        },
                        {
                            name: 'debitLedgerId',
                            label: 'Debit Ledger',
                            type: 'dropdown',
                            dropdownOptionsGetter: (props, state, formikProps) => {
                                return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                            },
                        },
                        {
                            name: 'creditLedgerId',
                            label: 'Credit Ledger',
                            type: 'dropdown',
                            dropdownOptionsGetter: (props, state, formikProps) => {
                                return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, []);
                            },
                        },
                    ],
                },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.OTHER_LEDGERS:
            config = [
                {
                    name: 'discountLedgerId',
                    label: 'Discount Ledger',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return getFilteredAndMappedLedgerBasedOnBaseGroup(props.ledgers, BASE_LEDGER_GROUP_IDS.DIRECT_EXPENSES);
                    },
                },
                // {
                //     name: 'roundOffLedgerId',
                //     label: 'Round Off Ledger',
                //     type: 'dropdown',
                //     dropdownOptionsGetter: (props, state, formikProps) => {
                //         return getFilteredAndMappedLedgerBasedOnBaseGroup(
                //             props.ledgers,
                //             BASE_LEDGER_GROUP_IDS.DIRECT_EXPENSES
                //         );
                //     },
                // },
            ];
            break;
        case ERP_PROFILE_FORM_TYPES.PROCUREMENT_RATE_CHART:
            initialValues = {
                ...initialValues,
                bookType: 'PROCUREMENT_CHART',
            };
            config = [...stationarySubFieldConfig];
            break;
        case ERP_PROFILE_FORM_TYPES.HIRE_SLIP_ERP_PROFILE:
            config = [
                {
                    name: 'ledgerSection.hireChargesEntry.ledgerId',
                    label: 'Hire Charges A/c',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'ledgerSection.extraChargesEntry.ledgerId',
                    label: 'Extra Charges A/c',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'ledgerSection.tdsEntry.ledgerId',
                    label: 'TDS A/c',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'hsPumpCashAdv.creditLedgerId',
                    label: 'HS Pump Cash Advance Credit Entry',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'hsFuelAdv.creditLedgerId',
                    label: 'HS Fuel Advance Credit Entry',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'hsCashAdv.creditLedgerId',
                    label: 'HS Cash Advance Credit Entry',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.detentionChargesEntry',
                    label: 'Detention Charges',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.unloadingChargesEntry',
                    label: 'Unloading Charges',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.otherChargesEntry',
                    label: 'Other Charges',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.penaltyChargesEntry',
                    label: 'Penalty Charges',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.claimChargesEntry',
                    label: 'Claim Charges',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.otherDeductionsEntry',
                    label: 'Other Deductions',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
                {
                    name: 'detentionLedger.tdsAccountEntry',
                    label: 'TDS Account',
                    type: 'dropdown',
                    dropdownOptionsGetter: (props, state, formikProps) => {
                        return mappedOptionsFromArray(props.ledgers, 'ledgerName');
                    },
                },
            ];
            break;
        default:
    }
    return {
        config,
        initialValues,
    };
}
export function getValidationSchema(formType) {
    return Yup.object().shape({
        formType: Yup.string().required('Please select form type.').nullable(),
    });
}
