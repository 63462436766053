import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import LedgerDetailsComponent from 'components/dashboard/ledger_details/ledger_details_component';
const DetentionAndDeductionLedgersComponent = (props) => {
    const { accesstoken } = props;
    const { t } = useTranslation();
    const ledgerDetailFields = [
        {
            name: 'ledgerDetails.claimChargesEntry.ledgerId',
            label: 'Claim ledger Entry',
        },
        {
            name: 'ledgerDetails.detentionChargesEntry.ledgerId',
            label: 'Detention ledger Entry',
        },
        {
            name: 'ledgerDetails.otherChargesEntry.ledgerId',
            label: 'Other Charges Entry',
        },
        {
            name: 'ledgerDetails.otherDeductionsEntry.ledgerId',
            label: 'Other Deductions Entry',
        },
        {
            name: 'ledgerDetails.penaltyChargesEntry.ledgerId',
            label: 'Penalty Charges Entry',
        },
        {
            name: 'ledgerDetails.unloadingChargesEntry.ledgerId',
            label: 'Unloading Charges Entry',
        },
    ];
    return (_jsx("div", { children: _jsx(LedgerDetailsComponent, { ledgerDetailsFields: ledgerDetailFields }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DetentionAndDeductionLedgersComponent);
