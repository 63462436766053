import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map, toSafeInteger, includes, find, toNumber, uniqBy } from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Input } from 'reactstrap';
import CalendarComponent from '../../../utils/calendar_component';
import { mappedRoutes, mappedConsignee, mappedConsignors } from '../../../../utils/form_utils';
import SelectRoute from '../../../dashboard/route_dispatch/route/select_route';
import { FxReactSelect as Select, FxTextSearchField } from '../../../../shared';
const ConsignmentsListingComponent = (props) => {
    const { data, formikProps, materialsHashMap, setMaterialsHashMap, routeList, customers, isEdit, isLorryArrival, selectedChallans, setSelectedChallans, challanFilters, setChallanFilters, currentPage, totalDataSize, setSelectedChallanData, selectedChallanData, onRowSelect, refs, } = props;
    const { startDate, endDate, routeId, consignmentConsignorId, consignmentConsigneeId, consignmentNumber, } = challanFilters;
    const [modifiedData, setModifiedData] = React.useState(data);
    useEffect(() => {
        if (isEdit) {
            setModifiedData(data);
            let newSelectedChallans = [];
            let newSelectedChallansData = [];
            map(materialsHashMap, (item) => {
                if (!includes(newSelectedChallans, item.challanId)) {
                    newSelectedChallansData.push(item);
                    newSelectedChallans.push(item.challanId);
                }
            });
            setSelectedChallanData(newSelectedChallansData);
            setSelectedChallans(newSelectedChallans);
        }
        else {
            setModifiedData(uniqBy([...selectedChallanData, ...data], 'id'));
        }
    }, [data]);
    /**
     * It takes in the following parameters:
     *
     * - e: The event object.
     * - key: The key of the material.
     * - balanceQuantity: The remaining quantity of the material.
     * - parentId: The id of the parent challan.
     * - consignmentMaterialId: The id of the material.
     * - consignmentNumber: The consignment number of the material
     * @param e - The event object.
     * @param key - The key of the material in the materialsHashMap.
     * @param balanceQuantity - The remaining quantity of the material in the consignment.
     * @param parentId - The id of the parent challan.
     * @param consignmentMaterialId - The id of the consignment material.
     * @param consignmentNumber - The consignment number of the consignment material.
     * @returns Nothing.
     */
    const handleInputChange = (e, key, parentId, row, inputKey, type) => {
        let balanceQuantity = get(row, 'dispatchQuantity', '');
        let consignmentMaterialId = get(row, 'consignmentMaterialId', '');
        let consignmentNumber = get(row, 'consignmentMaterial.consignment.consignmentNumber', '-');
        let materialName = get(row, 'consignmentMaterial.material.materialName', '-');
        if (e.target.value && toSafeInteger(e.target.value) <= 0 && type === 'number') {
            refs.current[`${parentId}-${consignmentMaterialId}-${inputKey}`].value = '';
            alert('Please enter a valid number.');
            return false;
        }
        if (e.target.value > balanceQuantity && includes(['materialDamaged', 'materialMisplaced'], inputKey)) {
            alert("Quantity can't be greater than dispatch quantity.");
            refs.current[`${parentId}-${consignmentMaterialId}-${inputKey}`].value = '';
            return false;
        }
        let newMaterialsHashMap = materialsHashMap;
        if (newMaterialsHashMap[`${parentId}-${consignmentMaterialId}`]) {
            newMaterialsHashMap[`${parentId}-${consignmentMaterialId}`][inputKey] =
                type === 'text' ? e.target.value : toNumber(e.target.value);
        }
        else {
            newMaterialsHashMap[`${parentId}-${consignmentMaterialId}`] = {
                challanId: parentId,
                consignmentNumber,
                materialName,
                consignmentMaterialId: consignmentMaterialId,
                [inputKey]: type === 'text' ? e.target.value : toNumber(e.target.value),
            };
        }
        console.log('newMaterialsHashMap', newMaterialsHashMap);
        setMaterialsHashMap({ ...newMaterialsHashMap });
    };
    /**
     * This function is used to render the quantity field in the table
     * @param cell - The cell value of the row.
     * @param row - The current row data.
     * @param formatExtraData - {
     * @param rowIndex - The index of the row in the table.
     * @returns The material that is being returned is the material that is being dispatched.
     */
    const renderInputField = (cell, row, formatExtraData, rowIndex) => {
        const { parentId, dataField, inputKey, placeholder, type = 'number' } = formatExtraData;
        const { values } = dataField;
        const key = cell;
        let consignmentMaterialId = get(row, 'consignmentMaterialId', '');
        return (_jsx(Input, { type: type, onClick: (e) => {
                e.stopPropagation();
            }, step: "any", onChange: (e) => handleInputChange(e, key, parentId, row, inputKey, type), min: 0, placeholder: placeholder, style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: get(materialsHashMap, `${parentId}-${consignmentMaterialId}.${inputKey}`, ''), disabled: !includes(selectedChallans, parentId), innerRef: (element) => {
                refs.current[`${parentId}-${consignmentMaterialId}-${inputKey}`] = element;
            } }, `${parentId}-${consignmentMaterialId}-${inputKey}`));
    };
    const isExpandableRow = (row) => {
        return true;
    };
    const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
        let content = _jsx("span", {});
        if (isExpandableRow) {
            content = _jsx("i", { className: `fa fa-chevron-${isExpanded ? 'down' : 'right'}`, "aria-hidden": "true" });
        }
        return content;
    };
    const renderCol = (cell, row, formatExtraData) => {
        const { dataField, isHyperLinked = false } = formatExtraData;
        if (isHyperLinked) {
            return (_jsx("a", { target: "_blank", href: `/dashboard/consignments/edit/${row.id}`, children: get(row, dataField, '-') }));
        }
        return get(row, dataField, '-');
    };
    const expandComponent = (row) => {
        return (_jsx(_Fragment, { children: _jsx("div", { className: "card fleetx-table-parent-container", children: _jsxs(BootstrapTable, { containerClass: "white-background", tableContainerClass: "fleetx-table-container grey-table-background", tableHeaderClass: "fleetx-table inner-table white", tableBodyClass: "fleetx-table inner-table white", trClassName: "tr-user-height table-row-style cursor-pointer", version: "4", hover: true, remote: true, data: get(row, 'materials', []), pagination: false, options: {
                        hideSizePerPage: true,
                        noDataText: 'No Data.',
                    }, children: [_jsx(TableHeaderColumn, { columnClassName: "td-column-center", width: "220", dataField: "consignmentMaterial.consignment.consignmentNumber", formatExtraData: {
                                dataField: 'consignmentMaterial.consignment.consignmentNumber',
                                isHyperLinked: true,
                            }, dataFormat: renderCol, isKey: true, children: "Consignment No." }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "consignmentMaterial.consignment.ewayBill", width: "200", formatExtraData: { dataField: 'consignmentMaterial.consignment.ewayBill' }, dataFormat: renderCol, children: "Eway Bill" }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "consignmentMaterial.consignment.consignmentDate", formatExtraData: { dataField: 'consignmentMaterial.consignment.consignmentDate' }, dataFormat: renderCol, width: "200", children: "CN Date" }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "consignmentMaterial.consignment.consignorDetails.name", formatExtraData: { dataField: 'consignmentMaterial.consignment.consignorDetails.name' }, width: "200", dataFormat: renderCol, children: "Consigner" }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "consignmentMaterial.material.materialName", formatExtraData: { dataField: 'consignmentMaterial.material.materialName' }, width: "200", dataFormat: renderCol, children: "Material Name" }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "dispatchQuantity", width: "200", children: "Material Dispatch" }), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "materialReceived", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'materialReceived',
                                placeholder: 'Enter Quatity',
                            }, children: "Material Recieved" }), isLorryArrival && (_jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "materialDamaged", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'materialDamaged',
                                placeholder: 'Enter Quatity',
                            }, children: "Material Damaged" })), isLorryArrival && (_jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "damagedValue", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'damagedValue',
                                placeholder: 'Enter Amount',
                            }, children: "Damaged Value" })), isLorryArrival && (_jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "materialMisplaced", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'materialMisplaced',
                                placeholder: 'Enter Quatity',
                            }, children: "Material Misplaced" })), isLorryArrival && (_jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "misplacedValue", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'misplacedValue',
                                placeholder: 'Enter Amount',
                            }, children: "Misplaced Value" })), _jsx(TableHeaderColumn, { columnClassName: "td-column-center", dataField: "remark", width: "200", dataFormat: renderInputField, formatExtraData: {
                                dataField: formikProps,
                                parentId: get(row, 'id', ''),
                                inputKey: 'remark',
                                placeholder: 'Enter Remark',
                                type: 'text',
                            }, children: "Remark" })] }) }) }));
    };
    const renderRoute = (id) => {
        return get(find(routeList, { id }), 'name', '');
    };
    const renderChallanNumber = (cell, row) => (_jsx("a", { target: "_blank", href: `/dashboard/challans/edit/${row.id}`, children: cell }));
    const options = {
        noDataText: 'No entry found.',
        paginationPosition: 'both',
        hideSizePerPage: true,
        sizePerPage: 10,
        page: currentPage,
        alwaysShowAllBtns: true,
        onPageChange: props.onPageChange,
        expandRowBgColor: '#f0f3f5',
    };
    const selectRowProp = {
        mode: isEdit ? 'none' : 'checkbox',
        // clickToSelect: true,
        clickToExpand: true,
        // bgColor: 'rgba(211,255,211)',
        bgColor: '#c1ffef',
        onSelect: onRowSelect,
        selected: selectedChallans,
    };
    const handleChallansFilter = (val) => {
        setChallanFilters({ ...challanFilters, ...val });
    };
    return (_jsxs("div", { className: "d-flex flex-column", children: [!isEdit && (_jsx("div", { children: _jsxs("div", { className: "pull-right", children: [isLorryArrival && (_jsx("div", { className: `pull-left mr-2 mb-2 }`, "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "CN-Number-Select CN Number", style: { width: '200px' }, children: _jsx(FxTextSearchField, { name: "consignmentNumber", placeholder: "CN Number", defaultValue: consignmentNumber, handleTextFilter: handleChallansFilter }) })), _jsx("div", { className: `pull-left mr-2 mb-2 }`, "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "Routes-Select Route", style: { width: '200px' }, children: _jsx(SelectRoute, { handleRouteSelectChange: (routeId) => handleChallansFilter({ routeId }), value: routeId, options: mappedRoutes(routeList) }) }), isLorryArrival && (_jsx("div", { className: `pull-left mr-2 mb-2 }`, "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "Consignor-Select Consignor", style: { width: '200px' }, children: _jsx(Select, { simpleValue: true, placeholder: "Consignor", value: consignmentConsignorId, options: mappedConsignors(customers), onChange: (value) => {
                                    handleChallansFilter({ consignmentConsignorId: value });
                                } }) })), isLorryArrival && (_jsx("div", { className: `pull-left mr-2 mb-2 }`, "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "Consignee-Select Consignee", style: { width: '200px' }, children: _jsx(Select, { simpleValue: true, placeholder: "Consignee", value: consignmentConsigneeId, options: mappedConsignee(customers), onChange: (value) => {
                                    handleChallansFilter({ consignmentConsigneeId: value });
                                } }) })), _jsx("div", { className: `calendar-component  pull-left ml-2 mb-2`, "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "Date Range", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                    handleChallansFilter({ startDate, endDate });
                                }, startDate: startDate, endDate: endDate }) })] }) })), _jsx("div", { className: "mt-4 fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background erp-table-listing", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", hover: true, data: modifiedData, options: options, remote: true, pagination: true, version: "4", expandableRow: isExpandableRow, expandComponent: expandComponent, expandColumnOptions: {
                        expandColumnVisible: true,
                        expandColumnComponent: expandColumnComponent,
                        columnWidth: 25,
                    }, selectRow: selectRowProp, fetchInfo: { dataTotalSize: totalDataSize }, children: [_jsx(TableHeaderColumn, { width: "5", dataField: "id", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                                whiteSpace: 'normal',
                            }, isKey: true }), _jsx(TableHeaderColumn, { width: "180", dataField: "challanNumber", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                                whiteSpace: 'normal',
                            }, dataFormat: renderChallanNumber, children: "Challan Number" }), _jsx(TableHeaderColumn, { width: "180", dataField: "date", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                                whiteSpace: 'normal',
                            }, children: "Challan Date" }), _jsx(TableHeaderColumn, { width: "180", dataField: "routeId", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                                whiteSpace: 'normal',
                            }, dataFormat: renderRoute, children: "Route" }), _jsx(TableHeaderColumn, { width: "130", dataField: "type", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                                whiteSpace: 'normal',
                            }, children: "Challan Type" })] }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsignmentsListingComponent);
