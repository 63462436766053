import { SET_CURRENT_CURSOR, SET_SINGLE_UPLOAD_LOADING, SHOW_GLOBAL_SEARCH } from 'actions/dashboard/utils_action';

const initialState = {
    isLoading: false,
    cursorData: '',
    showSearch: false,
};
export default function (state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case SET_SINGLE_UPLOAD_LOADING:
            return {
                ...state,
                isLoading: data,
            };
        case SET_CURRENT_CURSOR:
            return {
                ...state,
                cursorData: data,
            };
        case SHOW_GLOBAL_SEARCH:
            return {
                ...state,
                showSearch: data,
            };
        default:
            return state;
    }
}
