import {
    filter,
    find,
    floor,
    forEach,
    get,
    includes,
    isArray,
    isEmpty,
    keys,
    map,
    round,
    set,
    toSafeInteger,
    toUpper,
    transform,
} from 'lodash';
import { getSep, getVehicleNumberDigits } from './vehicle_utils';
import { getDeviceTypeFromDeviceId, getDeviceTypeFromDeviceTypeString, numberToLocaleString } from '../constant';
import { FLEETX_ACCOUNTS } from './account_utils';
import moment from 'moment-timezone';
import { getHumanizeTime, getMomentTime, getTimeDiffHours } from 'utils/date_utils';
import { FxToolTip } from '../sharedV2';
import { FxInfoCircleOutlined, FxWarningOutlined } from '../sharedV2/FxIcons';
import { FxTypography } from 'sharedV2';

export const AVERAGE_FUEL_COST = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.AERIS:
        case FLEETX_ACCOUNTS.AERIS2:
        case FLEETX_ACCOUNTS.AERIS3:
            return {
                PETROL: {
                    value: 9125,
                },
                DIESEL: {
                    value: 9850,
                },
                CNG: {
                    value: 0,
                },
            };
        default:
            return {
                PETROL: {
                    value: 103.56,
                },
                DIESEL: {
                    value: 93.68,
                },
                CNG: {
                    value: 50.95,
                },
            };
    }
};

export const getFuelAverageCost = (fuelType, loggedInUser) => {
    const accountId = get(loggedInUser, 'accountId', 0);
    const data = AVERAGE_FUEL_COST(accountId);
    return {
        lastAverageCost: data && data[toUpper(fuelType)] && data[toUpper(fuelType)].value,
    };
};

export const getFuelEfficeiencyUnit = (loggedInUser, getVolumeAsLabel = false, defaultValue) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.HINO:
        case FLEETX_ACCOUNTS.HLS_ASIA:
            return getVolumeAsLabel ? 'fuelType/hrs.' : 'L/Hr';
        default:
            return defaultValue ? defaultValue : getVolumeAsLabel ? 'km/fuelType' : 'km/L';
    }
};

export const getFuelUnitsForAccount = (accountId) => {
    switch (toSafeInteger(accountId)) {
        case FLEETX_ACCOUNTS.HINO:
        case FLEETX_ACCOUNTS.HLS_ASIA:
            return {
                'PETROL': {
                    unit: 'L/Hr',
                },
                'DIESEL': {
                    unit: 'L/Hr',
                },
                'CNG': {
                    unit: 'kg/hrs.',
                },
                'PROPANE': {
                    unit: 'kg/hrs.',
                },
                'UREA': {
                    unit: 'L/Hr',
                },
                'OTHERS': {
                    unit: 'L/Hr',
                },
            };
        default:
            return {
                'PETROL': {
                    unit: 'km/L',
                },
                'DIESEL': {
                    unit: 'km/L',
                },
                'CNG': {
                    unit: 'km/kg',
                },
                'HYBRID_PETROL': {
                    unit: 'km/L',
                },
                'PETROL_CNG': {
                    unit: 'km/kg',
                },
                'PROPANE': {
                    unit: 'km/kg',
                },
                'UREA': {
                    unit: 'km/L',
                },
                'ELECTRIC': {
                    unit: 'km/kWh',
                },
                'OTHERS': {
                    unit: 'km/L',
                },
            };
    }
};

export const GET_FUEL_TYPE = (loggedInUser) => {
    const accountId = get(loggedInUser, 'accountId');
    const fuelUnits = getFuelUnitsForAccount(accountId);
    return {
        PETROL: {
            name: 'Petrol',
            value: 'Petrol',
            unit: get(fuelUnits, 'PETROL.unit'),
            unitVolume: 'L',
            deviceFuelType: 'petrol',
        },
        DIESEL: {
            name: 'Diesel',
            value: 'Diesel',
            unit: get(fuelUnits, 'DIESEL.unit'),
            unitVolume: 'L',
            deviceFuelType: 'diesel',
        },
        CNG: {
            name: 'CNG',
            value: 'CNG',
            unit: get(fuelUnits, 'CNG.unit'),
            unitVolume: 'kg',
            deviceFuelType: 'petrol',
        },
        ELECTRIC: {
            name: 'Electric',
            value: 'Electric',
            unit: get(fuelUnits, 'ELECTRIC.unit'),
            unitVolume: 'kWh',
            deviceFuelType: 'petrol',
        },
        HYBRID_PETROL: {
            name: 'Petrol + Electric',
            value: 'Petrol + Electric',
            unit: get(fuelUnits, 'HYBRID_PETROL.unit'),
            unitVolume: 'L',
            deviceFuelType: 'petrol',
        },
        PETROL_CNG: {
            name: 'Petrol + CNG',
            value: 'Petrol + CNG',
            unit: get(fuelUnits, 'PETROL_CNG.unit'),
            unitVolume: 'kg',
            deviceFuelType: 'petrol',
        },
        'PETROL + CNG': {
            name: 'Petrol + CNG',
            value: 'Petrol + CNG',
            unit: get(fuelUnits, 'PETROL_CNG.unit'),
            unitVolume: 'kg',
            deviceFuelType: 'petrol',
        },
        UREA: {
            name: 'Urea',
            value: 'Urea',
            unit: get(fuelUnits, 'UREA.unit'),
            unitVolume: 'L',
            deviceFuelType: 'diesel',
        },
        PROPANE: {
            name: 'Propane',
            value: 'Propane',
            unit: get(fuelUnits, 'PROPANE.unit'),
            unitVolume: 'kg',
            deviceFuelType: 'petrol',
        },
    };
};
export const FUEL_TYPE_LIST = (loggedInUser) => {
    return [
        {
            id: 1,
            name: get(GET_FUEL_TYPE(loggedInUser), 'PETROL.value'),
        },
        {
            id: 2,
            name: get(GET_FUEL_TYPE(loggedInUser), 'DIESEL.value'),
        },
        {
            id: 3,
            name: get(GET_FUEL_TYPE(loggedInUser), 'PROPANE.value'),
        },
        {
            id: 4,
            name: get(GET_FUEL_TYPE(loggedInUser), 'CNG.value'),
        },
        {
            id: 5,
            name: get(GET_FUEL_TYPE(loggedInUser), 'ELECTRIC.value'),
        },
        {
            id: 7,
            name: get(GET_FUEL_TYPE(loggedInUser), 'UREA.value'),
        },
        {
            id: 8,
            name: get(GET_FUEL_TYPE(loggedInUser), 'HYBRID_PETROL.value'),
        },
        {
            id: 9,
            name: get(GET_FUEL_TYPE(loggedInUser), 'PETROL_CNG.value'),
        },
    ];
};

export function getFuelType(id, loggedInUser) {
    const fuelType = find(FUEL_TYPE_LIST(loggedInUser), { 'id': toSafeInteger(id) });
    return fuelType;
}

export function getFuel(fuelType, loggedInUser) {
    const FUEL_TYPE = GET_FUEL_TYPE(loggedInUser);
    if (!fuelType) {
        return FUEL_TYPE.DIESEL;
    }
    if (typeof fuelType === 'object') {
        return FUEL_TYPE[toUpper(fuelType.name)];
    }
    return FUEL_TYPE[toUpper(fuelType)];
}

export function getDefaultFuelTypeName(availableFuelTypes, loggedInUser) {
    const FUEL_TYPE = GET_FUEL_TYPE(loggedInUser);
    return availableFuelTypes
        ? includes(availableFuelTypes, FUEL_TYPE.DIESEL.name)
            ? FUEL_TYPE.DIESEL.name
            : availableFuelTypes[0]
        : null;
}

export function getFuelName(fuelType, loggedInUser) {
    return get(getFuel(fuelType, loggedInUser), 'value', '');
}

export function getDeviceFuelType(fuelType, loggedInUser) {
    return get(getFuel(fuelType, loggedInUser), 'deviceFuelType', '');
}

export function getFuelUnit(fuelType, loggedInUser) {
    return get(getFuel(fuelType, loggedInUser), 'unit', '');
}

export function getFuelVolume(fuelType, loggedInUser) {
    return get(getFuel(fuelType, loggedInUser), 'unitVolume', '');
}

export function getFuelString(fuel, fuelType, showUnit = true, loggedInUser) {
    return `${numberToLocaleString(round(fuel, 2))}${showUnit ? ` ${getFuelVolume(fuelType, loggedInUser)}` : ''}`;
}

export function getFuelLevel(fuel, showUnit = true) {
    if (!fuel) {
        return '';
    }
    let unit = '%';
    if (`${fuel}`.match(/L/i)) {
        unit = 'L';
    }
    return `${round(parseFloat(fuel))}${showUnit ? unit : ''}`;
}

export function getAnalyticsData(analyticsData, fuelType, groupId) {
    const groupData = getGroupData(analyticsData, groupId);
    const fuelData = getFuelData(groupData, fuelType);
    if (fuelData) {
        return fuelData[0];
    }
    return fuelData;
}

export function getGroupData(analyticsData, groupId) {
    if (!analyticsData) {
        return null;
    }
    const groupIds = keys(analyticsData);
    if (!groupIds || groupIds.length === 0) {
        return null;
    }
    if (!groupId) {
        groupId = groupIds[0];
    }
    return analyticsData[groupId];
}

export function getFuelData(analyticsData, fuelType) {
    if (!analyticsData) {
        return null;
    }
    const fuelTypes = keys(analyticsData);
    if (!fuelType) {
        fuelType = fuelTypes[0];
    }
    if (!fuelTypes || fuelTypes.length === 0 || !analyticsData[fuelType]) {
        return null;
    }
    return analyticsData[fuelType];
}

export function processFuelData(analyticsData, fuelType) {
    const fuelData = getFuelData(analyticsData, fuelType);
    const mileage = find(fuelData, ['type', 'mileage']);
    const mileageMedian = find(fuelData, ['type', 'mileageMedian']);
    const consumption = find(fuelData, ['type', 'totalFuelConsumption']);
    const totalOdometerWithFuel = find(fuelData, ['type', 'totalOdometerWithFuel']);
    const instantFuelMileage = find(fuelData, ['type', 'instantFuelMileage']);
    const instantFuelConsumption = find(fuelData, ['type', 'instantFuelConsumption']);
    const instantFuelDataPresent = instantFuelMileage?.count > 0 || instantFuelConsumption?.count > 0;
    const isFuelDataPresent = get(mileage, 'count', 0) > 0 || get(consumption, 'count', 0) > 0;

    if (isFuelDataPresent || instantFuelDataPresent) {
        return {
            fuelType,
            mileageMedian,
            mileage,
            consumption,
            totalOdometerWithFuel,
            instantFuelMileage,
            instantFuelConsumption,
        };
    } else {
        return null;
    }
}

export function processEvData(analyticsData, fuelType) {
    const fuelData = getFuelData(analyticsData, fuelType);
    const mileageEV = find(fuelData, ['type', 'mileageEV']);
    const totalEVSocConsumed = find(fuelData, ['type', 'totalEVSocConsumed']);
    const totalOdometerWithFuel = find(fuelData, ['type', 'totalOdometerWithFuel']);
    if (get(mileageEV, 'count', 0) > 0 || get(totalEVSocConsumed, 'count', 0) > 0) {
        return {
            fuelType,
            mileageEV,
            totalEVSocConsumed,
            totalOdometerWithFuel,
        };
    } else {
        return null;
    }
}

export function getFilteredGraphDataByFuelType(data, selectedFuelType) {
    const filteredData = get(data, selectedFuelType, []);
    return map(filteredData, (o) => {
        set(o, 'vehicleNumberSliced', getVehicleNumberDigits(o.vehicleNumber));
        set(o, 'cam', round(o.cam, 2));
        return o;
    });
}

export function getFuelCostByVolume(volume = 0, fuelType, toFormat, loggedInUser) {
    const cost = round(volume * getFuelAverageCost(fuelType, loggedInUser).lastAverageCost);
    return toFormat ? getFuelCostFormatted(cost) : cost;
}

export function getFuelCostPerKm(mileage = 0, fuelType, loggedInUser) {
    if (mileage > 1) {
        return round(getFuelAverageCost(fuelType, loggedInUser).lastAverageCost / mileage, 1);
    } else {
        return 0;
    }
}

export function getFuelCostFormatted(cost) {
    if (cost > 0) {
        const localeString = numberToLocaleString(cost);
        if (cost > 10000000) {
            return `${localeString.substring(0, localeString.length - 4)}Cr`;
        } else if (cost > 100000) {
            return `${round(cost / 100000, 2)}Lacs`;
        }
        return localeString;
    } else {
        return cost;
    }
}

export function getFuelUnitForDevice(point, deviceId) {
    let fuelUnit = 'L';
    const fuel = get(point, 'otherAttributes.fuel', '') + '';
    const fuelUnitServer = get(point, 'otherAttributes.fuelUnit', '');
    if (fuelUnitServer) {
        return fuelUnitServer;
    }
    if (fuel.match(/%/gi)) {
        fuelUnit = '%';
    } else if (
        get(getDeviceTypeFromDeviceId(deviceId, get(point, 'otherAttributes.deviceType', '')), 'fuelUnit') === '%'
    ) {
        fuelUnit = '%';
    }
    return fuelUnit;
}

export function renderFuelLevel(
    fuel,
    showDot = false,
    tankCapacity,
    isSwitchedUser,
    fuelReading,
    isSharedRealtimeVehicle,
    label,
    fuelSensorStatus = 'Working',
    fuelPercent,
    lastFuelSensorDisconnectTime,
    moreData = {}
) {
    if (fuelSensorStatus === 'Not Installed') {
        return null;
    }
    const { vehicle, fuelLabel } = moreData;
    const tc = toSafeInteger(tankCapacity);
    let fuelInTank = '';
    let fuelString = '';
    let errorString = '';
    let tooltipTitle = '';

    const shouldShowFuelLevelZero = fuel && fuelReading === '1';

    if (fuel && (parseFloat(fuel) > 0 || shouldShowFuelLevelZero)) {
        if (tc && fuel.match && fuel.match(/%/)) {
            fuelInTank = (parseFloat(tankCapacity) * parseFloat(fuel)) / 100;
            fuelInTank = round(fuelInTank, 1);
            fuelString = fuelInTank ? `${fuelInTank}L(${getFuelLevel(fuel, true)})` : `${getFuelLevel(fuel, true)}`;
        } else {
            fuelString = `${getFuelLevel(fuel, true)}` + '' + (!!tc && tc > 0 ? ` / ${round(tc)}L` : '');
        }
    } else if (fuelPercent) {
        fuelString = `${getFuelLevel(fuelPercent, true)}` + '' + (!!tc && tc > 0 ? ` / ${round(tc)}L` : '');
    }
    if (fuelSensorStatus && fuelSensorStatus !== 'Working') {
        errorString = 'Status : ' + fuelSensorStatus;
        const sensorDisconnectTime = toSafeInteger(lastFuelSensorDisconnectTime);
        if (sensorDisconnectTime) {
            const duration = moment.duration(getMomentTime().diff(sensorDisconnectTime)).humanize();
            errorString += ` Since ${getHumanizeTime(sensorDisconnectTime)}(${duration})`;
        } else if (fuelSensorStatus === 'Disconnected') {
            errorString += ``;
        }
        if (fuelReading) {
            if (fuelReading == -4) {
                tooltipTitle = `Reading: ${fuelReading}. Sensor Wiring Issue. Contact Ops.`;
            } else {
                tooltipTitle = `Reading: ${fuelReading}`;
            }
        }
    }

    const externalMachineStatusString = get(vehicle, 'otherAttributes.externalMachineStatus', null);
    const externalMachineStatusOffDuration = toSafeInteger(
        get(vehicle, 'otherAttributes.externalMachineStatusOffTime', 0)
    );
    const isExternalMachineStatusAvailable = externalMachineStatusString !== null;
    const externalMachineStatus = externalMachineStatusString && externalMachineStatusString === 'true';
    let offSince = '';
    if (externalMachineStatusOffDuration && !externalMachineStatus) {
        const duration = moment.duration(externalMachineStatusOffDuration).humanize();
        offSince = ` Since ${getHumanizeTime(
            getMomentTime(get(vehicle, 'lastUpdatedAt')).subtract(externalMachineStatusOffDuration)
        )}(${duration})`;
    }
    const deviceId = get(vehicle, 'deviceId', '');
    const isDeviceTypeOBD = deviceId.slice(0, 3) === '213';
    const fuelPer = tc !== 0 && round((parseInt(fuel) / toSafeInteger(tc)) * 100, 0);
    const isAdBlueInLiters = !!get(vehicle, 'otherAttributes.adblueInLitres', null);
    const totalWorkingHoursEM = get(vehicle, 'otherAttributes.totalWorkingHoursEM', null);

    let truncatedErroString = '';
    if (fuelString && totalWorkingHoursEM && isExternalMachineStatusAvailable && errorString) {
        truncatedErroString = errorString.slice(0, 25) + '...';
    } else if (isExternalMachineStatusAvailable) {
        truncatedErroString = errorString.slice(0, 50) + '...';
    } else {
        truncatedErroString = errorString;
    }
    const isSourceTpApi = get(vehicle, 'otherAttributes.source') === 'API';
    if (!isSharedRealtimeVehicle && (fuelString || errorString) && !isDeviceTypeOBD && !isAdBlueInLiters) {
        return (
            <div
                style={{
                    display: 'inline-block',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: '2px',
                    backgroundColor: '#F5F5F5',
                    borderRadius: 3,
                    paddingRight: '4px',
                }}
            >
                {isExternalMachineStatusAvailable && (
                    <>
                        <span
                            style={{
                                color: '#000000',
                                width: 'auto',
                                paddingLeft: 4,
                            }}
                        >
                            <span className={`font-weight-bold mr-1`} style={{ color: '#000000' }}>
                                CM -
                            </span>
                            <span style={{ color: '#333333' }}>
                                <span className="mr-1">{externalMachineStatus ? 'Machinery ON' : 'Machinery OFF'}</span>
                                {!externalMachineStatus && (
                                    <FxToolTip title={offSince} overlayStyle={{ maxWidth: 300 }}>
                                        <FxInfoCircleOutlined />
                                    </FxToolTip>
                                )}
                            </span>
                        </span>
                        {getSep()}
                    </>
                )}
                {!isExternalMachineStatusAvailable && (
                    <span
                        className={`font-weight-bold`}
                        style={{
                            color: '#000000',
                            width: 'auto',
                            paddingLeft: 4,
                        }}
                    >
                        {isSourceTpApi ? 'TP API: ' : ''} {`${fuelLabel}`}-
                    </span>
                )}
                {fuelString && (
                    <span className="mr-1" style={{ color: '#333333' }}>
                        <img width="18" height="18" className="mb-1 mr-1" src={'/public/img/icons/fuelTank-icon.svg'} />
                        {fuelString}
                        {fuelPer ? ',' : ''}
                        {fuelPer}
                        {fuelPer ? '%' : ''}
                    </span>
                )}
                {!!totalWorkingHoursEM && (
                    <>
                        {!!fuelString && getSep()}
                        <span
                            className="mr-1"
                            style={{
                                color: '#333333',
                            }}
                        >
                            Engine Hours:
                        </span>
                        <span className="mr-1">{getTimeDiffHours(totalWorkingHoursEM, false, true)}</span>
                    </>
                )}
                {errorString && (
                    <>
                        {!!fuelString && getSep()}
                        <span className="text-danger">
                            <FxToolTip title={errorString}>{truncatedErroString}</FxToolTip>{' '}
                            {isSwitchedUser && tooltipTitle && (
                                <FxToolTip title={tooltipTitle}>
                                    <FxWarningOutlined />
                                </FxToolTip>
                            )}
                        </span>
                    </>
                )}
            </div>
        );
    } else if (!isSharedRealtimeVehicle && !isDeviceTypeOBD && !isAdBlueInLiters && isExternalMachineStatusAvailable) {
        return (
            <div
                style={{
                    display: 'inline-block',
                    alignItems: 'center',
                    textAlign: 'center',
                    backgroundColor: isExternalMachineStatusAvailable ? '#faeee5' : '#F5F5F5',
                    borderRadius: 3,
                }}
            >
                <>
                    <span
                        className={`font-weight-bold`}
                        style={{
                            color: '#000000',
                            width: 'auto',
                            padding: 5,
                        }}
                    >
                        <span className={`font-weight-bold mr-1`}>EM -</span>
                        <span style={{ color: '#333333' }}>
                            <span className="mr-1">{externalMachineStatus ? 'Machinery ON' : 'Machinery OFF'}</span>
                            {!externalMachineStatus && (
                                <FxToolTip title={offSince} overlayStyle={{ maxWidth: 300 }}>
                                    <FxInfoCircleOutlined />
                                </FxToolTip>
                            )}
                        </span>
                    </span>
                </>
            </div>
        );
    } else {
        return '';
    }
}
export function renderTotalFuel(fuels, tankCapacity) {
    const { fuel, fuel_2, fuel_3 = '0', fuel_4 = '0' } = fuels;
    const {
        fuelTankCapacity,
        fuelTankCapacity_2 = '0',
        fuelTankCapacity_3 = '0',
        fuelTankCapacity_4 = '0',
    } = tankCapacity;
    const totalFuel = parseInt(fuel) + parseInt(fuel_2) + parseInt(fuel_3) + parseInt(fuel_4);
    const totalTankCapacity =
        parseInt(fuelTankCapacity) +
        parseInt(fuelTankCapacity_2) +
        parseInt(fuelTankCapacity_3) +
        parseInt(fuelTankCapacity_4);
    const totalfuelPercentage = parseInt((totalFuel / totalTankCapacity) * 100);
    return (
        <div
            style={{
                display: 'inline-block',
                alignItems: 'center',
                textAlign: 'center',
                padding: '2px',
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                // paddingRight: '4px',
            }}
        >
            <span className={`font-weight-bold mr-1`} style={{ color: '#000000' }}>
                Total Fuel:
            </span>
            {totalFuel}L / {totalTankCapacity},{totalfuelPercentage}
            {'%'}
        </div>
    );
}

export const FUEL_PROVIDER_TYPES = [
    {
        value: 'HPCL',
        label: 'HPCL',
    },
    {
        value: 'IOCL',
        label: 'IOCL',
    },
];

export function getFuelSurchargeAmount(totalDistance, mileage, differenceInPrice) {
    const totalFuelConsumend = totalDistance / mileage;
    const fuelSurcharge = totalFuelConsumend * differenceInPrice;
    return round(fuelSurcharge, 2);
}

export function getAvgFuelCost(key, value) {
    switch (key) {
        case '5':
            return get(value, 'avgElectricFuelCostPerKm', 0);
        default:
            return get(value, 'fuelCostPerKm', 0);
    }
}
export function getAvgPricePerUnit(key, value) {
    switch (key) {
        case '5':
            return get(value, 'avgElectricPricePerKg', 0);
        default:
            return get(value, 'pricePervolumeUnit', 0);
    }
}
export function getTotalFuel(key, value) {
    switch (key) {
        case '5':
            return `${round(get(value, 'totalElectricFuel', 0), 2)} kWh`;
        case '4':
            return `${round(get(value, 'kilograms', 0), 2)} kg`;
        default:
            return `${round(get(value, 'liters', 0), 2)} L`;
    }
}
export function getTotalFuelCost(key, value) {
    switch (key) {
        case '5':
            return get(value, 'totalElectricFuelCost', 0);
        default:
            return get(value, 'totalFuelCost', 0);
    }
}
